import { useState } from 'react'
import { Link } from 'react-router-dom'
import useLogin from '../api/Site/useLogin'
import {AlertWarning} from '../blocks/components/alerts/AlertWarning'

import { Button } from "../components/ui/button"
import { Input } from "../components/ui/input"
import { Label } from "../components/ui/label"

const Login = () => {
    const [ form, setForm ] = useState({email: '', password: ''})
    const [ passwordVis, setPasswordVis ] = useState(false)
    const [ alertsArray, setAlertsArray ] = useState([])

    const handleFormChange = (name, value) => {
        setForm({...form, [name]: value})
    }
    
    const { onSubmitLogin } = useLogin(form, setPasswordVis, setAlertsArray)

    return (
      <div className="bg-zinc-100 w-full lg:grid lg:min-h-[600px] lg:grid-cols-2 xl:min-h-[800px]">
          <AlertWarning 
              modalVis={passwordVis} 
              setModalVis={setPasswordVis}
              alertsArray={alertsArray} 
          />
        <div className="flex items-center justify-center py-12">
          <div className="mx-auto grid w-[350px] gap-6">
            <div className="grid gap-2 text-center">
              <h1 className="text-3xl font-bold">Login</h1>
              <p className="text-balance text-muted-foreground">
                Enter your email below to login to your account
              </p>
            </div>
            <form className="grid gap-4" onSubmit={(e) => onSubmitLogin(e)}>
              <div className="grid gap-2">
                <Label htmlFor="email">Email</Label>
                <Input
                  id="email"
                  type="email"
                  name="email"
                  placeholder="Enter your account email"
                  required
                  value={form.email} 
                  onChange={(e) => handleFormChange(e.target.name, e.target.value)}
                />
              </div>
              <div className="grid gap-2">
                <div className="flex items-center">
                  <Label htmlFor="password">Password</Label>
                  <Link
                    to={{pathname: "/forgot-password"}}
                    className="ml-auto inline-block text-sm underline"
                  >
                    Forgot your password?
                  </Link>
                </div>
                <Input 
                  id="password" 
                  type="password" 
                  name="password"
                  placeholder="Enter your password"
                  required
                  value={form.password} 
                  onChange={(e) => handleFormChange(e.target.name, e.target.value)}
                    />
              </div>
              <Button type="submit" className="w-full">
                Login
              </Button>
            </form>
            <div className="mt-4 text-center text-sm">
              Don&apos;t have an account?{" "}
              <Link 
                  to={{pathname: "/signup"}}
                  className="underline">
                Sign up
              </Link>
            </div>
          </div>
        </div>
        <div className="hidden bg-muted lg:block bg-indigo-950">                            
        </div>
      </div>
    )
}

export default Login
