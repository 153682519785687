import { Link } from 'react-router-dom';
import { Button } from "../../components/ui/button"

export const blogData = [    
    {
        title: 'Camera controls guide for touchscreen, mouse, and trackpad!',
        date: 'Wed, May 22, 2024 at 11:57AM',
        author: 'jona-young',
        content: <>
                    <p className="leading-7 [&:not(:first-child)]:mt-6">
                        This guide will walk you through camera controls when viewing a climb!
                    </p>
                    <h5 className="scroll-m-20 text-sm font-extrabold tracking-tight">
                        Touchscreen (Phone, Tablet)
                    </h5>
                    <div className="p-4">
                        <h5 className="scroll-m-20 text-sm font-extrabold tracking-tight">
                            Rotate
                        </h5>
                        <p className="leading-7 [&:not(:first-child)]:mt-2 text-sm">
                            Single finger swipe in any direction!
                        </p>
                        <h5 className="scroll-m-20 text-sm font-extrabold tracking-tight">
                            Zoom
                        </h5>
                        <p className="leading-7 [&:not(:first-child)]:mt-2 text-sm">
                            To zoom in, place two fingers together on the touchscreen
                            then spread them apart maintaining contact with the touchscreen.
                        </p>
                        <p className="leading-7 [&:not(:first-child)]:mt-2 text-sm">
                            To zoom out, place two fingers apart from each other on the
                            touchscreen then bring them closer maintaining contact with
                            the touchscreen.
                        </p>
                        <h5 className="scroll-m-20 text-sm font-extrabold tracking-tight">
                            Move
                        </h5>
                        <p className="leading-7 [&:not(:first-child)]:mt-2 text-sm">
                            To move around the climbing wall, place two fingers touching
                            each other on the touchscreen, keeping them together, swipe in any
                            direction to move around the wall!
                        </p>
                    </div>
                    <h5 className="scroll-m-20 text-sm font-extrabold tracking-tight">
                    Mouse (Laptop, Desktop)
                    </h5>
                    <div className="p-4">
                        <h5 className="scroll-m-20 text-sm font-extrabold tracking-tight">
                            Rotate
                        </h5>
                        <p className="leading-7 [&:not(:first-child)]:mt-2 text-sm">
                            Hold left-click and move in any direction to pan the camera!
                        </p>
                        <h5 className="scroll-m-20 text-sm font-extrabold tracking-tight">
                            Zoom
                        </h5>
                        <p className="leading-7 [&:not(:first-child)]:mt-2 text-sm">
                            To zoom, spin the scroll wheel (inbetween the left and right mouse click buttons).
                        </p>
                        <h5 className="scroll-m-20 text-sm font-extrabold tracking-tight">
                            Move
                        </h5>
                        <p className="leading-7 [&:not(:first-child)]:mt-2 text-sm">
                            Hold right-click and move in any direction to move around the
                            climbing wall. 
                        </p>
                    </div>
                    <h5 className="scroll-m-20 text-sm font-extrabold tracking-tight">
                    Trackpad (Laptop)
                    </h5>
                    <div className="p-4">
                        <h5 className="scroll-m-20 text-sm font-extrabold tracking-tight">
                            Rotate
                        </h5>
                        <p className="leading-7 [&:not(:first-child)]:mt-2 text-sm">
                            Hold left-click and move in any direction to pan the camera!
                        </p>
                        <h5 className="scroll-m-20 text-sm font-extrabold tracking-tight">
                            Zoom
                        </h5>
                        <p className="leading-7 [&:not(:first-child)]:mt-2 text-sm">
                            To zoom in, place two fingers together on the trackpad
                            then spread them apart maintaining contact with the trackpad.
                        </p>
                        <p className="leading-7 [&:not(:first-child)]:mt-2 text-sm">
                            To zoom out, place two fingers apart from each other on the
                            trackpad then bring them closer maintaining contact with
                            the trackpad.
                        </p>
                        <h5 className="scroll-m-20 text-sm font-extrabold tracking-tight">
                            Move
                        </h5>
                        <p className="leading-7 [&:not(:first-child)]:mt-2 text-sm">
                            Place two fingers touching each other on the trackpad, 
                            keeping them together, swipe in any direction to move around
                            the wall! 
                        </p>
                    </div>
                </>
    }
]