import UseMutateAdminCreateWallSet from '../../../api/Gym/UseMutateAdminCreateWallset'  
  import {
    Card,
    CardContent,
    CardHeader,
    CardTitle,
    CardFooter
  } from "../../../components/ui/card.jsx"
  import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
  } from "../../../components/ui/select"
import { Input } from '../../../components/ui/input'
import { Label } from '../../../components/ui/label'
import { CircleX } from "lucide-react"

export function AddWallSet({modalVis, setModalVis, gymData, gymId, wallId, setWallId, newWallSet, setNewWallSet}) {    
    const addNewWallSet = () => {
        setNewWallSet({
            date: Date(),
            climbs: [],
            wallSetURL: '',
            wallGeometry: '',
            wallMaterial: '',
            switchSets: false
        })
    }

    if (modalVis) {
        return (
            <div className="fixed top-0 h-full w-full z-20 bg-black/10">
                <Card className="mt-12 mx-auto w-[18rem] h-fit box-border rounded-lg p-1 overflow-auto">
                    <CardHeader >
                        <div className="flex flex-row justify-between pb-2">
                        <CardTitle className="w-fit">Add Wall Set</CardTitle>     
                        <CircleX 
                            color="red"
                            className="h-4 w-4"
                            onClick={() => setModalVis(false)}
                        />  
                        </div>                                                                                                          
                    </CardHeader>
                    <CardContent>
                    <Select 
                        id="wall"
                        name="wall"
                        required
                        value={wallId}
                        onValueChange={(value) =>{addNewWallSet(); setWallId(value)}}
                        >
                        <SelectTrigger
                        id="wall"
                        aria-label="Select a wall"
                        >
                        <SelectValue placeholder="Select a wall" />
                        </SelectTrigger>
                        <SelectContent>
                            {gymData && gymData.walls.map((wall, idx) => {
                                return <SelectItem value={wall._id}>
                                {wall.wallName}
                            </SelectItem>
                            })}                         
                        </SelectContent>
                    </Select>
                    </CardContent>
                    { newWallSet !== null ?
                        <CardContent className="p-4 pt-2">
                            <Label
                                className="w-24"
                                htmlFor="wallSet">Wall Set {" "}</Label>
                            <Input
                            id="date" 
                            name="date"
                            required
                            disabled
                            type="text"
                            className="w-full"
                            placeholder="Enter a wall name"
                            value={newWallSet.date}
                            />
                            <Label
                                className="w-24"
                                htmlFor="wallSetURL">Wall Set URL {" "}</Label>
                            <Input
                            id="wallSetURL" 
                            name="wallSetURL"
                            required
                            type="text"
                            className="w-full"
                            placeholder="Enter a wall set URL"
                            value={newWallSet.wallSetURL}
                            onChange={(e) => setNewWallSet({...newWallSet, wallSetURL: e.target.value})}
                            />
                            <Label
                                className="w-24"
                                htmlFor="wallGeometry">Wall Set Geometry {" "}</Label>
                            <Input
                            id="wallGeometry" 
                            name="wallGeometry"
                            required
                            type="text"
                            className="w-full"
                            placeholder="Enter the name of the geometry"
                            value={newWallSet.wallGeometry}
                            onChange={(e) => setNewWallSet({...newWallSet, wallGeometry: e.target.value})}
                            />
                            <Label
                                className="w-24"
                                htmlFor="wallGeometry">Wall Set Material {" "}</Label>
                            <Input
                            id="wallMaterial" 
                            name="wallMaterial"
                            required
                            type="text"
                            className="w-full"
                            placeholder="Enter the name of the material"
                            value={newWallSet.wallMaterial}
                            onChange={(e) => setNewWallSet({...newWallSet, wallMaterial: e.target.value})}
                            />
                            <div className="flex flex-row justify-start mt-4">
                                <Input 
                                    type="checkbox"                             
                                    onClick={() => {setNewWallSet({...newWallSet, switchSets: newWallSet.switchSets == false ? true : false})}} 
                                    name="showInfo" 
                                    checked={newWallSet.switchSets == false ? false : true}
                                    className="w-4 h-auto" />
                                <Label className="text-nowrap ml-2">Pin this wall set for this wall?</Label>
                            </div>  
                        </CardContent>  
                    :
                        null
                    }   
                    
                    <CardFooter className="flex flex-row justify-center">
                        <UseMutateAdminCreateWallSet 
                            gymId={gymId}
                            wallId={wallId}
                            newWallSet={newWallSet} 
                            setModalVis={setModalVis} />               
                    </CardFooter> 
                </Card>
            </div>            
        )
    } else return
    
}