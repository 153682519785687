import UseMutateDeleteGymClimb from '../../api/Gym/UseMutateDeleteGymClimb.js'
import { Button } from '../../components/ui/button.jsx'
import {
    Card,
    CardDescription,
    CardHeader,
    CardTitle,
    CardContent
  } from "../../components/ui/card.jsx"

const ModalGymClimbDelete = ({modalVis, setModalVis, currentWallID, currentWallSetID, deleteId}) => {
    if (modalVis) {
        return (
            <div className="fixed top-0 h-full w-full z-20 bg-black/10">
                <Card className="mt-12 mx-auto w-64 box-border rounded-lg p-1 overflow-auto">
                    <CardHeader>
                        <CardTitle>Delete the selected climb?</CardTitle>
                        <CardDescription>{deleteId.grade + " - " + deleteId.name}</CardDescription>
                    </CardHeader>
                    <CardContent className="flex flex-row justify-between">
                        <UseMutateDeleteGymClimb 
                            wallID={currentWallID} 
                            wallSetID={currentWallSetID}
                            climbID={deleteId.id}
                            setAlert={setModalVis} />
                        <Button onClick={() => {setModalVis(false)}} size="sm" variant="destructive">Cancel</Button>
                    </CardContent>
                    
                </Card>
            </div>
            
        )
    } else {
        return
    }

}

export default ModalGymClimbDelete;