/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.5.0 campusTEST.glb --transform 
Files: campusTEST.glb [131.51MB] > /Users/jonathanyoung/Documents/3D-Models/Photogrammetry/campusTEST-transformed.glb [4.25MB] (97%)
*/

import React from 'react'
import { useGLTF } from '@react-three/drei'

export function WallModel(props) {
  const { nodes, materials } = useGLTF(props.modelURL)
  return (
    <group {...props} dispose={null}>
      <mesh geometry={nodes[props.modelGeometry].geometry} material={materials[props.modelMaterial]} />

      { nodes && nodes.Plane ? 
          <mesh geometry={nodes.Plane.geometry} material={nodes.Plane.material} />
        :
          null
      }
    </group>
  )
}
