import { useState, useEffect } from 'react'
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectTrigger,
    SelectValue,
  } from "../../../components/ui/select"  

export function GradeSelect({ gradeValue, updateGradeValue, getGradeOptions}) {
    const [ gradeOptions, setGradeOptions ] = useState([])

    useEffect(() => {
        setGradeOptions(getGradeOptions) 
    }, [])

    return (
        <Select
            onValueChange={(value) => {updateGradeValue(value)}}
            name="grade"
            value={gradeValue} >
            <SelectTrigger className="w-[180px]">
                <SelectValue placeholder="Select the climb's grade" />
            </SelectTrigger>
            <SelectContent>
                <SelectGroup>
                    {gradeOptions.length > 0 && gradeOptions.map((item, i) => {
                        return <SelectItem key={item.grade + '-id-' + item.id} value={item.grade}>{item.grade}</SelectItem>
                    })}
                </SelectGroup>
            </SelectContent>
        </Select> 
    )
}

