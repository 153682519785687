import { useMutation, useQueryClient } from '@tanstack/react-query'
import { axiosAPI } from '../axiosAPI.js'
import { Button } from '../../components/ui/button.jsx'

const UseMutateCreateGymClimb = ({wallID, wallSetID, climb, holdColor, setClimbModalVis}) => {
    const queryClient = useQueryClient()

    // send to backend API
    const mutation = useMutation({
        mutationFn: async () => {
            const gymID = queryClient.getQueryData(['gym']).id

            const response = await axiosAPI.put('add-gym-climb/' + gymID, {
                                                wallID: wallID, 
                                                wallSetID: wallSetID, 
                                                climb: {...climb, ['color']: holdColor}
                                            })

            return response.data
        },
        onSuccess: (data) => {
            queryClient.setQueryData(['gym'], data)

            return setClimbModalVis(false)
        }
    })

    const onSubmitCreateGymClimb = (e) => {
        e.preventDefault()

        mutation.mutate()
    }


    return (
        <div>
            {mutation.isLoading ? (
            'Adding gym climb...'
            ) : (
            <>
                {mutation.isError ? (
                    <>
                        <p className="climb-options-header">Errors:</p>
                        <p className="climb-options-error">{mutation.error.response.data.name}</p>
                        <p className="climb-options-error">{mutation.error.response.data.grade}</p>

                    </>
                ) : null}
    
                <Button onClick={(e) => onSubmitCreateGymClimb(e)} size="sm" >Add Gym Climb</Button>
            </>
            )}
        </div>
    )
}

export default UseMutateCreateGymClimb;