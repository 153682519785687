import axios from 'axios'
import createAuthRefreshInterceptor from 'axios-auth-refresh'
import history from '../api/history'

export const axiosAPI = axios.create({
    baseURL: process.env.REACT_APP_MODE == "dev" ? process.env.REACT_APP_API_DEV : process.env.REACT_APP_API_PROD
})

axiosAPI.defaults.withCredentials = true; 
axiosAPI.defaults.headers.common['Content-Type'] = "application/json"

export const setHeaderToken = (token) => {
    return axiosAPI.defaults.headers.common.Authorization = `Bearer ${token}`;
}

export const removeHeaderToken = () => {
    return axiosAPI.defaults.headers.common.Authorization = null;
}

export const refreshToken = async () => {
    try {
        const response = await axiosAPI.get('refresh-token')
        const accessToken = response.data.accessToken
        
        sessionStorage.setItem("userId", response.data.userId)
        sessionStorage.setItem("firstName", response.data.firstName)
        sessionStorage.setItem("lastName", response.data.lastName)
        sessionStorage.setItem("email", response.data.email)

        return accessToken
    } catch (err) {
        return null
    }
}

const refreshAuth = async (failedRequest) => {
    const newToken = await refreshToken()

    if (newToken) {
        failedRequest.response.config.headers.Authorization = "Bearer " + newToken
        setHeaderToken(newToken)

        return Promise.resolve(newToken)
    } else {
        history.replace('/login')

        return Promise.reject()
    }
}

createAuthRefreshInterceptor(axiosAPI, refreshAuth, {
    statusCodes: [401],
    pauseInstanceWhileRefreshing: true,
})

