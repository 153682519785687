import { useState } from 'react'
import {Link} from "react-router-dom"
import { HexColorPicker } from 'react-colorful'
import { checkUserGroup } from '../helpers/checkUserGroup'
import { AlertWarning } from '../blocks/components/alerts/AlertWarning'
import { handleOpenHoursUpdate, 
        pushNewToArray,
        popFromArray, 
        handleUpdateGrade, 
        handleUpdateRating,
        handleUpdateColor,
        addNewWall,
        addNewWallSet,
        removeNewWall,
        removeNewWallSet,
        handleChangeWallName,
        handleWallSetField,
        handleUpdateAuthor,
        handleUpdateCollaborator,
        handleRemoveCollaborator    
} from '../helpers/handleGymData.js'
import {
  PlusCircle,
  CircleX
} from "lucide-react"

import { Badge } from "../components/ui/badge"
import { Separator } from '../components/ui/separator'
import { ScrollArea } from "../components/ui/scroll-area"
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "../components/ui/breadcrumb"
import { Button } from "../components/ui/button"
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../components/ui/card"
import { Input } from "../components/ui/input"
import { Label } from "../components/ui/label"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../components/ui/select"
import '../tailwind.css'

const FormGym = ({mode, heading, subHeading, form, setForm, userList, alertWarning, setAlertWarning, alertMessages, setAlertMessages, submitFunction}) => {
    const [ currentCollaborator, setCurrentCollaborator ] = useState('')

    return (
        <div className="flex min-h-screen w-full flex-col bg-muted/40">   
            <AlertWarning modalVis={alertWarning} setModalVis={setAlertWarning} alertsArray={alertMessages} />   
            <div className="flex flex-col sm:gap-4 sm:py-4 sm:pl-14">
            <header className="sticky top-0 z-30 flex h-14 items-center gap-4 border-b bg-background px-4 sm:static sm:h-auto sm:border-0 sm:bg-transparent sm:px-6">          
                <Breadcrumb className="flex">
                <BreadcrumbList>
                    <BreadcrumbItem>
                    <BreadcrumbLink asChild>
                        <Link to={{ pathname: "/admin" }}>Admin</Link>
                    </BreadcrumbLink>
                    </BreadcrumbItem>
                    <BreadcrumbSeparator />              
                    <BreadcrumbItem>
                    <BreadcrumbPage>{mode} a Gym</BreadcrumbPage>
                    </BreadcrumbItem>
                </BreadcrumbList>
                </Breadcrumb>          
            </header>
            <main className="grid flex-1 items-start gap-4 p-4 sm:px-6 sm:py-0 md:gap-8">
                <div className="mx-auto grid flex-1 auto-rows-max gap-4">
                <div className="flex w-90 items-center gap-4">              
                    <h1 className="flex-1 shrink-0 whitespace-nowrap text-xl font-semibold tracking-tight sm:grow-0">
                        {heading}
                    </h1>
                    <Badge variant="outline" className="ml-auto sm:ml-0">
                    Active
                    </Badge>              
                </div>
                <div className="grid gap-4 md:grid-cols-[1fr_250px] lg:grid-cols-3 lg:gap-8">
                    <div className="grid auto-rows-max items-start gap-4 lg:col-span-2 lg:gap-8">
                    <Card className="w-90 ">
                        <CardHeader>
                        <CardTitle>General</CardTitle>
                        <CardDescription>
                            {subHeading}
                        </CardDescription>
                        </CardHeader>
                        <CardContent>
                        <div className="grid gap-6">
                            <div className="grid gap-3">
                            <Label htmlFor="gymName">Name</Label>
                            <Input
                                id="gymName"
                                name="gymName"
                                required
                                type="text"
                                className="w-full"
                                placeholder="Enter the name of your gym"
                                value={form.gymName}
                                onChange={(e) => setForm({...form, gymName: e.target.value})}
                            />
                            </div>
                            <div className="grid gap-3">
                            <Label htmlFor="address">Location</Label>
                            <div className="flex flex-row items-center justify-start ml-2">
                                <Label
                                    className="w-24"
                                    htmlFor="address">Address {" "}</Label>
                                <Input
                                id="address" 
                                name="address"
                                required
                                type="text"
                                className="w-full ml-2"
                                placeholder="Enter your address"
                                value={form.location.address}
                                onChange={(e) => setForm({...form, location: {...form.location, address: e.target.value}})}
                                />
                            </div>
                            <div className="flex flex-row items-center justify-start ml-2">
                                <Label 
                                    className="w-24"
                                    htmlFor="city">City {" "}</Label>
                                <Input
                                id="city" 
                                name="city"
                                required
                                type="text"
                                className="w-full ml-2"
                                placeholder="Enter your city"
                                value={form.location.city}
                                onChange={(e) => setForm({...form, location: {...form.location, city: e.target.value}})}
                                />
                            </div>
                            <div className="flex flex-row items-center justify-start ml-2">
                                <Label 
                                    className="w-24"
                                    htmlFor="province">Province {" "}</Label>
                                <Input
                                id="province" 
                                name="province"
                                required
                                type="text"
                                className="w-full ml-2"
                                placeholder="Enter your province"
                                value={form.location.province}
                                onChange={(e) => setForm({...form, location: {...form.location, province: e.target.value}})}
                                />
                            </div>
                            <div className="flex flex-row items-center justify-start ml-2">
                                <Label 
                                    className="w-24"
                                    htmlFor="postalCode">Postal Code {" "}</Label>
                                <Input
                                id="postalCode" 
                                name="postalCode"
                                required
                                type="text"
                                className="w-full ml-2"
                                placeholder="Enter your postal code"
                                value={form.location.postalCode}
                                onChange={(e) => setForm({...form, location: {...form.location, postalCode: e.target.value}})}
                                />
                            </div>
                            </div>
                            <div className="grid gap-3">
                            <Label htmlFor="description">Open Hours</Label>
                            <div className="flex flex-row items-center justify-start ml-2">
                                <Badge variant="outline" className="ml-auto sm:ml-0">
                                    SUN
                                </Badge>  
                                <Input
                                id="sunday" 
                                name="sunday"
                                required
                                type="text"
                                className="w-full ml-2"
                                placeholder="Enter your open times for Sunday..."
                                value={form.openHours[0]}
                                onChange={(e) => handleOpenHoursUpdate(form, setForm, 0, e.target.value)}
                                />
                            </div>
                            <div className="flex flex-row items-center justify-start ml-2">
                                <Badge variant="outline" className="ml-auto sm:ml-0">
                                    MON
                                </Badge> 
                                <Input
                                id="monday" 
                                name="monday"
                                required
                                type="text"
                                className="w-full ml-2"
                                placeholder="Enter your open times for Monday..."
                                value={form.openHours[1]}
                                onChange={(e) => handleOpenHoursUpdate(form, setForm, 1, e.target.value)}
                                />
                            </div>
                            <div className="flex flex-row items-center justify-start ml-2">
                                <Badge variant="outline" className="ml-auto sm:ml-0">
                                    TUE
                                </Badge> 
                                <Input
                                id="tuesday" 
                                name="tuesday"
                                required
                                type="text"
                                className="w-full ml-2"
                                placeholder="Enter your open times for Tuesday..."
                                value={form.openHours[2]}
                                onChange={(e) => handleOpenHoursUpdate(form, setForm, 2, e.target.value)}
                                />
                            </div>
                            <div className="flex flex-row items-center justify-start ml-2">
                                <Badge variant="outline" className="ml-auto sm:ml-0">
                                    WED
                                </Badge> 
                                <Input
                                id="wednesday" 
                                name="wednesday"
                                required
                                type="text"
                                className="w-full ml-2"
                                placeholder="Enter your open times for Wednesday..."
                                value={form.openHours[3]}
                                onChange={(e) => handleOpenHoursUpdate(form, setForm, 3, e.target.value)}
                                />
                            </div>
                            <div className="flex flex-row items-center justify-start ml-2">
                                <Badge variant="outline" className="ml-auto sm:ml-0">
                                    THU
                                </Badge> 
                                <Input
                                id="thursday" 
                                name="thursday"
                                required
                                type="text"
                                className="w-full ml-2"
                                placeholder="Enter your open times for Thursday..."
                                value={form.openHours[4]}
                                onChange={(e) => handleOpenHoursUpdate(form, setForm, 4, e.target.value)}
                                />
                            </div>
                            <div className="flex flex-row items-center justify-start ml-2">
                                <Badge variant="outline" className="ml-auto sm:ml-0">
                                    FRI
                                </Badge> 
                                <Input
                                id="friday" 
                                name="friday"
                                required
                                type="text"
                                className="w-full ml-2"
                                placeholder="Enter your open times for Friday..."
                                value={form.openHours[5]}
                                onChange={(e) => handleOpenHoursUpdate(form, setForm, 5, e.target.value)}
                                />
                            </div>
                            <div className="flex flex-row items-center justify-start ml-2">
                                <Badge variant="outline" className="ml-auto sm:ml-0">
                                    SAT
                                </Badge>
                                <Input 
                                id="saturday" 
                                name="saturday"
                                required
                                type="text"
                                className="w-full ml-2"
                                placeholder="Enter your open times for Saturday..."
                                value={form.openHours[6]}
                                onChange={(e) => handleOpenHoursUpdate(form, setForm, 6, e.target.value)}
                                />
                            </div>                     
                            </div>
                        </div>
                        </CardContent>
                    </Card>
                    <Card className="w-90 ">
                        <CardHeader>
                        <CardTitle>Grading</CardTitle>
                        <CardDescription>
                            Enter the grading system for your gym
                        </CardDescription>
                        </CardHeader>
                        <CardContent>
                        <div className="grid gap-6">
                            <div className="grid gap-3">
                            <Label htmlFor="gradingType">Type</Label>
                            <Select 
                                id="gradingType"
                                name="gradingType"
                                required
                                value={form.gradingType}
                                onValueChange={(value) => setForm({...form, gradingType: value})}
                                >
                                <SelectTrigger
                                id="gradingCategoryType"
                                aria-label="Select grading type"
                                >
                                <SelectValue placeholder="Select a grading type" />
                                </SelectTrigger>
                                <SelectContent>
                                <SelectItem value="text">
                                    Text
                                </SelectItem>
                                <SelectItem value="color">
                                    Colour
                                </SelectItem>
                                </SelectContent>
                            </Select>
                            </div>
                            <div className="grid gap-3">
                            <Label htmlFor="name">Grading System</Label>
                            { form.gradingType == "color" ?
                                form.gradingSystem.map((grade, idx) => {
                                    return (
                                        <CardContent className="flex flex-col p-2 pt-0">
                                            <Label className="text-xs font-semibold">Grade {idx + 1}</Label>
                                            <div className="flex flex-row">
                                                <div className="mr-2">
                                                    <Input 
                                                        type="text" 
                                                        className="text-xs w-16" 
                                                        name="gradingSystem"
                                                        placeholder="Enter a grade" 
                                                        value={form.gradingSystem[idx].grade} 
                                                        onChange={(e) => handleUpdateGrade(form, setForm, idx, e.target.value)} />
                                                    <Button 
                                                        variant="destructive" 
                                                        className="w-16 h-8 mt-2"
                                                        onClick={(e) => {popFromArray(form, setForm, "gradingSystem", idx)}}>Remove</Button>
                                                </div>
                                                <div className="grading-color">
                                                    <HexColorPicker 
                                                        color={form.gradingSystem[idx].color} 
                                                        onChange={(color) => handleUpdateColor(form, setForm, "gradingSystem", idx, color)} />
                                                </div>
                                            </div>                                        
                                        </CardContent>
                                    )
                                })
                            :
                                form.gradingType == "text" ?
                                    <CardContent className="flex flex-row flex-wrap justify-start p-1 pt-0">
                                        {form.gradingSystem.map((grade, idx) => {
                                            return (
                                                <div>
                                                    <Label className="text-xs font-semibold">Grade {idx + 1}</Label>
                                                    <div className="flex flex-row ">
                                                        <div className="mr-2">
                                                            <Input 
                                                                type="text" 
                                                                className="text-xs w-14" 
                                                                name="gradingSystem"
                                                                placeholder="Enter a grade" 
                                                                value={form.gradingSystem[idx].grade} 
                                                                onChange={(e) => handleUpdateGrade(form, setForm, idx, e.target.value)} />
                                                            <Button 
                                                                variant="destructive" 
                                                                className="w-14 h-8 mt-2"
                                                                onClick={(e) => {popFromArray(form, setForm, "gradingSystem", idx)}}>Delete</Button>
                                                        </div>
                                                    </div>  
                                                </div>
                                            )
                                        })}
                                    </CardContent>                                        
                                :
                                    null
                                }
                                { form.gradingSystem.length < 17 ? 
                                    <Button 
                                        onClick={(e) => {pushNewToArray(form, setForm, "gradingSystem", "grade")}} 
                                        className="mt-4">
                                        + Grade
                                    </Button>
                                :
                                    null
                                }                            
                            </div>                      
                        </div>
                        </CardContent>
                    </Card>
                    <Card className="w-90 ">
                        <CardHeader>
                        <CardTitle>Feedback</CardTitle>
                        <CardDescription>
                            Enter the feedback scale that climbers can review for your climbs
                        </CardDescription>
                        </CardHeader>
                        <CardContent>
                        <div className="grid gap-6">                      
                            <div className="grid gap-3">
                                <Label htmlFor="ratingSystem">Rating System</Label>
                                {   form.ratingSystem.length > 0 ? 
                                        form.ratingSystem.map((rating, idx) => {
                                            return (
                                                <CardContent className="flex flex-col p-2 pt-0">
                                                    <Label className="text-xs font-semibold">Rating {idx + 1}</Label>
                                                    <div className="flex flex-row">
                                                        <div className="mr-2">
                                                            <Input 
                                                                type="text" 
                                                                className="text-xs w-32" 
                                                                name="ratingSystem"
                                                                placeholder="Enter a rating" 
                                                                value={form.ratingSystem[idx].rating} 
                                                                onChange={(e) => handleUpdateRating(form, setForm, idx, e.target.value)} />
                                                            <Button 
                                                                variant="destructive" 
                                                                className="w-32 h-8 mt-2"
                                                                onClick={(e) => {popFromArray(form, setForm, "ratingSystem", idx)}}>Remove</Button>
                                                        </div>
                                                        <div className="feedback-color">
                                                            <HexColorPicker 
                                                                color={form.ratingSystem[idx].color} 
                                                                onChange={(color) => handleUpdateColor(form, setForm, "ratingSystem", idx, color)} />
                                                        </div>
                                                    </div>                                        
                                                </CardContent>
                                            )
                                        })
                                    :
                                        null
                                }
                                { form.ratingSystem.length < 5 ? 
                                    <Button 
                                        onClick={(e) => {pushNewToArray(form, setForm, "ratingSystem", "rating")}} 
                                        className="mt-4">
                                        + Rating
                                    </Button>                                
                                :
                                    null
                                } 
                            </div>                      
                        </div>
                        </CardContent>
                    </Card>
                    { mode.toLowerCase() == "create" ?
                        <Card className="w-90" x-chunk="dashboard-07-chunk-1">
                            <CardHeader>
                            <CardTitle>Walls</CardTitle>
                            <CardDescription>
                                Setup the initial walls, and their first wall sets with 3D model storage URLs
                            </CardDescription>
                            </CardHeader>
                            <CardContent>
                            { form.walls.map((wall, wallIdx) => {
                                return <>
                                <div className="flex flex-row justify-between mb-4">
                                    <Label htmlFor="address">Wall #{wallIdx + 1}</Label>
                                    <CircleX 
                                        color="red"
                                        className="h-4 w-4"
                                        onClick={() => removeNewWall(form, setForm, wallIdx)} />
                                </div>
                                    
                                    <div className="flex flex-row items-center justify-start ml-2">
                                        <Label
                                            className="w-24"
                                            htmlFor="wallName">Name {" "}</Label>
                                        <Input
                                        id="wallName" 
                                        name="wallName"
                                        required
                                        type="text"
                                        className="w-full ml-2"
                                        placeholder="Enter a wall name"
                                        value={form.walls[wallIdx].wallName}
                                        onChange={(e) => handleChangeWallName(form, setForm, wallIdx, e.target.value)}
                                        />
                                    </div>      
                                    {
                                        form.walls[wallIdx].wallSets.map((wallSet, wallSetIdx) => {
                                            return <>
                                                <Card className="m-2">
                                                    <CardContent className="p-4 pt-2">
                                                        <Label
                                                            className="w-24"
                                                            htmlFor="wallSet">Wall Set {" "}</Label>
                                                        <Input
                                                        id="date" 
                                                        name="date"
                                                        required
                                                        disabled
                                                        type="text"
                                                        className="w-full"
                                                        placeholder="Enter a wall name"
                                                        value={form.walls[wallIdx].wallSets[wallSetIdx].date}
                                                        />
                                                        <Label
                                                            className="w-24"
                                                            htmlFor="wallSetURL">Wall Set URL {" "}</Label>
                                                        <Input
                                                        id="wallSetURL" 
                                                        name="wallSetURL"
                                                        required
                                                        type="text"
                                                        className="w-full"
                                                        placeholder="Enter a wall set URL"
                                                        value={form.walls[wallIdx].wallSets[wallSetIdx].wallSetURL}
                                                        onChange={(e) => handleWallSetField(form, setForm, wallIdx, wallSetIdx, "wallSetURL", e.target.value)}
                                                        />
                                                        <Label
                                                            className="w-24"
                                                            htmlFor="wallGeometry">Wall Set Geometry {" "}</Label>
                                                        <Input
                                                        id="wallGeometry" 
                                                        name="wallGeometry"
                                                        required
                                                        type="text"
                                                        className="w-full"
                                                        placeholder="Enter the name of the geometry"
                                                        value={form.walls[wallIdx].wallSets[wallSetIdx].wallGeometry}
                                                        onChange={(e) => handleWallSetField(form, setForm, wallIdx, wallSetIdx, "wallGeometry", e.target.value)}
                                                        />
                                                        <Label
                                                            className="w-24"
                                                            htmlFor="wallGeometry">Wall Set Material {" "}</Label>
                                                        <Input
                                                        id="wallMaterial" 
                                                        name="wallMaterial"
                                                        required
                                                        type="text"
                                                        className="w-full"
                                                        placeholder="Enter the name of the material"
                                                        value={form.walls[wallIdx].wallSets[wallSetIdx].wallMaterial}
                                                        onChange={(e) => handleWallSetField(form, setForm, wallIdx, wallSetIdx, "wallMaterial", e.target.value)}
                                                        />
                                                        
                                                    </CardContent>  
                                                    <CardFooter className="flex flex-row justify-around px-2 pt-0 pb-3">                                                   
                                                        {wallSetIdx == form.walls[wallIdx].wallSets.length - 1 ?
                                                            <Button 
                                                                size="sm"
                                                                onClick={(e) => {addNewWallSet(form, setForm, wallIdx)}}>
                                                                + Wall Set
                                                            </Button>
                                                        :
                                                            null
                                                        }
                                                        { wallSetIdx > 0 ?
                                                            <Button 
                                                                variant="destructive"
                                                                size="sm"
                                                                onClick={() => {removeNewWallSet(form, setForm, wallIdx, wallSetIdx)}}>Remove Wall Set
                                                            </Button>
                                                        :
                                                            null
                                                        }                                               
                                                    </CardFooter>
                                                </Card>   
                                            </>
                                        })                            }                      
                                    <Separator className="my-4" />

                                </>
                                    
                                })
                            }   
                            
                            </CardContent>
                            <CardFooter className="justify-center border-t p-4">
                            <Button 
                                size="sm" 
                                variant="ghost" 
                                className="gap-1"
                                onClick={() => addNewWall(form, setForm)}>
                                <PlusCircle className="h-3.5 w-3.5" />
                                Add Wall
                            </Button>
                            </CardFooter>
                        </Card>
                    :
                        null
                    }                    
                    <Card className="w-90 ">
                        <CardHeader>
                        <CardTitle>User Permissions</CardTitle>
                        <CardDescription>
                            Enter account permissions for the gym author, collaborators, and initial privacy settings
                        </CardDescription>
                        </CardHeader>
                        <CardContent>
                        <div className="grid gap-6">                      
                            <div className="grid gap-3">
                                <Label htmlFor="privacy">Privacy</Label>                            
                                <Select 
                                    id="privacy"
                                    name="privacy"
                                    required
                                    value={form.privacy}
                                    onValueChange={(value) => setForm({...form, privacy: value})}
                                    >
                                    <SelectTrigger
                                    id="privacy"
                                    aria-label="Select a privacy option"
                                    >
                                    <SelectValue placeholder="Select a privacy option" />
                                    </SelectTrigger>
                                    <SelectContent>
                                    <SelectItem value="public">
                                        Public
                                    </SelectItem>
                                    <SelectItem value="private">
                                        Private
                                    </SelectItem>
                                    </SelectContent>
                                </Select>
                                <Label htmlFor="author">Author</Label>                            
                                <Select 
                                    id="author"
                                    name="author"
                                    required
                                    value={form.authorID}
                                    onValueChange={(value) => handleUpdateAuthor(userList, form, setForm, value)}
                                    >
                                    <SelectTrigger
                                    id="author"
                                    aria-label="Select a gym owner/manager"
                                    >
                                    <SelectValue placeholder="Select a gym owner/manager" />
                                    </SelectTrigger>
                                    <SelectContent>
                                        {userList && userList.map((user) => {
                                            if (!checkUserGroup(form.collaborators, user.userID)) {
                                                return <SelectItem 
                                                        key={user.username + user._id} 
                                                        value={user.userID}>
                                                            {user.username}
                                                    </SelectItem>
                                            }                                            
                                        })}
                                    </SelectContent>
                                </Select>
                                <Label htmlFor="name">Collaborators</Label>
                                <Select 
                                    id="collaborators"
                                    name="collaborators"
                                    required
                                    value={currentCollaborator}
                                    onValueChange={(value) => handleUpdateCollaborator(userList, form, setForm, setAlertMessages, setAlertWarning, setCurrentCollaborator, value)}
                                    >
                                    <SelectTrigger
                                    id="collaborator"
                                    aria-label="Add a collaborator"
                                    >
                                    <SelectValue placeholder="Add a collaborator" />
                                    </SelectTrigger>
                                    <SelectContent>
                                        {userList && userList.map((user) => {
                                            if (user.userID !== form.authorID) {
                                                return <SelectItem 
                                                    key={user.username + user._id} 
                                                    value={user.userID}>
                                                        {user.username}
                                                </SelectItem>
                                            }                                            
                                        })}
                                    </SelectContent>
                                </Select>  
                                { form.collaborators.length > 0 ? 
                                    <Card className="w-52 mx-auto">
                                        <CardHeader className="p-2 pb-2">
                                            <Label>Collaborator List</Label>
                                        </CardHeader>
                                        <CardContent className="p-2">
                                            <ScrollArea className="h-14 rounded-md border p-1">
                                            {form.collaborators.map((item) => {
                                                return <div className="flex flex-row items-center justify-between">
                                                    <p className="text-sm">{item.username}</p>
                                                    <CircleX 
                                                        color="red"
                                                        className="h-3.5 w-3.5"
                                                        onClick={() => handleRemoveCollaborator(form, setForm, setAlertMessages, setAlertWarning, setCurrentCollaborator, item.userID)} />
                                                </div>
                                            })}
                                            </ScrollArea>
                                        </CardContent>                                        
                                    </Card> 
                            
                                :
                                    null
                                }
                                                     
                            </div>                      
                        </div>
                        </CardContent>
                    </Card>                
                    </div>                    
                </div>
                <div className="flex items-center justify-center gap-2">
                    <Button variant="outline" size="sm">
                    Discard
                    </Button>
                    {submitFunction}
                </div>
                </div>
            </main>
            </div>
        </div>
    )
}


export default FormGym