import { Link } from 'react-router-dom'
import { ButtonLink } from '../buttons/ButtonLink'
import { Button } from '../../../components/ui/button'
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
  } from "../../../components/ui/table"
  import {
    Popover,
    PopoverContent,
    PopoverTrigger,
  } from "../../../components/ui/popover.jsx"

  export function ExploreTable({headings, exploreGyms, today}) {
    return (
      <Table>
        <TableHeader>
          <TableRow>
            { headings.map((heading, idx) => {
              if (idx == 0 || idx > 2) { 
                return <TableHead className="hidden md:table-cell">{heading}</TableHead>
              }
              
              return <TableHead>{heading}</TableHead>
            })}
          </TableRow>
        </TableHeader>
        <TableBody>
          {exploreGyms.map((gym, idx) => (
            <TableRow>
                <TableCell className="hidden md:table-cell">
                  <Link to={{ pathname: "/gym/" + gym._id }}>
                      {idx + 1}
                  </Link>
                </TableCell>
                <TableCell>
                  <Popover>
                      <PopoverTrigger>                      
                        {gym.gymName} 
                      </PopoverTrigger>
                      <PopoverContent className="w-64">
                          <div className="flex flex-col justify-start items-start">
                              <div className="flex flex-row justify-start mt-2">
                                  <h4 className="text-sm font-semibold w-14 mr-2">
                                      Gym
                                  </h4>
                                  <p className={`w-32 text-sm text-wrap ml-2 border p-1`}>{gym.gymName}</p>
                              </div>
                              <div className="flex flex-row justify-start mt-2">
                                  <h4 className="text-sm font-semibold w-14 mr-2">
                                    Hours
                                  </h4>
                                  <p className="w-32 text-sm text-wrap ml-2 border p-1">
                                    {"[" + today.day + "] " + gym.openHours[today.hoursIdx]}   
                                  </p>
                              </div>
                              <div className="flex flex-row justify-start mt-2">
                                  <h4 className="text-sm font-semibold w-14 mr-2">
                                    Address
                                  </h4>
                                  <p className="w-32 text-sm text-wrap ml-2 border py-1">
                                    {gym.location.address}
                                  </p>
                              </div>
                              <div className="flex flex-row justify-start mt-2">
                                  <h4 className="text-sm font-semibold w-14 mr-2">
                                    City
                                  </h4>
                                  <p className="w-32 text-sm text-wrap ml-2 border py-1">
                                    {gym.location.city}
                                  </p>
                              </div>
                              <div className="flex flex-row justify-start mt-2">
                                  <h4 className="text-sm font-semibold w-14 mr-2">
                                    Province
                                  </h4>
                                  <p className="w-32 text-sm text-wrap ml-2 border py-1">
                                    {gym.location.province}
                                  </p>
                              </div>       
                              <div className="mx-auto mt-2">
                                  <ButtonLink link={"/gym/" + gym._id} buttonName="Go to Gym!" />
                              </div>                          
                          </div>
                      </PopoverContent>
                  </Popover>                    
                </TableCell>
                <TableCell className="text-nowrap">
                  {"[" + today.day + "] " + gym.openHours[today.hoursIdx]}                                                            
                </TableCell>
                <TableCell className="hidden text-nowrap md:table-cell">
                  {gym.location.address}
                </TableCell>
                <TableCell className="hidden md:table-cell">
                  {gym.location.city}
                </TableCell>
                <TableCell className="hidden md:table-cell">
                  {gym.location.province}
                </TableCell>                               
            </TableRow>
          ))}
        </TableBody>
      </Table>
    )
  }
  