import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import useForgotPassword from '../api/Site/useForgotPassword.js'
import {AlertWarning} from '../blocks/components/alerts/AlertWarning'
import {ButtonLoading} from '../blocks/components/buttons/ButtonLoading'

import { Button } from "../components/ui/button"
import { Input } from "../components/ui/input"
import { Label } from "../components/ui/label"

const ForgotPassword = () => {
    const [ form, setForm ] = useState({})
    const [ isLoading, setIsLoading ] = useState(false)
    const [ alertVis, setAlertVis ] = useState(false)
    const [ alertsArray, setAlertsArray ] = useState([])

    const handleFormChange = (name, value) => {
        setForm({...form, [name]: value})
    }

    const { onSubmitForgotPassword } = useForgotPassword(form, setAlertVis, setAlertsArray, setIsLoading)
    
    return (
        <div className="bg-zinc-100 w-full lg:grid lg:min-h-[600px] lg:grid-cols-2 xl:min-h-[800px]">
          <AlertWarning 
              modalVis={alertVis} 
              setModalVis={setAlertVis}
              alertsArray={alertsArray} 
          />
            <div className="flex items-center justify-center py-12">
            <div className="mx-auto grid w-[350px] gap-6">
                <div className="grid gap-2 text-center">
                <h1 className="text-3xl font-bold">Forgot Password</h1>
                <p className="text-balance text-muted-foreground">
                    Enter your email below to reset your password
                </p>
                </div>
                <form className="grid gap-4" onSubmit={(e) => onSubmitForgotPassword(e)}>
                    <div className="grid gap-2">
                        <Label htmlFor="email">Email</Label>
                        <Input
                        id="email"
                        type="email"
                        name="email"
                        placeholder="Enter your email"
                        required
                        value={form.email} 
                        onChange={(e) => handleFormChange(e.target.name, e.target.value)}
                        />
                    </div>            
                    { isLoading == false ?
                        <Button type="submit" className="w-full">
                            Login
                        </Button>
                    :
                        <ButtonLoading />
                    }
                </form>
                <div className="mt-4 text-center text-sm">
                Back to Login?{" "}
                <Link 
                    to={{pathname: "/login"}}
                    className="underline">
                    Login
                </Link>
                </div>
            </div>
            </div>
            <div className="hidden bg-muted lg:block bg-indigo-950">                            
            </div>
        </div>
    )
}

export default ForgotPassword