import { useMutation, useQueryClient } from '@tanstack/react-query'
import { axiosAPI } from '../axiosAPI.js'
import { useGymStore } from '../../Beta/data/useGymStore.js'
import { handleUpdatedWall } from '../../Beta/data/useWallStore.js'
import { gymDataFormatter } from '../../helpers/dataProjectFormatters.js'
import { Button } from '../../components/ui/button.jsx'

const UseMutateSendClimbFeedback = ({form, setModalVis, climbID, setNotification, setMessage, setWallName}) => {
    const queryClient = useQueryClient()
    const currentWallID = useGymStore((state) => state.currentWallID)
    const currentWallSetID = useGymStore((state) => state.currentWallSetID)
    const setClimbs = useGymStore((state) => state.setClimbs)

    // send to backend API
    const mutation = useMutation({
        mutationFn: async (climb) => {
            const gymID = queryClient.getQueryData(['gym']).id

            const response = await axiosAPI.put('send-gym-climb-feedback/' + gymID, {wallID: currentWallID, wallSetID: currentWallSetID, climbID: climbID, climb: {feedback: form}})

            return response.data
        }, 
        onSuccess: (data) => {
            queryClient.setQueryData(['gym'], data)
            
            gymDataFormatter(data, currentWallID, currentWallSetID, handleUpdatedWall, setClimbs, setWallName)
            setModalVis(false)
            
            setMessage(["Feedback sent! Thank you!"])
            
            return setNotification(true)
        },
        onError: (error) => {
            setMessage([])
            for (const [key, value] of Object.entries(error.response.data)) {
                setMessage(errorValues => ([...errorValues, value]))
            }
    
            return setNotification(true)
          }
        })

    const onSubmitSendFeedback = (e) => {
        e.preventDefault()

        mutation.mutate()
    }

    
    return (
        <div>
            {mutation.isLoading ? (
            'Sending feedback...'
            ) : (
            <>
                <div className="flex flex-row justify-around mt-6">
                    <Button onClick={(e) => onSubmitSendFeedback(e)}>Submit</Button>
                    <Button onClick={() => setModalVis(false)} variant="destructive">Cancel</Button>
                </div>

                {mutation.isError ? (
                    <>
                        <p className="forgot-password-error">{mutation.error.response.data}</p>
                    </>
                ) : null}
                </>
            )}
        </div>
    )
}

export default UseMutateSendClimbFeedback;