import { useState, useEffect } from 'react'
import { useCoordinatesStore } from '../Beta/data/useCoordinatesStore'

export const useCoordinateUtilities = () => {
  const [ mouseUpCoord, setMouseUpCoord ] = useState(null)
  const [ mouseDownCoord, setMouseDownCoord ] = useState(null)
  const setMouseCoords = useCoordinatesStore((state) => state.setMouseCoords)
  
  useEffect(() => {
    if (mouseDownCoord && mouseUpCoord) {
      const threshold = 0.3
      const xDiff = threshold >= Math.abs(mouseDownCoord.x - mouseUpCoord.x)
      const yDiff = threshold >= Math.abs(mouseDownCoord.y - mouseUpCoord.y)
      const zDiff = threshold >= Math.abs(mouseDownCoord.z - mouseUpCoord.z)

      if (xDiff && yDiff && zDiff) {
        setMouseCoords({x: mouseUpCoord.x, y: mouseUpCoord.y, z: mouseUpCoord.z})
      } 
    }
  }, [mouseUpCoord])

  return { setMouseDownCoord, setMouseUpCoord }
}