import { useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import useResetPassword from '../api/Site/useResetPassword.js'
import {AlertWarning} from '../blocks/components/alerts/AlertWarning'

import { Button } from "../components/ui/button"
import { Input } from "../components/ui/input"
import { Label } from "../components/ui/label"

const ResetPassword = () => {
    const { id } = useParams();
    const [ form, setForm ] = useState({})
    const [ passwordVis, setPasswordVis ] = useState(false)
    const [ alertsArray, setAlertsArray ] = useState([])

    const handleFormChange = (name, value) => {
        setForm({...form, [name]: value})
    }

    const { onSubmitResetPassword } = useResetPassword(id, form, setPasswordVis, setAlertsArray)

    return (
        <div className="bg-zinc-100 w-full lg:grid lg:min-h-[600px] lg:grid-cols-2 xl:min-h-[800px]">
          <AlertWarning 
              modalVis={passwordVis} 
              setModalVis={setPasswordVis}
              alertsArray={alertsArray} 
          />
        <div className="flex items-center justify-center py-12">
          <div className="mx-auto grid w-[350px] gap-6">
            <div className="grid gap-2 text-center">
              <h1 className="text-3xl font-bold">Reset Password</h1>
              <p className="text-balance text-muted-foreground">
                Enter your verification code and new password.
              </p>
            </div>
            <form className="grid gap-4" onSubmit={(e) => onSubmitResetPassword(e)}>
              <div className="grid gap-2">
                <Label htmlFor="email">Verification Code</Label>
                <Input
                  id="resetCode"
                  type="text"
                  name="resetCode"
                  placeholder="Enter your verification code"
                  required
                  value={form.resetCode} 
                  onChange={(e) => handleFormChange(e.target.name, e.target.value)}
                />
              </div>
              <div className="grid gap-2">
                <div className="flex items-center">
                  <Label htmlFor="password">Password</Label>                  
                </div>
                <Input 
                  id="password" 
                  type="password" 
                  name="password"
                  required
                  value={form.password} 
                  onChange={(e) => handleFormChange(e.target.name, e.target.value)}
                    />
              </div>
              <div className="grid gap-2">
                <div className="flex items-center">
                  <Label htmlFor="password">Password</Label>                  
                </div>
                <Input 
                  id="password2" 
                  type="password" 
                  name="password2"
                  required
                  value={form.password} 
                  onChange={(e) => handleFormChange(e.target.name, e.target.value)}
                    />
              </div>
              <Button type="submit" className="w-full">
                Reset Password
              </Button>
            </form>
            <div className="mt-4 text-center text-sm">
              Back to Home?{" "}
              <Link 
                  to={{pathname: "/"}}
                  className="underline">
                Home Page
              </Link>
            </div>
          </div>
        </div>
        <div className="hidden bg-muted lg:block bg-indigo-950">                            
        </div>
      </div>
    )
}

export default ResetPassword