import { useQuery } from '@tanstack/react-query'
import { axiosAPI } from '../axiosAPI.js'

const useQueryExploreGyms = (daysOfWeek, setToday) => {
    const { isLoading, error, data, refetch } = useQuery({
        queryKey: ['explore-gyms'],
        queryFn: async () => {
          const response = await axiosAPI.get('public-gyms')

          const currentDay = new Date().toLocaleString(window.navigator.language, {
                  weekday: 'short'
              }).toLocaleUpperCase()
            
              console.log(response.data[0])
          const numericDayIdx = daysOfWeek.findIndex((day) => day == currentDay)
          const hoursIdx = response.data[0].openHours.findIndex((hours, idx) => idx == numericDayIdx)

          setToday({
              day: currentDay,
              hoursIdx: hoursIdx
          })

          return response.data
        },
        retry: 1,
    })

    return { data, isLoading, error, refetch }
}

export default useQueryExploreGyms;