
import { Collapsible, CollapsibleTrigger, CollapsibleContent } from '../../../components/ui/collapsible'
import { Button } from '../../../components/ui/button'
import {
    ChevronDown,
    ChevronRight,
  } from "lucide-react"

export function CollapsibleBox({isOpen, setIsOpen, title, titleIcon, content}) {
    return (
        <Collapsible
            open={isOpen}
            onOpenChange={setIsOpen}
            className="w-48 space-y-2"
            >
            <div className="flex items-center justify-between space-x-4">
                <span
                    className="flex items-center gap-3 rounded-lg px-3 py-2 text-muted-foreground transition-all hover:text-primary">
                    {titleIcon}
                    {title}
                </span>
                <CollapsibleTrigger asChild>
                <Button variant="ghost" size="sm" className="w-9 p-0">
                    {isOpen ? <ChevronRight /> : <ChevronDown />}
                    <span className="sr-only">Toggle</span>
                </Button>
                </CollapsibleTrigger>
            </div>
            <CollapsibleContent className="space-y-2 px-4">
                {content}
            </CollapsibleContent>
        </Collapsible>
    )
}
