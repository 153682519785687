import {Link} from "react-router-dom"
import useQueryUserClimbs from '../api/Climb/useQueryUserClimbs.js'
import Loading from '../Site/Loading.js'
import PageNotFound from '../Site/PageNotFound.js'
import { Button } from "../components/ui/button"
import { GymTable } from '../blocks/components/data/GymTable'
import { GymFollowing } from '../blocks/components/data/GymFollowing'
import { GymBarGrades } from '../blocks/components/data/GymBarGrades'
import { GymRadarFeedback } from '../blocks/components/data/GymRadarFeedback'
import {
  Activity,
  ArrowUpRight,
  CreditCard,
  Users,
} from "lucide-react"

import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "../components/ui/card"


const xBarKey = "grade"
const yBarKey = "count"

const barData = [
  { grade: "vB", count: 17, fill: "#ffee00" },
  { grade: "v1", count: 24, fill: "#fa077d" },
  { grade: "v2", count: 38, fill: "#072ffa" },
  { grade: "v3", count: 42, fill: "#00e208" },
  { grade: "v4", count: 21, fill: "#fa6c07" },
  { grade: "v6", count: 11, fill: "#fa0707" },
  { grade: "v8", count: 9, fill:  "#000000" },
]

const barConfig = {
  count: {
    label: "Count",
  },
  vB: {
    label: "VB-0",
  },
  v1: {
    label: "V1",
  },
  v2: {
    label: "V2-3",
  },
  v3: {
    label: "V3-4",
  },
  v4: {
    label: "V4-6",
  },
  v6: {
    label: "V6-8",
  },
  v8: {
    label: "V8+",
  },
}

const radarLegend = "rating"
const radarValue = "count"
const radarData = [
  { rating: "Perfect", count: 237 },
  { rating: "Easy", count: 273 },
  { rating: "VeryEasy", count: 209 },
  { rating: "Very Hard", count: 186 },
  { rating: "Hard", count: 305 },
]

const radarConfig = {
  count: {
    label: "Count",
    color: "hsl(var(--chart-1))",
  },
}

const headings = ["#", "Gym", "Feedback"]

const dayOfTheWeek = "SAT"
const followingGyms = [
  {
    gymName: "Poulder Barc",
    hours: "10:00AM to 10:00PM",
    id: "123doobybop"
  },
  {
    gymName: "Bouldhers",
    hours: "9:00AM to 5:00PM",
    id: "321doobybop"
  },
  {
    gymName: "Grand River",
    hours: "12:00PM to 8:00PM",
    id: "Boppydo123"
  },
  {
    gymName: "Oock Rasis",
    hours: "10:30AM to 11:00PM",
    id: "dooby321bop"
  },
  {
    gymName: "Poulder Barc",
    hours: "10:00AM to 10:00PM",
    id: "123doobybop"
  },
  {
    gymName: "Bouldhers",
    hours: "9:00AM to 5:00PM",
    id: "321doobybop"
  },
  {
    gymName: "Grand River",
    hours: "12:00PM to 8:00PM",
    id: "Boppydo123"
  },
  {
    gymName: "Oock Rasis",
    hours: "10:30AM to 11:00PM",
    id: "dooby321bop"
  },
]

const Dashboard = () => {
  const { isLoading, error, data } = useQueryUserClimbs()

  if (isLoading) return <Loading />

  if (error) return <PageNotFound />
  
  return (
    <div className="flex min-h-screen w-full flex-col">    
      <main className="flex flex-1 flex-col gap-4 p-4 md:gap-8 md:p-8">
        <div className="grid gap-4 md:grid-cols-2 md:gap-8 lg:grid-cols-4">
          <Card x-chunk="dashboard-01-chunk-0">
            <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
              <CardTitle className="text-sm font-medium">
                Total Visits
              </CardTitle>
              <Users className="h-4 w-4 text-muted-foreground" />
            </CardHeader>
            <CardContent>
              <div className="text-2xl font-bold">37.5K</div>
              <p className="text-xs text-muted-foreground">
                +20.1% from last month
              </p>
            </CardContent>
          </Card>
          <Card x-chunk="dashboard-01-chunk-1">
            <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
              <CardTitle className="text-sm font-medium">
                Visits (Last 24H)
              </CardTitle>
              <Users className="h-4 w-4 text-muted-foreground" />
            </CardHeader>
            <CardContent>
              <div className="text-2xl font-bold">+1275</div>
              <p className="text-xs text-muted-foreground">
                +180.1% from last month
              </p>
            </CardContent>
          </Card>
          <Card x-chunk="dashboard-01-chunk-2">
            <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
              <CardTitle className="text-sm font-medium">Newest Wall</CardTitle>
              <CreditCard className="h-4 w-4 text-muted-foreground" />
            </CardHeader>
            <CardContent>
              <div className="text-2xl font-bold">Back of Dragon</div>
              <p className="text-xs text-muted-foreground">
                2nd Half
              </p>
            </CardContent>
          </Card>
          <Card x-chunk="dashboard-01-chunk-3">
            <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
              <CardTitle className="text-sm font-medium">Next Wall Set</CardTitle>
              <Activity className="h-4 w-4 text-muted-foreground" />
            </CardHeader>
            <CardContent>
              <div className="text-2xl font-bold">Cave</div>
              <p className="text-xs text-muted-foreground">
                After that, Dragon
              </p>
            </CardContent>
          </Card>
          <GymBarGrades 
            chartData={barData} 
            chartConfig={barConfig} 
            xAxisKey={xBarKey} 
            yAxisKey={yBarKey}/>
          <GymRadarFeedback 
            chartData={radarData} 
            chartConfig={radarConfig} 
            legendType={radarLegend} 
            valueType={radarValue}/>
        </div>
        <div className="grid gap-4 md:gap-8 lg:grid-cols-2 xl:grid-cols-3">
          <Card
            className="xl:col-span-2" x-chunk="dashboard-01-chunk-4"
          >
            <CardHeader className="flex flex-row items-center">
              <div className="grid gap-2">
                <CardTitle>Your Gyms</CardTitle>
                <CardDescription>
                  A list of your climbing gyms.
                </CardDescription>
              </div>
              <Button asChild size="sm" className="ml-auto gap-1">
                <Link to={{pathname: "/"}}>
                  View All
                  <ArrowUpRight className="h-4 w-4" />
                </Link>
              </Button>
            </CardHeader>
            <CardContent>
              <GymTable 
                  headings={headings} 
                  userGyms={data.userGyms} />
            </CardContent>
          </Card>
          <Card x-chunk="dashboard-01-chunk-5">
            <CardHeader>
              <CardTitle>Gyms you follow</CardTitle>
            </CardHeader>
            <CardContent className="grid gap-8">
              <GymFollowing 
                following={followingGyms}
                dOTW={dayOfTheWeek} />
            </CardContent>
          </Card>
        </div>
      </main>
    </div>
  )
}

export default Dashboard