import { useQuery } from '@tanstack/react-query'
import { axiosAPI } from '../axiosAPI.js'

const useQueryAllGyms = () => {
    const { isLoading, error, data, refetch } = useQuery({
        queryKey: ['all-gyms'],
        queryFn: async () => {
          const response = await axiosAPI.get('gyms')

          return response.data
        },
        retry: 1
    })

    return { data, isLoading, error, refetch }
}

export default useQueryAllGyms;