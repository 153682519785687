import { useMutation, useQueryClient } from '@tanstack/react-query'
import { axiosAPI } from '../axiosAPI.js'
import { useBorderPointsStore } from '../../Beta/data/useBorderPointsStore.js'
import { useGymStore, exitEditingBorder } from '../../Beta/data/useGymStore.js'
import { handleUpdatedWall } from '../../Beta/data/useWallStore.js'
import { gymDataFormatter } from '../../helpers/dataProjectFormatters.js'

const useMutateUpdateWallsetClimbWithBorder = (wallID, wallSetID, setHighlightMode, setWallName) => {
    const queryClient = useQueryClient()
    const currentWallSetID = useGymStore((state) => state.currentWallSetID)
    const setClimbs = useGymStore((state) => state.setClimbs)

    // send to backend API
    const mutation = useMutation({
        mutationFn: async (climb) => {
            const gymID = queryClient.getQueryData(['gym']).id

            const response = await axiosAPI.put('update-gym-climb/' + gymID, {wallID: wallID, wallSetID: wallSetID, climbID: climb.id, climb: climb})

            return response.data
        }, 
        onSuccess: (data) => {
            queryClient.setQueryData(['gym'], data)
            
            gymDataFormatter(data, wallID, currentWallSetID, handleUpdatedWall, setClimbs, setWallName)

            return setHighlightMode(false)
        },
        onError: (error, variables, context) => {
        }
    })

    const onSubmitUpdateWallsetClimbWithBorder = (e, currentPoints) => {
        e.preventDefault()

        const setCurrentPoints = useBorderPointsStore.getState().setCurrentPoints
    
        let updatedClimb = {...useGymStore.getState().climbToEdit}
        
        const parsedBorder = currentPoints.map((point) => {
            return point
        })

        updatedClimb.borderPoints = parsedBorder

        mutation.mutate(updatedClimb)

        // clear holds and reset modals to clean
        setCurrentPoints([])
        useGymStore.getState().setClimbToEdit({})
    }

    return { onSubmitUpdateWallsetClimbWithBorder }
}

export default useMutateUpdateWallsetClimbWithBorder;