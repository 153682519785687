import { useState } from 'react'
import { Link } from 'react-router-dom'
import useSignup from '../api/Site/useSignup'

import {AlertWarning} from '../blocks/components/alerts/AlertWarning'

import { Button } from "../components/ui/button"
import { Input } from "../components/ui/input"
import { Label } from "../components/ui/label"

const Signup = () => {
    const [ form, setForm ] = useState({firstName: '', lastName: '', username: '', email: '', password: '', password2: ''})
    const [ passwordVis, setPasswordVis ] = useState(false)
    const [ alertsArray, setAlertsArray ] = useState([])

    const handleFormChange = (name, value) => {
        setForm({...form, [name]: value})
    }

    const { onSubmitSignup } = useSignup(form, setPasswordVis, setAlertsArray)
    
    return (
      <div className="bg-zinc-100 w-full lg:grid lg:min-h-[600px] lg:grid-cols-2 xl:min-h-[800px]">
          <AlertWarning 
              modalVis={passwordVis} 
              setModalVis={setPasswordVis}
              alertsArray={alertsArray} 
          />
        <div className="flex items-center justify-center py-12">
          <div className="mx-auto grid w-[350px] gap-6">
            <div className="grid gap-2 text-center">
              <h1 className="text-3xl font-bold">Signup</h1>
              <p className="text-balance text-muted-foreground">
                Fill in your details to create an account
              </p>
            </div>
            <form className="grid gap-4" onSubmit={(e) => onSubmitSignup(e)}>
              <div className="grid gap-2">
                <Label htmlFor="firstName">First Name</Label>
                <Input
                  id="firstName"
                  type="text"
                  name="firstName"
                  placeholder="Enter your first name"
                  required
                  value={form.firstName} 
                  onChange={(e) => handleFormChange(e.target.name, e.target.value)}
                />
              </div>
              <div className="grid gap-2">
                <Label htmlFor="lastName">lastName</Label>
                <Input
                  id="lastName"
                  type="text"
                  name="lastName"
                  placeholder="Enter your last name"
                  required
                  value={form.lastName} 
                  onChange={(e) => handleFormChange(e.target.name, e.target.value)}
                />
              </div>
              <div className="grid gap-2">
                <Label htmlFor="username">Username</Label>
                <Input
                  id="username"
                  type="text"
                  name="username"
                  placeholder="Enter a username"
                  required
                  value={form.username} 
                  onChange={(e) => handleFormChange(e.target.name, e.target.value)}
                />
              </div>
              <div className="grid gap-2">
                <Label htmlFor="email">Email</Label>
                <Input
                  id="email"
                  type="email"
                  name="email"
                  placeholder="Enter your email"
                  required
                  value={form.email} 
                  onChange={(e) => handleFormChange(e.target.name, e.target.value)}
                />
              </div>
              <div className="grid gap-2">
                <div className="flex items-center">
                  <Label htmlFor="password">Password</Label>
                </div>
                <Input 
                  id="password" 
                  type="password" 
                  name="password"
                  placeholder="Enter a password"
                  required
                  value={form.password} 
                  onChange={(e) => handleFormChange(e.target.name, e.target.value)}
                    />
              </div>
              <div className="grid gap-2">
                <div className="flex items-center">
                  <Label htmlFor="password2">Confirm Password</Label>                    
                </div>
                <Input 
                  id="password2" 
                  type="password" 
                  name="password2"
                  placeholder="Confirm your password"
                  required
                  value={form.password2} 
                  onChange={(e) => handleFormChange(e.target.name, e.target.value)}
                    />
              </div>
              <Button type="submit" className="w-full">
                Signup
              </Button>
            </form>
            <div className="mt-4 text-center text-sm">
              Already have an account?{" "}
              <Link 
                  to={{pathname: "/login"}}
                  className="underline">
                Login
              </Link>
            </div>
          </div>
        </div>
        <div className="hidden bg-muted lg:block bg-indigo-950">                            
        </div>
      </div>
  )
}

export default Signup