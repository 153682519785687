import { ButtonLink } from '../blocks/components/buttons/ButtonLink'

const About = () => {
    return (
        <div className="container max-w-full mt-8">
          <h1 className="scroll-m-20 text-4xl font-extrabold tracking-tight lg:text-5xl">
              About
          </h1>
          <p className="leading-7 [&:not(:first-child)]:mt-6">
              Hi, my name is Jonathan and I am an avid indoor boulderer! Following a deload training week away from the 
              climbing wall, debating max hang half crimps on my bathroom door frame for 7 seconds per set so as not to 
              lose my finger strength, I stumbled upon the idea of beta-break.
          </p>
          <p className="leading-7 [&:not(:first-child)]:mt-6">
              beta-break is a climbing gym wall visualization tool utilizing 3D modeling to showcase a gym's current, new, and past walls' climbing problems.
          </p>
          <h2 className="mt-10 scroll-m-20 border-b pb-2 text-3xl font-semibold tracking-tight transition-colors first:mt-0">
            Who is this for?
          </h2>
          <blockquote className="mt-6 border-l-2 pl-6 italic">
              For climbing gyms, beta-break showcases the climbing problems set in your climbing gym to your climbers. It
              also allows those climbers to send feedback on the climbs!
          </blockquote>
          <blockquote className="mt-6 border-l-2 pl-6 italic">
            For route setters, beta-break allows you to view and feel better informed about climber feedback through our consolidated
            data table view.
          </blockquote>    

          <div className="my-6 w-full overflow-y-auto">
            <p className="leading-7 [&:not(:first-child)]:mt-6">
                If you are looking for guides, updates, and other cool information about beta-break, checkout our blog!

            </p>
            <div className="flex flex-row justify-around">
                <ButtonLink
                    link={"/blog"}
                    buttonName={"Blog"}
                />
                <ButtonLink
                    link={"/"}
                    buttonName={"Home"}
                />
            </div>
                    
          </div>        
        </div>
      )         
}

export default About
  