import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useQueries } from '@tanstack/react-query'
import Loading from '../Site/Loading.js'
import PageIsPrivate from './PageIsPrivate.js'
import PageNotFound from '../Site/PageNotFound.js'
import UseMutateUpdateGym from '../api/Gym/UseMutateUpdateGym'
import useQueryGymAndUsers from '../api/Gym/useQueryGymAndUsers.js'
import { axiosAPI } from '../api/axiosAPI'
import FormGym from './FormGym'
import '../tailwind.css'

const baseFormState = { isLoaded: false,
    location: { address: '', city: '', province: '', postalCode: ''},
    openHours: ['', '', '', '', '', '', ''],
    gradingType: "text",
    gradingSystem: [],
    ratingSystem: [],
    walls: [],
    collaborators: []}

const EditGym = () => {
    const { id } = useParams()
    const [ userId, setUserId ] = useState(sessionStorage.getItem('userId') ? sessionStorage.getItem('userId') : '')

    const { data, isLoading, error } = useQueryGymAndUsers(id)

    const userListAndGym = useQueries({queries: [
        {
          queryKey: ["user-list"],
          queryFn: async () => {
            const response = await axiosAPI.get('user-list')
  
            return response.data
          },        
        },
        {
          queryKey: ["edit-gym"],
          queryFn: async () => {
            const response = await axiosAPI.get('gym/' + id)
  
            if (response.data.userAuthorCheck.userIsAuthor) {
              setUserId(response.data.userAuthorCheck.userId)
              sessionStorage.setItem("userId", response.data.userAuthorCheck.userId)
            } else {
              setUserId('')
              sessionStorage.clear()
            }
  
            return response.data.gym
          }
        }]});

    const [ form, setForm ] = useState(baseFormState)
    const [ alertWarning, setAlertWarning] = useState(false)
    const [ alertMessages, setAlertMessages ] = useState([])

    useEffect(() => {        
        // if (data !== undefined) {
        //     if (form.isLoaded == false && userListAndGym[1].data._id == id) {
        //         setForm({...userListAndGym[1].data, isLoaded: true})
        //     } else if (userListAndGym[1].data._id !== id) {
        //         setForm(baseFormState)
        //     }
        // }
        if (data !== undefined) {
          setForm({...data.gym})

        }        
    }, [data])
    
    if (isLoading) return <Loading />

    if (error) {
        if (error.response.data.error == "private") {
            return <PageIsPrivate errMessage={error.response.data.message} />
        } else {
            return <PageNotFound />
        }
    } 

    return (
        <FormGym 
            mode={"Edit"} 
            heading={"Edit a Gym"} 
            subHeading={"Modify or add gym information"} 
            form={form} 
            setForm={setForm} 
            userList={data.userList}
            alertWarning={alertWarning}
            setAlertWarning={setAlertWarning}
            alertMessages={alertMessages}
            setAlertMessages={setAlertMessages}
            submitFunction={<UseMutateUpdateGym gymInfo={form} setNotification={setAlertWarning} setMessage={setAlertMessages} />}
        />

    )
}


export default EditGym