import { passwordStrength } from 'check-password-strength'

export const passwordCheck = (passwordValue, password2Value) => {
    const isPasswordValid = passwordStrength(passwordValue)
    const errorAlerts = []

    if (isPasswordValid.value !== "Strong") {
        errorAlerts.push(`Password strength is ${isPasswordValid.value}`)
    }

    if (isPasswordValid.length < 8 || isPasswordValid.length > 16) {
        errorAlerts.push("Password length must be between 8 and 16 characters!")                                                 
    }

    if (isPasswordValid.contains.includes("lowercase") == false) {
        errorAlerts.push("Password must contain a lowercase character!")
    }

    if (isPasswordValid.contains.includes("uppercase") == false) {
        errorAlerts.push("Password must contain an uppercase character!")
    }

    if (isPasswordValid.contains.includes("symbol") == false) {
        errorAlerts.push("Password must contain a symbol !#$%&'()*+,-./:;<=>?@[\]^_`{|}~" + '"')
    }

    if (isPasswordValid.contains.includes("number") == false) {
        errorAlerts.push("Password must contain a number!")
    }

    if (isPasswordValid.length < 8 || isPasswordValid.length > 16) {
        errorAlerts.push("Password length must be between 8 and 16 characters!")                                                 
    }

    if (passwordValue !== password2Value) {
        errorAlerts.push("Passwords do not match!")
    }

    if (errorAlerts.length > 0) {
        return { valid: false, errorAlerts: errorAlerts}
    } else {
        return { valid: true, errorAlerts: [] }
    }
}