import { Link } from 'react-router-dom';
import { useState } from 'react'
import { tailspin, bouncy } from 'ldrs'

tailspin.register()
bouncy.register()

const Loading = () => {
    const [ userId, setUserId ] = useState(sessionStorage.getItem('userId') ? sessionStorage.getItem('userId') : null)

    return (
        <div className="page404-panel">
            <div className="loading-container">
                <l-tailspin
                size="200"
                stroke="12"
                stroke-length="0.15"
                bg-opacity="0.1"
                speed="1.5" 
                color="#54a8c7" 
                ></l-tailspin>             
            </div>
            <div className="loading-container loading-text-box">
                <p className="page404-heading">
                    Loading                                
                </p>
            </div>
            <div className="loading-container loading-button-box">
                { userId !== null ?
                    <Link to={{pathname: "/climbs"}} className="site-banner-link">
                        <h1 className="button-option button-list">Go Home</h1>
                    </Link>
                :
                    <Link to={{pathname: "/"}} className="site-banner-link">
                        <h1 className="button-option button-list">Go Home</h1>
                    </Link>
                }            
            </div>
        </div>
    )
}

export default Loading