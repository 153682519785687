import LightBulb from "../assets/img/lightbulb.png"
import Chart from "../assets/img/chart.png"
import Dev from "../assets/img/dev.png"
import AboutImage from "../assets/img/about-img.png"
import { H1 } from '../blocks/components/typography/H1.js'
import { H3 } from '../blocks/components/typography/H3.js'
import { BlockQuote } from '../blocks/components/typography/BlockQuote.js'
import { Paragraph } from '../blocks/components/typography/Paragraph.js'
import { ButtonLink } from '../blocks/components/buttons/ButtonLink.js'
import { CardInfoLink } from '../blocks/components/cards/CardInfoLink.js'
import { CardInfoIcon } from '../blocks/components/cards/CardInfoIcon.js'

const Home = () => {
    return (
        <>
            {/* <section className="site-panel header-spacer"> */}
            <section className="container max-w-full bg-indigo-950">
                <div className="w-fit text-center mx-auto my-auto pt-14 pb-40">
                    <H1 text={"Welcome to beta-break!"}
                        color={"text-indigo-200"} />
                    <Paragraph text={"A climbing gym wall visualization tool utilizing 3D modeling to showcase a gym's current, new, and past walls' climbing problems."} 
                               color={"text-indigo-300"} />    
                </div>
            </section>
            {/* Refactor for Tailwind */}
            <section className="about-transition">
                <div className="about-imagebox">
                    <img src={AboutImage} className="about-transitionimage" />
                </div>
                <div className="flex flex-row justify-center mb-4">
                    <ButtonLink link={"/explore-gyms"} buttonName={"Explore Gyms!"} />
                </div>
            </section>
            <section className="container max-w-full px-2 py-4 bg-indigo-950">
                    <H3 text={"Who are we?"}
                        color={"text-indigo-200"} />
                    <div className="flex flex-col justify-center items-center overflow-scroll sm:flex-row sm:justify-around">
                        <CardInfoLink heading={"About"}
                                        subtext={"A quick introduction on how beta-break came to be."}
                                        linkButton={<ButtonLink link={"/about"} buttonName={"Learn more!"} />} />
                        <CardInfoLink heading={"How it works"}
                                        subtext={"An outline of the process to update your climbing gym."}
                                        linkButton={<ButtonLink link={"/howitworks"} buttonName={"Process"} />} />
                        <CardInfoLink heading={"Blog"}
                                        subtext={"For progress updates or guides on using beta-break!"}
                                        linkButton={<ButtonLink link={"/blog"} buttonName={"Check it out!"} />} />                                                                                          
                    </div>
            </section>
            <section>
                <div className="py-5 mx-2">
                    <BlockQuote text={"- SUGGESTIONS ON FEATURES?"} color={"text-indigo-500"} />
                    <H3 text={"We would love to connect and hear about features you would like implemented."}
                        color={"text-indigo-950"} />
                    <div className="flex-col pt-2">
                        <CardInfoIcon heading={"Feature Implementation"}
                                      subtext={"You know what you want, and we would love to add features allowing you to use beta-break in the best way."}
                                      icon={<img src={LightBulb} className="w-12 h-auto pt-2" />} />     
                        <CardInfoIcon heading={"Community Updates"}
                                      subtext={"Check out our blog to see what we are working on, guides on using beta-break, and more!"}
                                      icon={<img src={Chart} className="w-12 h-auto pt-2" />} />     
                        <CardInfoIcon heading={"Development Updates"}
                                      subtext={"We run regular maintenance and updates. If you are running into issues, please contact us!"}
                                      icon={<img src={Dev} className="w-12 h-auto pt-2" />} />                                                                                                                                                                              
                        <div className="mx-auto mt-4">
                            <ButtonLink link={"/contact-us"} buttonName={"Contact Us"} />
                        </div>
                    </div>
                </div>
            </section>
        </>    
    )
}

export default Home