import { useQuery } from '@tanstack/react-query'
import { axiosAPI } from '../axiosAPI.js'

const useQueryGym = (id, setUserId) => { 

    const { isLoading, error, data } = useQuery({
        queryKey: ['list-walls'],
        queryFn: async () => {
          const response = await axiosAPI.get('gym-protected/' + id)

          if (response.data.userAuthorCheck.userIsAuthor) {
            setUserId(response.data.userAuthorCheck.userId)
            sessionStorage.setItem("userId", response.data.userAuthorCheck.userId)
          } else {
            setUserId('')
            sessionStorage.clear()
          }

          return response.data.gym
        },
        retry: 1
    })

    return { data, isLoading, error }
}

export default useQueryGym;