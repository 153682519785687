import { useState } from 'react'
import Loading from '../Site/Loading.js'
import PageIsPrivate from './PageIsPrivate.js'
import PageNotFound from '../Site/PageNotFound.js'
import useQueryUserList from '../api/Site/useQueryUserList'
import UseMutateCreateGym from '../api/Gym/UseMutateCreateGym'

import FormGym from './FormGym'
import '../tailwind.css'

const CreateGym = () => {
    const { data, isLoading, error } = useQueryUserList()
    const [ alertWarning, setAlertWarning] = useState(false)
    const [ alertMessages, setAlertMessages ] = useState([])
    const [ form, setForm ] = useState({ location: { address: '', city: '', province: '', postalCode: ''},
                                         openHours: ['', '', '', '', '', '', ''],
                                         gradingType: "text",
                                         gradingSystem: [],
                                         ratingSystem: [],
                                         walls: [],
                                         collaborators: []})

    if (isLoading) return <Loading />

    if (error) {
        if (error.response.data.error == "private") {
            return <PageIsPrivate errMessage={error.response.data.message} />
        } else {
            return <PageNotFound />
        }
    } 
    
    return (
        <FormGym 
            mode={"Create"} 
            heading={"Create a Gym"} 
            subHeading={"Enter your gym information"} 
            form={form} 
            setForm={setForm} 
            userList={data}
            alertWarning={alertWarning}
            setAlertWarning={setAlertWarning}
            alertMessages={alertMessages}
            setAlertMessages={setAlertMessages}
            submitFunction={<UseMutateCreateGym form={form} setNotification={setAlertWarning} setMessage={setAlertMessages} />} />
    )
}


export default CreateGym