export function H4({text, color}) {
    const styling = color == undefined ? 
                    "scroll-m-20 text-xl font-extrabold tracking-tight" : 
                    `${color} scroll-m-20 text-xl font-extrabold tracking-tight`

    return (
      <h4 className={styling}>
        {text}
      </h4>
    )
  }