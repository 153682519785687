
import { useQuery } from '@tanstack/react-query'
import { axiosAPI } from '../axiosAPI.js'

const useQueryGym = (id) => { 

    const { isLoading, error, data } = useQuery({
        queryKey: ['gym-users'],
        queryFn: async () => {
          const response = await axiosAPI.get('gym-and-users/' + id)

          return response.data
        },
        retry: 1
    })

    return { data, isLoading, error }
}

export default useQueryGym;