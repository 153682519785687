import { useMutation } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'
import { axiosAPI, setHeaderToken } from '../axiosAPI.js'

const useLogin = (form, setNotification, setMessage) => {
    const navigate = useNavigate()
    
    // send to backend API
    const mutation = useMutation({
        mutationFn: async (form) => {
            const response = await axiosAPI.post('login', form)

            return response.data
        },
        onSuccess: (data) => {
            const accessToken = data.accessToken
            
            sessionStorage.setItem("userId", data.userId)
            sessionStorage.setItem("firstName", data.firstName)
            sessionStorage.setItem("lastName", data.lastName)
            sessionStorage.setItem("email", data.email)

            setHeaderToken(accessToken)
            
            return navigate('/dashboard')
        },
        onError: (error) => {
            setMessage([])
            for (const [key, value] of Object.entries(error.response.data)) {
                setMessage(errorValues => ([...errorValues, value]))
            }
    
            return setNotification(true)
          }
    })

    const onSubmitLogin = (e) => {
        e.preventDefault()

        mutation.mutate(form)
    }
    
    return { onSubmitLogin }
}

export default useLogin;