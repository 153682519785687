import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { Pencil, Trash2 } from 'lucide-react'
import useMutateAdminDeleteWallset from '../api/Gym/useMutateAdminDeleteWallset'
import Loading from '../Site/Loading.js'
import PageIsPrivate from './PageIsPrivate.js'
import PageNotFound from '../Site/PageNotFound.js'
import {AddWallSet} from '../blocks/components/data/AddWallSet.js'
import {EditWallSet} from '../blocks/components/data/EditWallSet.js'
import { AlertModal } from '../blocks/components/alerts/AlertModal.js'
import useQueryGymWallSets from '../api/Gym/useQueryGymWallSets'
import { ScrollArea } from '../components/ui/scroll-area.jsx'
import { Separator } from '../components/ui/separator.jsx'
import { Button } from '../components/ui/button.jsx'
import {
    Card,
    CardContent,
    CardDescription,
    CardFooter,
    CardHeader,
    CardTitle,
  } from "../components/ui/card.jsx"

const ListWallSetsGym = () => {
    const { id } = useParams()
    const [ addSetModal, setAddSetModal ] = useState(false)
    const [ editSetModal, setEditSetModal ] = useState(false)
    const [ deleteSetModal, setDeleteSetModal ] = useState(false)
    const [ wallId, setWallId ] = useState(null)
    const [ wallSetId, setWallSetId ] = useState(null)
    const [ newWallSet, setNewWallSet ] = useState(null)

    const [ userId, setUserId ] = useState(sessionStorage.getItem('userId') ? sessionStorage.getItem('userId') : '')
    const { data, isLoading, error } = useQueryGymWallSets(id, setUserId)

    const { onSubmitDeleteWallset } = useMutateAdminDeleteWallset(id, wallId, wallSetId, setDeleteSetModal)

    // Header for wall sets listings

    // Can Gyms interact with their own wall sets?

    // Cards representing each wall 
    // Each Wall listed and the wall set is represented as an input scroll area

    // What is the purpose of the List a Wall and Wall sets?
    //    1. To see all their walls and wall sets.
    //    2. To edit a wall set if the links were entered incorrectly
    //    3. To delete a wall set if the data was applied to the wrong gym
    //    4. To add a wall set, copying the modal from AdminGyms
    //    3. To move a wall set to a different wall

    if (isLoading) return <Loading />

    if (error) {
        if (error.response.data.error == "private") {
            return <PageIsPrivate errMessage={error.response.data.message} />
        } else {
            return <PageNotFound />
        }
    } 

    return <>
        <div className="min-h-screen w-full p-4 flex-col bg-muted/40">
            <AddWallSet 
                modalVis={addSetModal}
                setModalVis={setAddSetModal}
                gymData={data}
                gymId={id}
                wallId={wallId == null ? '' : wallId}
                setWallId={setWallId}
                newWallSet={newWallSet}
                setNewWallSet={setNewWallSet} />
            <EditWallSet
                modalVis={editSetModal}
                setModalVis={setEditSetModal}
                gymData={data}
                gymId={id}
                wallId={wallId}
                wallSetId={wallSetId} />
            {/* <DeleteWallSet
                modalVis={deleteSetModal}
                setModalVis={setDeleteSetModal}
                wallSetData={}
                gymId={id}
                wallId={wallId} /> */} 
            <AlertModal 
                modalVis={deleteSetModal}
                setModalVis={setDeleteSetModal}
                heading={"Delete this wall set?"}
                subHeading={"This action cannot be undone. This will permanently delete the wall set."} 
                submitFunction={(e) => onSubmitDeleteWallset(e)} 
            />           
            <Card x-chunk="dashboard-06-chunk-0">
                <CardHeader>
                <CardTitle>List Walls and Wall Sets</CardTitle>
                <CardDescription>
                    Options to add, edit, delete, and move wall sets.
                </CardDescription>
                <Button
                    size="sm"
                    className="px-2"
                    style={{marginTop: "0"}}
                    onClick={() => setAddSetModal(true)}>
                    + Wall Set
                </Button>
                </CardHeader>
                <CardContent>
                    {data.walls.map((wall) => {
                        return <Card className="mb-4">
                            <CardHeader className="flex flex-row items-center justify-between">
                                <CardTitle>{wall.wallName}</CardTitle>

                            </CardHeader>
                            <CardContent>
                                <ScrollArea className="h-48 w-48 rounded-md border">
                                    <div className="p-4">
                                        <h4 className="mb-4 text-sm font-medium leading-none">Wall Sets</h4>
                                        {wall.wallSets.map((wallSet) => {
                                            const wallSetDate = new Date(Date.parse(wallSet.date)).toLocaleDateString()
                                            return <>
                                            <div className="flex flex-row justify-between">
                                                <p className="text-xs">
                                                    { wallSetDate }
                                                </p>
                                                <div className="flex flex-row w-12 justify-between">
                                                    <Pencil
                                                        className="w-4 h-4"
                                                        onClick={() => {setWallId(wall._id); setWallSetId(wallSet._id); setEditSetModal(true)}} />
                                                    <Trash2 
                                                        className="w-4 h-4"
                                                        color="red"
                                                        onClick={() => {setWallId(wall._id); setWallSetId(wallSet._id); setDeleteSetModal(true)}}/>
                                                </div>                                            
                                            </div>
                                            <Separator className="my-2" />
                                            </>
                                        })}  
                                        {wall.wallSets.map((wallSet) => {
                                            const wallSetDate = new Date(Date.parse(wallSet.date)).toLocaleDateString()
                                            return <>
                                            <p className="text-sm">
                                                { wallSetDate }
                                            </p>
                                            <Separator className="my-2" />
                                            </>
                                        })}  
                                        {wall.wallSets.map((wallSet) => {
                                            const wallSetDate = new Date(Date.parse(wallSet.date)).toLocaleDateString()
                                            return <>
                                            <p className="text-sm">
                                                { wallSetDate }
                                            </p>
                                            <Separator className="my-2" />
                                            </>
                                        })}  
                                        {wall.wallSets.map((wallSet) => {
                                            const wallSetDate = new Date(Date.parse(wallSet.date)).toLocaleDateString()
                                            return <>
                                            <p className="text-sm">
                                                { wallSetDate }
                                            </p>
                                            <Separator className="my-2" />
                                            </>
                                        })}  
                                                                                                                                              
                                    </div>
                                </ScrollArea>                                
                            </CardContent>
                        </Card>
                    })}
                </CardContent>
            </Card>
        </div>
    </>
}

export default ListWallSetsGym