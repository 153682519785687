import { useState, useEffect } from 'react'
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectTrigger,
    SelectValue,
  } from "../../../components/ui/select"  
  import { useQueryClient } from '@tanstack/react-query'
  import { useGymStore, getWallSetOptions } from '../../../Beta/data/useGymStore'
  import { useWallStore } from '../../../Beta/data/useWallStore'
  
  export function WallSetSelect({}) {
      const queryClient = useQueryClient()
      const gymData = queryClient.getQueryData(['gym'])
      const currentWallSetID = useGymStore((state) => state.currentWallSetID)
      const setCurrentWallSetID = useGymStore((state) => state.setCurrentWallSetID)
      const setCurrentWallModel = useWallStore((state) => state.setCurrentWallModel)
  
      const [ wallSetOptions, setWallSetOptions ] = useState([])
  
      useEffect(() => {
          setWallSetOptions(getWallSetOptions(gymData))
      },[currentWallSetID, gymData])
  
      const renderOptions = () => {
          let options = []
          for (let i = 0; i < wallSetOptions.length; i++) {
              options.push(<SelectItem key={wallSetOptions[i].wallSetID + '-holdSel-'+i} 
                                       value={wallSetOptions[i].wallSetID} >
                                {new Date(Date.parse(wallSetOptions[i].name)).toLocaleDateString()}
                            </SelectItem>)
          }
          return options
      }
  
      const updateWallSet = (wallSetID) => {
          const wallIndex = wallSetOptions.findIndex((item) => item.wallSetID == wallSetID)

          const modelURL = wallSetOptions[wallIndex].wallModel.modelURL
          const geometry = wallSetOptions[wallIndex].wallModel.geometry
          const material = wallSetOptions[wallIndex].wallModel.material
  
          setCurrentWallModel({ modelURL: modelURL, geometry: geometry, material: material})
          setCurrentWallSetID(wallSetID)
      }

    return (
        <Select
            onValueChange={(value) => {updateWallSet(value)}}
            name="wallSet"
            value={currentWallSetID} >
            <SelectTrigger className="w-[180px]">
                <SelectValue placeholder="Select a wall set" />
            </SelectTrigger>
            <SelectContent>
                <SelectGroup>
                    {renderOptions()}
                </SelectGroup>
            </SelectContent>
        </Select> 
    )
}

