import { create } from 'zustand'
import { useBorderPointsStore } from '../data/useBorderPointsStore'
import { useGymStore } from '../data/useGymStore'
import { WallModel } from '../../assets/WallModel.jsx'

export const useWallStore = create((set) => ({
    currentWallModel: null,
    setCurrentWallModel: (data) => set((state) => ({currentWallModel: data})),
    wallIndex: 0,
    updateWallIndex: (data) => set((state) => ({wallIndex: data})),
    currentProject: {},
    setCurrentProject: (data) => set((state) => ({currentProject: data})),
    renderWall: (wallModel) => {
        return <WallModel modelURL={wallModel.modelURL} 
                          modelGeometry={wallModel.geometry} 
                          modelMaterial={wallModel.material} />
    }
}))

// returns wall options for use in select input
export const getWallOptions = () => {
    let wallOptions = [{
        name: "Vertical",
        wallURL: "https://beta-break.nyc3.digitaloceanspaces.com/walls/default/Vertical.glb"
    },
    {
        name: "Overhang (30 degrees)",
        wallURL: "https://beta-break.nyc3.digitaloceanspaces.com/walls/default/Overhang-30.glb"
    },
    {
        name: "Overhang (45 degrees)",
        wallURL: "https://beta-break.nyc3.digitaloceanspaces.com/walls/default/Overhang-45.glb"
    },
    {
        name: "Slab",
        wallURL: "https://beta-break.nyc3.digitaloceanspaces.com/walls/default/Slab-5.glb"
    },
    {
        name: "Lead/Top Rope",
        wallURL: "https://beta-break.nyc3.digitaloceanspaces.com/walls/default/Lead-Top.glb"
    },
    {
        name: "HUB COMP TEST",
        wallURL: "https://beta-break.nyc3.cdn.digitaloceanspaces.com/walls/Comp-Wall-TEST.glb"
    },
    {
        name: "DRAFT Mushroom Wall",
        wallURL: "https://beta-break.nyc3.digitaloceanspaces.com/walls/Mushroom-Wall.glb"
    }]

    return wallOptions;
}

export const handleUpdatedWall = () => {
    const setCurrentProject = useWallStore.getState().setCurrentProject
    const currentWallModel = useWallStore.getState().currentWallModel
    const renderWall = useWallStore.getState().renderWall

    return setCurrentProject(renderWall(currentWallModel))
}

export const handleWallChange = (wallURL) => {
    const setCurrentPoints = useBorderPointsStore.getState().setCurrentPoints
    const setCurrentWallModel = useWallStore.getState().setCurrentWallModel
    
    //WILL NOT WORK SOON
    setCurrentWallModel(wallURL)
    setCurrentPoints([])
}

export const handleUpdateCurrentGymWall = (_wallID, data) => {
    const setCurrentWallModel = useWallStore.getState().setCurrentWallModel
    const setCurrentWallSetID = useGymStore.getState().setCurrentWallSetID
    const setCurrentWallID = useGymStore.getState().setCurrentWallID

    //When switching the wall to render, in order to find the url to load the model
    // it must go to the currentWallSetID, find the wallSet, then load the wallSetID
    //because there is no wallURL anymore
    const wallIndex = data.walls.findIndex((wall) => wall._id == _wallID)
    setCurrentWallID(data.walls[wallIndex]._id)

    const wallSetID = data.walls[wallIndex].currentWallSetID
    setCurrentWallSetID(wallSetID)

    const wallSetIndex = data.walls[wallIndex].wallSets.findIndex((wallSet) => wallSet._id == wallSetID)
    setCurrentWallModel({ modelURL: data.walls[wallIndex].wallSets[wallSetIndex].wallSetURL,
                          geometry: data.walls[wallIndex].wallSets[wallSetIndex].wallGeometry,
                          material: data.walls[wallIndex].wallSets[wallSetIndex].wallMaterial })

}