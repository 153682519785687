import { useQuery } from '@tanstack/react-query'
import { axiosAPI } from '../axiosAPI.js'

const useQueryUserProfile = () => {
    const { isLoading, error, data } = useQuery({
        queryKey: ['profile-info'],
        queryFn: async () => {
            const response = await axiosAPI.get('user-profile')

            return response.data
        },
        enabled: true
    })

   return { isLoading, error, data}
}

export default useQueryUserProfile;