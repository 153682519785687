
export const arraySort = (objectToSort, fieldName, order) => {
    const sortedObject = objectToSort.sort((a, b) => {
        let textA = a[fieldName].toUpperCase();
        let textB = b[fieldName].toUpperCase();
        
        if (order == "ASC") {
            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        } else {
            return (textA < textB) ? 1 : (textA > textB) ? -1 : 0;

        }
    }); 
    
    return sortedObject
}