import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'
import { axiosAPI } from '../axiosAPI.js'
import { removeHeaderToken } from '../axiosAPI.js'


const useMutateLogout = () => {
    const queryClient = useQueryClient()
    const navigate = useNavigate()

    // send to backend API
    const mutation = useMutation({
      mutationFn: async () => {
        await axiosAPI.get('logout/')

        sessionStorage.clear()
        removeHeaderToken()
        return queryClient.removeQueries()
      },
      onSuccess: () => {
          return navigate('/')
      }
  })


  const onSubmitLogout = (e, form) => {
      e.preventDefault()

      mutation.mutate()
  }

  return { onSubmitLogout }

}

export default useMutateLogout;