import * as React from "react"
import {
  Card,
  CardDescription,
  CardContent,
  CardHeader,
  CardTitle,
} from "../../../components/ui/card"

export function CardInfoIcon({heading, subtext, icon}) {
  return (
    <Card className="flex flex-row justify-start pt-6 my-4 max-w-2xl mx-auto">
      <CardContent className="p-1 w-24">
        {icon}
      </CardContent>
      <CardContent>
        <CardTitle>{heading}</CardTitle>
        <CardDescription>{subtext}</CardDescription>
      </CardContent>
    </Card>
  )
}
