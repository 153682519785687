import { useState } from 'react'
import useQueryUserProfile from '../api/Site/useQueryUserProfile.js'
import { H4 } from '../blocks/components/typography/H4.js'
import { SubHeading } from '../blocks/components/typography/SubHeading.js'
import { ButtonLink } from '../blocks/components/buttons/ButtonLink.js'
import { Label } from '../components/ui/label.jsx'
import { Input } from '../components/ui/input.jsx'
import '../tailwind.css'

const Profile = () => {
    const { data, isLoading, error } = useQueryUserProfile()
    const [ alertModalVis, setAlertModalVis ] = useState(false)
    const [ alertMsg, setAlertMsg ] = useState(null)
    const [ upgradeVis, setUpgradeVis ] = useState(false)
    const [ accountDelVis, setAccountDelVis ] = useState(false)

    //USE SHADCN ALERT INSTEAD FOR USEALERT
    if (isLoading) return 'Loading...'

    if (error) return 'An error has occurred: ' + error.message 
    
    return (
        <div className="h-screen">
            <div className="container max-w-full mt-8">
                <h1 className="scroll-m-20 text-4xl font-extrabold text-center mb-4 tracking-tight lg:text-5xl">
                    Profile
                </h1>
                <main className="flex justify-center">                
                    <div className="w-80">
                    <fieldset className="grid gap-6 rounded-lg border p-4">
                        <legend className="-ml-1 px-1 text-sm font-medium">
                            Information
                        </legend>                    
                        <div>
                        <Label>First Name</Label>
                        <Input id="firstName" type="text" value={data.firstName} disabled />
                        </div>
                        <div>
                        <Label>Last Name</Label>
                        <Input id="lastName" type="text" value={data.lastName} disabled />
                        </div>
                        <div>
                        <Label>Username</Label>
                        <Input id="username" type="text" value={data.username} disabled />
                        </div>
                        <div>
                        <Label>Email</Label>
                        <Input id="email" type="email" value={data.email} disabled />
                        </div>
                    </fieldset>  
                    <fieldset className="grid gap-6 rounded-lg border p-4">
                        <legend className="-ml-1 px-1 text-sm font-medium">
                            Feedback
                        </legend>

                        <div className="text-center">
                            <H4 text="Any suggestions?" />
                            <SubHeading text={"We would absolutely love to hear from you!"} color="mb-4" />                        
                            <ButtonLink link={"/contact-us"} buttonName={"Send Feedback"} />                        
                        </div>
                    </fieldset>              
                    </div>
                    
                </main>
            </div>
        </div>    
    )
}

export default Profile