import { useState } from 'react'
import UseMutateSendClimbFeedback from '../../api/Gym/UseMutateSendClimbFeedback.js'
import { Label } from '../../components/ui/label.jsx'
import { Button } from '../../components/ui/button.jsx'
import { Textarea } from '../../components/ui/textarea.jsx'
import {
    Card,
    CardContent,
    CardDescription,
    CardHeader,
    CardTitle,
  } from "../../components/ui/card.jsx"

const UseModalClimbFeedback = ({modalVis, setModalVis, climbName, climbGrade, climbID, setNotification, setMessage, ratingSystem, setWallName }) => {
    const [ form, setForm ] = useState({level: "", notes: "", color: "#ffffff"})
    
    if (modalVis) {
        return (
            <div className="fixed top-1 left-0 h-full w-full z-20 bg-black/10">
                <Card className="mt-12 mx-auto w-[18rem] h-[28rem] box-border rounded-lg p-1 overflow-auto">
                    <CardHeader>
                    <CardTitle>Feedback on Grade</CardTitle>
                    <CardDescription>{climbGrade} - {climbName}</CardDescription>
                    </CardHeader>
                    <CardContent>                    
                        <div className="flex flex-col flex-wrap justify-start">
                            { ratingSystem.length > 0 ?
                                ratingSystem.map((item) => {
                                    const highlightBorder = item.rating == form.level ? "0.1rem solid black" : "none"
                                    return <Button onClick={() => {setForm(data => ({...data, level: item.rating, color: item.color}))}}
                                                   style={{ backgroundColor: item.color, border: highlightBorder }}
                                                   className="mt-2"
                                                   size="sm">
                                                    {item.rating}
                                        </Button>
                                })
                            :
                            <>
                                <Button onClick={() => {setForm(data => ({...data, level: "Very Hard"}))}}
                                        size="sm"
                                        className="mt-2"
                                        style={{ backgroundColor: "#dc2626" }}>
                                            Very Hard
                                </Button>
                                <Button onClick={() => {setForm(data => ({...data, level: "Hard"}))}}
                                        size="sm"
                                        className="mt-2"
                                        style={{ backgroundColor: "#f97316" }}>
                                            Hard
                                </Button>
                                <Button onClick={() => {setForm(data => ({...data, level: "Perfect"}))}}
                                        size="sm"
                                        className="mt-2"
                                        style={{ backgroundColor: "#facc15" }}>
                                            Perfect
                                </Button>
                                <Button onClick={() => {setForm(data => ({...data, level: "Easy"}))}}
                                        size="sm"   
                                        className="mt-2"
                                        style={{ backgroundColor: "#22c55e" }}>
                                            Easy
                                </Button>
                                <Button onClick={() => {setForm(data => ({...data, level: "Very Easy"}))}}
                                        size="sm"      
                                        className="mt-2"
                                        style={{ backgroundColor: "#2563eb" }}>
                                            Very Easy
                                </Button>
                            </>                   
                            }                                                 
                        </div>
                        <Label>Message</Label>
                        <Textarea 
                            rows={5}  
                            name="message" 
                            placeholder="(Optional) Enter your feedback on the climb."
                            value={form.notes} 
                            onChange={(e) => setForm(data => ({...data, notes: e.target.value}))} />
                        <UseMutateSendClimbFeedback form={form} setModalVis={setModalVis} climbID={climbID} setNotification={setNotification} setMessage={setMessage} setWallName={setWallName} />
                    </CardContent>                    
                </Card>
            </div>
        )
    } else {
        return null
    }
}

export default UseModalClimbFeedback;