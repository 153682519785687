export const checkUserGroup = (collabArray, uid) => {
    for (var i = 0; i < collabArray.length; i++) {
        if (collabArray[i].userID == uid) {
            return true
        }
    }

    return false
}

export const checkGroupAssociation = (collaborators, followers, id) => {
    for (var i = 0; i < collaborators.length; i++) {
        if (collaborators[i].userID == id && collaborators[i].status !== "pending") {
            return "collaborator"
        } else if (collaborators[i].userID == id && collaborators[i].status == "pending") {
            return "Pending..."
        }
    }

    for (var i = 0; i < followers.length; i++) {
        if (followers[i].userID == id && followers[i].status !== "pending") {
            return "follower"
        } else if (followers[i].userID == id && followers[i].status == "pending") {
            return "Pending..."
        }
    }

    return "Saved"
}
