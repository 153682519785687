import { useState, useEffect } from 'react'
import { BookCheck, BookUp, LockKeyhole } from "lucide-react"
import { useQueryClient } from '@tanstack/react-query'
import { HexColorPicker } from 'react-colorful'
import UseMutateUpdateGym from '../../api/Gym/UseMutateUpdateGym.js'
import { Button } from '../../components/ui/button'
import {
    Card,
    CardContent,
    CardHeader,
    CardTitle,
  } from "../../components/ui/card.jsx"
  import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectTrigger,
    SelectValue,
  } from "../../components/ui/select"  
import { Input } from "../../components/ui/input.jsx"
import { Label } from "../../components/ui/label.jsx"
import { Badge } from "../../components/ui/badge.jsx"
import { daysOfWeek } from '../../helpers/daysOfOurLives.js'

const ModalChangeGymIntro = ({modalVis, setModalVis, getWallOptions}) => {
    const queryClient = useQueryClient()
    const gym = queryClient.getQueryData(['gym'])

    const [ wallOptions, setWallOptions ] = useState([])
    const [ gymInfo, setGymInfo ] = useState(gym ? {openHours: [...gym.openHours], location: {...gym.location}, gradingType: gym.gradingType, 
                                                    gradingSystem: [...gym.gradingSystem], nextWallToSet: gym.nextWallToSet, 
                                                    newWallSet: gym.newWallSet, ratingSystem: [...gym.ratingSystem], privacy: gym.privacy } : {})
    
    const handleUpdateTimeslot = (_idx, value) => {
        const updatedOpenHours = gymInfo.openHours.map((day, dayIdx) => {
            if (dayIdx == _idx) {
                return value
            }

            return day
        })

        setGymInfo({...gymInfo, ["openHours"]: updatedOpenHours})
    }

    const handleUpdateLocationField = (name, value) => {
        setGymInfo({...gymInfo, location: { ...gymInfo.location, [name]: value}})
    }

    const handleUpdateNextWall = (value) => {
        setGymInfo({...gymInfo, ["nextWallToSet"]: value})
    }

    const handleUpdateNewWall = (wallID) => {
        const wallIdx = wallOptions.findIndex((item) => item.wallID == wallID)
        const updatedWallSet = { name: wallOptions[wallIdx].name, id: wallOptions[wallIdx].wallID}
        setGymInfo({...gymInfo, ["newWallSet"]: updatedWallSet})
    }

    const handleUpdateGrade = (gradeIdx, value) => {
        const updatedGrades = gymInfo.gradingSystem.map((interval, idx) => {
            if (idx == gradeIdx) {
                return { ...interval, ["grade"]: value }
            }
            return interval
        })

        setGymInfo({...gymInfo, ["gradingSystem"]: updatedGrades})
    }

    const handleUpdateRating = (ratingIdx, value) => {
        const updatedGrades = gymInfo.ratingSystem.map((interval, idx) => {
            if (idx == ratingIdx) {
                return { ...interval, ["rating"]: value }
            }
            return interval
        })

        setGymInfo({...gymInfo, ["ratingSystem"]: updatedGrades})
    }

    const handleUpdateColor = (mode, dataIdx, color) => {
        const updatedGrades = gymInfo[mode].map((interval, idx) => {
            if (idx == dataIdx) {
                return { ...interval, ["color"]: color }
            }
            return interval
        })

        setGymInfo({...gymInfo, [mode]: updatedGrades})
    }

    const handleUpdateGradeType = (value) => {
        setGymInfo({...gymInfo, ["gradingType"]: value})
    }

    const pushNewToArray = (mode, secondaryField) => {
        console.log(mode, secondaryField)
        let updatedArray = [...gymInfo[mode]]
        updatedArray.push({[secondaryField]: "", color: "#ffffff"})

        setGymInfo({...gymInfo, [mode]: updatedArray})
    }

    const popFromArray = (mode, arrayIdx) => {
        console.log('fucking bullshit: ', arrayIdx)
        const filteredArray = gymInfo[mode].filter((item, idx) => idx !== arrayIdx)
        setGymInfo({...gymInfo, [mode]: filteredArray})
    }

    useEffect(() => {
        setWallOptions(getWallOptions()) 
    }, [])

    if (modalVis) {
        return (            
            <div className="fixed top-0 h-full w-full z-20 bg-black/10">
                <Card className="mt-12 mx-auto w-[18rem] h-[35rem] box-border rounded-lg p-1 overflow-auto">
                    <CardHeader className="flex flex-row pb-2">
                        <CardTitle className="w-28">Edit Gym Info</CardTitle>

                        <Button 
                            variant="destructive" 
                            onClick={() => setModalVis(false)}
                            className="h-8 w-16 mt-0">Cancel</Button>   
                    </CardHeader>
                    <CardContent>
                        <div className="pt-4">
                            <Label className="text-md font-bold">Open Times</Label>
                            <CardContent className="p-2 pt-0">
                            { gym.openHours.map((timeSlot, idx) => {
                                return (
                                    <div className="flex flex-row justify-start items-center my-2">
                                        <Badge variant="secondary" className="w-16 h-6 font-semibold justify-center mr-2">
                                            {daysOfWeek[idx]}
                                        </Badge>                                        
                                        <Input 
                                            type="text" 
                                            placeholder="Enter your open hours" 
                                            required 
                                            name="openHours"
                                            className="text-xs" 
                                            value={gymInfo.openHours[idx]} 
                                            onChange={(e) => handleUpdateTimeslot(idx, e.target.value)} />
                                    </div>
                                )
                            })}
                            </CardContent>
                        </div>
                        <div>
                            <Label className="text-md">Location</Label>
                            <CardContent className="p-2 pt-0">
                                    <Label className="text-xs font-semibold">Address</Label>
                                    <Input 
                                        type="text" 
                                        className="text-xs" 
                                        name="address"
                                        placeholder="Enter your address" 
                                        value={gymInfo.location.address} 
                                        onChange={(e) => handleUpdateLocationField(e.target.name, e.target.value)} />
                                    <Label className="text-xs font-semibold">City</Label>
                                    <Input 
                                        type="text" 
                                        className="text-xs" 
                                        name="city"
                                        placeholder="Enter your city" 
                                        value={gymInfo.location.city} 
                                        onChange={(e) => handleUpdateLocationField(e.target.name, e.target.value)} />
                                    <Label className="text-xs font-semibold">Province</Label>
                                    <Input 
                                        type="text" 
                                        className="text-xs" 
                                        name="province"
                                        placeholder="Enter your province" 
                                        value={gymInfo.location.province} 
                                        onChange={(e) => handleUpdateLocationField(e.target.name, e.target.value)} />    
                                    <Label className="text-xs font-semibold">Postal Code</Label>
                                    <Input 
                                        type="text" 
                                        className="text-xs" 
                                        name="postalcode"
                                        placeholder="Enter your postal code" 
                                        value={gymInfo.location.postalCode} 
                                        onChange={(e) => handleUpdateLocationField(e.target.name, e.target.value)} />                                
                            </CardContent>
                        </div>
                        <div>
                            <Label className="text-md">Grading</Label>

                            <CardContent className="p-2 pt-0">
                                <Label 
                                    htmlFor="gradeType"
                                    className="text-xs font-semibold pb-1">Type</Label>
                                <Select
                                    onValueChange={(value) => {handleUpdateGradeType(value)}}
                                    name="gradeType"
                                    value={gymInfo.gradingType} >
                                    <SelectTrigger className="w-[180px]">
                                        <SelectValue placeholder="Select a gym grade type" />
                                    </SelectTrigger>
                                    <SelectContent>
                                        <SelectGroup>
                                            <SelectItem value="color">Color</SelectItem>
                                            <SelectItem value="text">Text</SelectItem>
                                        </SelectGroup>
                                    </SelectContent>
                                </Select>  

                                <Label 
                                    htmlFor="gradeSystem"
                                    className="text-xs font-semibold py-2">System</Label> 
                                { gymInfo.gradingType == "color" ?
                                    gymInfo.gradingSystem.map((grade, idx) => {
                                        return (
                                            <CardContent className="flex flex-col p-2 pt-0" key={grade.grade+grade.color}>
                                                <Label className="text-xs font-semibold">Grade {idx + 1}</Label>
                                                <div className="flex flex-row">
                                                    <div className="mr-2">
                                                        <Input 
                                                            type="text" 
                                                            className="text-xs w-16" 
                                                            name="gradingSystem"
                                                            placeholder="Enter a grade" 
                                                            value={gymInfo.gradingSystem[idx].grade} 
                                                            onChange={(e) => handleUpdateGrade(idx, e.target.value)} />
                                                        <Button 
                                                            variant="destructive" 
                                                            className="w-16 h-8 mt-2"
                                                            onClick={(e) => {popFromArray("gradingSystem", idx)}}>Remove</Button>
                                                    </div>
                                                    <div className="grading-color">
                                                        <HexColorPicker 
                                                            color={gymInfo.gradingSystem[idx].color} 
                                                            onChange={(color) => handleUpdateColor("gradingSystem", idx, color)} />
                                                    </div>
                                                </div>                                        
                                            </CardContent>
                                        )
                                    })
                                :
                                    gymInfo.gradingType == "text" ?
                                        <CardContent className="flex flex-row flex-wrap justify-start p-1 pt-0">
                                            {gymInfo.gradingSystem.map((grade, idx) => {
                                                return (
                                                    <div>
                                                        <Label className="text-xs font-semibold">Grade {idx + 1}</Label>
                                                        <div className="flex flex-row ">
                                                            <div className="mr-2">
                                                                <Input 
                                                                    type="text" 
                                                                    className="text-xs w-14" 
                                                                    name="gradingSystem"
                                                                    placeholder="Enter a grade" 
                                                                    value={gymInfo.gradingSystem[idx].grade} 
                                                                    onChange={(e) => handleUpdateGrade(idx, e.target.value)} />
                                                                <Button 
                                                                    variant="destructive" 
                                                                    className="w-14 h-8 mt-2"
                                                                    onClick={(e) => {popFromArray("gradingSystem", idx)}}>Delete</Button>
                                                            </div>
                                                        </div>  
                                                    </div>
                                                )
                                            })}
                                        </CardContent>                                        
                                    :
                                        null
                                }
                                { gymInfo.gradingSystem.length < 17 ? 
                                    <Button 
                                        onClick={(e) => {pushNewToArray("gradingSystem", "grade")}} 
                                        className="mt-4">
                                        + Grade
                                    </Button>
                                :
                                    null
                                }
                            </CardContent>
                        </div>                        
                        <div>
                            <Label className="text-md">Feedback</Label>

                            <CardContent className="p-2 pt-0">                            
                                <Label 
                                    htmlFor="gradeSystem"
                                    className="text-xs font-semibold py-2">System</Label> 
                                {   gymInfo.ratingSystem.length > 0 ? 
                                        gymInfo.ratingSystem.map((rating, idx) => {
                                            return (
                                                <CardContent className="flex flex-col p-2 pt-0">
                                                    <Label className="text-xs font-semibold">Rating {idx + 1}</Label>
                                                    <div className="flex flex-row">
                                                        <div className="mr-2">
                                                            <Input 
                                                                type="text" 
                                                                className="text-xs w-32" 
                                                                name="ratingSystem"
                                                                placeholder="Enter a rating" 
                                                                value={gymInfo.ratingSystem[idx].rating} 
                                                                onChange={(e) => handleUpdateRating(idx, e.target.value)} />
                                                            <Button 
                                                                variant="destructive" 
                                                                className="w-32 h-8 mt-2"
                                                                onClick={(e) => {popFromArray("ratingSystem", idx)}}>Remove</Button>
                                                        </div>
                                                        <div className="feedback-color">
                                                            <HexColorPicker 
                                                                color={gymInfo.ratingSystem[idx].color} 
                                                                onChange={(color) => handleUpdateColor("ratingSystem", idx, color)} />
                                                        </div>
                                                    </div>                                        
                                                </CardContent>
                                            )
                                        })
                                    :
                                        null
                                }
                                { gymInfo.ratingSystem.length < 5 ? 
                                    <Button 
                                        onClick={(e) => {pushNewToArray("ratingSystem", "rating")}} 
                                        className="mt-4">
                                        + Rating
                                    </Button>
                                :
                                    null
                                }                                    
                            </CardContent>
                        </div>  
                        <div>
                            <Label className="text-md">Most Recently Set Wall</Label>
                            <CardContent className="p-2 pt-0 flex flex-row justify-start items-center">
                                <BookCheck />
                                <Select
                                    onValueChange={(value) => {handleUpdateNewWall(value)}}
                                    name="wall"
                                    value={gymInfo.newWallSet.id} >
                                    <SelectTrigger className="w-[180px]">
                                        <SelectValue placeholder="Select the most recent updated wall" />
                                    </SelectTrigger>
                                    <SelectContent>
                                        <SelectGroup>
                                            {wallOptions.length > 0 && wallOptions.map((item, i) => {
                                                return <SelectItem key={item.name + '-wallSel-'+i} value={item.wallID}>{item.name}</SelectItem>
                                            })}
                                        </SelectGroup>
                                    </SelectContent>
                                </Select>  
                            </CardContent>
                        </div>
                        <div>
                            <Label className="text-md">Next Wall To Update</Label>
                            <CardContent className="p-2 pt-0 flex flex-row justify-start items-center">
                                <BookUp />
                                <Select
                                    onValueChange={(value) => {handleUpdateNextWall(value)}}
                                    name="wallSet"
                                    value={gymInfo.nextWallToSet} >
                                    <SelectTrigger className="w-[180px]">
                                        <SelectValue placeholder="Select the most recent updated wall" />
                                    </SelectTrigger>
                                    <SelectContent>
                                        <SelectGroup>
                                            {wallOptions.length > 0 && wallOptions.map((item, i) => {
                                                return <SelectItem key={item.name + '-wallSetSel-'+i} value={item.name}>{item.name}</SelectItem>
                                            })}
                                        </SelectGroup>
                                    </SelectContent>
                                </Select> 
                            </CardContent>
                        </div>
                        <div>
                            <Label className="text-md">Privacy</Label>
                            <CardContent className="p-2 pt-0 flex flex-row justify-start items-center">
                                <LockKeyhole />
                                <Select
                                    onValueChange={(value) => {handleUpdateNextWall(value)}}
                                    name="wallSet"
                                    value={gymInfo.privacy} >
                                    <SelectTrigger className="w-[180px]">
                                        <SelectValue placeholder="Select a privacy option" />
                                    </SelectTrigger>
                                    <SelectContent>
                                        <SelectGroup>
                                            <SelectItem value="public">Public</SelectItem>
                                            <SelectItem value="private">Private</SelectItem>
                                        </SelectGroup>
                                    </SelectContent>
                                </Select> 
                            </CardContent>
                        </div>
                    </CardContent>
                    <UseMutateUpdateGym gymInfo={gymInfo} setAlert={setModalVis} />
                </Card>
            </div>
        )
    } else {
        return <></>
    }
}

export default ModalChangeGymIntro;