export const handleOpenHoursUpdate = (form, setForm, dayIdx, value) => {
    const updatedOpenHours = form.openHours.map((hoursOnDay, idx) => {
        if (idx == dayIdx) {
            return value
        } else return hoursOnDay
    })

    setForm({...form, openHours: updatedOpenHours})
}

export const pushNewToArray = (form, setForm, mode, secondaryField) => {
    let updatedArray = [...form[mode]]
    updatedArray.push({[secondaryField]: "", color: "#ffffff"})

    setForm({...form, [mode]: updatedArray})
}

export const popFromArray = (form, setForm, mode, arrayIdx) => {
    const filteredArray = form[mode].filter((item, idx) => idx !== arrayIdx)
    setForm({...form, [mode]: filteredArray})
}

export const handleUpdateGrade = (form, setForm, gradeIdx, value) => {
    const updatedGrades = form.gradingSystem.map((interval, idx) => {
        if (idx == gradeIdx) {
            return { ...interval, ["grade"]: value }
        }
        return interval
    })

    setForm({...form, ["gradingSystem"]: updatedGrades})
}

export const handleUpdateRating = (form, setForm, ratingIdx, value) => {
    const updatedGrades = form.ratingSystem.map((interval, idx) => {
        if (idx == ratingIdx) {
            return { ...interval, ["rating"]: value }
        }
        return interval
    })

    setForm({...form, ["ratingSystem"]: updatedGrades})
}

export const handleUpdateColor = (form, setForm, mode, dataIdx, color) => {
    const updatedGrades = form[mode].map((interval, idx) => {
        if (idx == dataIdx) {
            return { ...interval, ["color"]: color }
        }
        return interval
    })

    setForm({...form, [mode]: updatedGrades})
}

export const addNewWall = (form, setForm) => {
    const updatedWalls = [...form.walls, {
        wallName: '', wallSets:[{
            date: Date(),
            climbs: [],
            wallSetURL: '',
            wallGeometry: '',
            wallMaterial: ''
        }]
    }]

    setForm({...form, walls: updatedWalls})
}

export const addNewWallSet = (form, setForm, wallIdx) => {
    const updatedWallSets = [...form.walls[wallIdx].wallSets, {
        date: Date(),
        climbs: [],
        wallSetURL: '',
        wallGeometry: '',
        wallMaterial: ''
    }]
    const updatedWalls = form.walls.map((wall, wIdx) => {
        if (wIdx == wallIdx) {
            let updatedWall = {...wall}
            updatedWall.wallSets = updatedWallSets
            return updatedWall
        }
        return wall
    })

    setForm({...form, walls: updatedWalls})
}

export const removeNewWall = (form, setForm, wallIdx) => {
    const updatedWalls = form.walls.filter((wall, idx) => idx !== wallIdx)    
    setForm({...form, walls: updatedWalls})
}

export const removeNewWallSet = (form, setForm, wallIdx, wallSetIdx) => {
    const updatedWallSets = form.walls[wallIdx].wallSets.filter((wallSet, idx) => idx !== wallSetIdx)
    const updatedWalls = form.walls.map((wall, wIdx) => {
        if (wIdx == wallIdx) {
            let updatedWall = wall
            updatedWall.wallSets = updatedWallSets
            return updatedWall
        }
        return wall
    })

    setForm({...form, walls: updatedWalls})
}

export const handleChangeWallName = (form, setForm, wallIdx, value) => {
    const updatedWalls = form.walls.map((wall, idx) => {
        if (idx == wallIdx) {
            let updatedWall = {...wall}
            updatedWall.wallName = value

            return updatedWall
        }

        return wall
    })

    setForm({...form, walls: updatedWalls})
}

export const handleWallSetField = (form, setForm, wallIdx, wallSetIdx, wallSetField, wallSetValue) => {
    const updatedWallSet = {...form.walls[wallIdx].wallSets[wallSetIdx], [wallSetField]: wallSetValue}
    const updatedWallSets = form.walls[wallIdx].wallSets.map((wallSet, wsIdx) => {
        if (wsIdx == wallSetIdx){
            return updatedWallSet
        }
        return wallSet
    })

    const updatedWalls = form.walls.map((wall, wIdx) => {
        if (wIdx == wallIdx) {
            let updatedWall = {...wall}
            updatedWall.wallSets = updatedWallSets

            return updatedWall
        }
        return wall
    })

    setForm({...form, walls: updatedWalls})
}

export const handleUpdateAuthor = (data, form, setForm, authorID) => {
    const authorIndex = data.findIndex((user) => user.userID == authorID)
    setForm({...form, authorID: data[authorIndex].userID, authorName: data[authorIndex].username})
}

export const handleUpdateCollaborator = (data, form, setForm, setAlertMessages, setAlertWarning, setCurrentCollaborator, collabID) => {
    setAlertMessages([])
    const collabIndex = data.findIndex((user) => user.userID == collabID)

    const isUserInList = form.collaborators.findIndex((user) => user.userID == collabID)
    if (isUserInList == -1) {
        const updatedCollaborators = [...form.collaborators, {username: data[collabIndex].username, userID: data[collabIndex].userID}]

        setForm({...form, collaborators: updatedCollaborators})
        setCurrentCollaborator(collabID)
    } else {
        setAlertMessages(["User is already in the collaborator list!"])
        setAlertWarning(true)
    }
}

export const handleRemoveCollaborator = (form, setForm, setAlertMessages, setAlertWarning, setCurrentCollaborator, collabID) => {
    setAlertMessages([])

    const isUserInList = form.collaborators.findIndex((user) => user.userID == collabID)
    if (isUserInList == -1) {
        setCurrentCollaborator(null)
        setAlertMessages(["User is not in the collaborator list!"])
        setAlertWarning(true)    
    } else {
        setCurrentCollaborator(null)
        const updatedCollaborators = form.collaborators.filter((user) => user.userID !== collabID)
        setForm({...form, collaborators: updatedCollaborators})
    }
}