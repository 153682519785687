import { useMutation } from '@tanstack/react-query'
import { axiosAPI } from '../axiosAPI.js'

const useForgotPassword = (form, setNotification, setMessage, setIsLoading) => {
    const mutation = useMutation({
        mutationFn:(form) => {
            setIsLoading(true)
        
            return axiosAPI.post('forgot-password', form)
        },
        onSuccess: (data) => {
            setIsLoading(false)

            for (const [key, value] of Object.entries(data.data)) {
                setMessage(messages => ([...messages, value]))
            }   
            
            return setNotification(true)
        },
        onError: (error) => {
            setIsLoading(false)

            setMessage([])
            for (const [key, value] of Object.entries(error.response.data)) {
                setMessage(errorValues => ([...errorValues, value]))
            }                

            return setNotification(true)
        }
    })

    const onSubmitForgotPassword = (e) => {
        e.preventDefault()

        mutation.mutate(form)
    }

    return { onSubmitForgotPassword }
}

export default useForgotPassword;