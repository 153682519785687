import * as React from "react"
import {
  Card,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../../../components/ui/card"


export function CardInfoLink({heading, subtext, linkButton}) {
  return (
    <Card className="w-[12rem] mt-2 mx-4 flex-col justify-between">
      <CardHeader>
        <CardTitle>{heading}</CardTitle>
        <CardDescription>{subtext}</CardDescription>
      </CardHeader>
      <CardFooter className="mt-auto">
        {linkButton}
      </CardFooter>
    </Card>
  )
}
