import {
    DropdownMenu,
    DropdownMenuCheckboxItem,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
  } from "../../../components/ui/dropdown-menu"
import { ListFilter } from "lucide-react"
import { Button } from "../../../components/ui/button"

export function GradeFilter({gradeRangeList, handleGradeRangeChange, refetchOnConfirm}) {
    return (
        <DropdownMenu>
            <DropdownMenuTrigger asChild>
                <Button size="sm" className="h-8 gap-1" variant="outline ">
                <ListFilter className="h-3.5 w-3.5" />
                <span className="sr-only sm:not-sr-only sm:whitespace-nowrap">
                    Grade Filter
                </span>
                </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
                <DropdownMenuLabel>Filter by</DropdownMenuLabel>
                <DropdownMenuSeparator />            
                { gradeRangeList && gradeRangeList.length > 0 ?
                    gradeRangeList.map((grade) => {
                        return  <DropdownMenuCheckboxItem 
                                    onClick={(e) => {e.preventDefault(); handleGradeRangeChange(grade.grade, !grade.filter)}} 
                                    checked={grade.filter}>
                                    {grade.grade}
                                </DropdownMenuCheckboxItem>
                        })
                :
                    null
                }                            
                <DropdownMenuItem>
                    <Button 
                        size="lg" 
                        className="h-8 gap-1 w-full"
                        onClick={() => { refetchOnConfirm()}}>
                        <span>
                            Confirm
                        </span>
                    </Button>
                </DropdownMenuItem> 
            </DropdownMenuContent>
        </DropdownMenu>
    )
}