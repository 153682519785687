import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useGymStore } from '../../Beta/data/useGymStore.js'
import { Button } from '../../components/ui/button'
import { axiosAPI } from '../axiosAPI.js'

const UseMutateMakeMainGymWallSet = ({wallID, wallSetID, setMessage, setNotification}) => {
    const queryClient = useQueryClient()
    const setCurrentWallSetID = useGymStore((state) => state.setCurrentWallSetID)    

    // send to backend API
    const mutation = useMutation({
        mutationFn: async () => {
            const gymID = queryClient.getQueryData(['gym']).id

            const response = await axiosAPI.put('update-gym-main-wallset/' + gymID, {wallID: wallID, wallSetID: wallSetID})
            return response.data
        }, 
        onSuccess: (data) => {
            setCurrentWallSetID(data.walls[0].currentWallSetID)

            queryClient.setQueryData(['gym'], data)
            setMessage(["Wall set pinned!"])
            
            return setNotification(true)
        }
    })

    const onSubmitChangeWallset = (e) => {
        e.preventDefault()

        mutation.mutate()
    }

    return (
        <div>
            {mutation.isLoading ? (
            'Changing climb set...'
            ) : (
            <>
                <div className="modal-alert-buttonbox">
                    <Button onClick={(e) => onSubmitChangeWallset(e)}>Change Climb Set?</Button>
                </div>

                {mutation.isError ? (
                    <>
                        <p className="forgot-password-error">{mutation.error.response.data.authorization}</p>
                    </>
                ) : null}
            </>
            )}
        </div>
    )
}

export default UseMutateMakeMainGymWallSet;