
import { Button } from '../../../components/ui/button'
import { Filter } from "lucide-react"
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuGroup,
    DropdownMenuLabel,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
  } from "../../../components/ui/dropdown-menu"

export function DropdownFilter({options, resetClimbs}) {
    return (
        <DropdownMenu>
            <DropdownMenuTrigger asChild>
                <Button size="sm" className="w-9 p-0">
                    <Filter />
                    <span className="sr-only">Toggle</span>
                </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent style={{marginLeft: "2rem"}}>
                <DropdownMenuLabel>Grades</DropdownMenuLabel>
                <DropdownMenuSeparator />
                <DropdownMenuGroup className="flex flex-col px-2 pt-2">
                    { options.map((item) => {return item})} 
                    
                    <Button 
                    size="sm" 
                    variant="destructive"
                    className="my-2"
                    onClick={() => resetClimbs()} >Reset Filter</Button>           
                </DropdownMenuGroup>
            </DropdownMenuContent>
        </DropdownMenu>        
    )
}
