import { useMutation } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'
import { axiosAPI, setHeaderToken } from '../axiosAPI.js'
import { passwordCheck } from '../../helpers/passwordCheck.js'

const useSignup = (form, setNotification, setMessage) => {
    const navigate = useNavigate()

    // send to backend API
    const mutation = useMutation({
      mutationFn: async () => {
        const response = await axiosAPI.post('signup', form)

        return response.data
      },
      onSuccess: (data) => {
        const accessToken = data.accessToken
        
        sessionStorage.setItem("userId", data.userId)
        sessionStorage.setItem("firstName", data.firstName)
        sessionStorage.setItem("lastName", data.lastName)
        sessionStorage.setItem("email", data.email)
        
        setHeaderToken(accessToken)

        return navigate('/dashboard')      
      },
      onError: (error) => {
        setMessage([])
        for (const [key, value] of Object.entries(error.response.data)) {
            setMessage(errorValues => ([...errorValues, value]))
        }

        return setNotification(true)
      }
  })

  const onSubmitSignup = (e) => {
    e.preventDefault()

    setMessage([])

    if (form.username.includes(" ")) {
      setMessage(["Username may not contain any spaces"])
      return setNotification(true)
    }

    if (form.username.length < 4 || form.username.length > 16) {
        setMessage(["Username must be between 4 and 16 characters"])
        return setNotification(true)
    }

    const allSpecials = /[*|\":<>[\]{}`\\()'.-;@&$]/;

    if (allSpecials.test(form.firstName)) { 
        setMessage(["Your first name may not contain any special characters"])
        return setNotification(true)
    }

    if (allSpecials.test(form.lastName)) { 
        setMessage(["Your last name may not contain any special characters"])
        return setNotification(true)
    }

    if (allSpecials.test(form.username)) { 
        setMessage(["Username may not contain any special characters"])
        return setNotification(true)
    }

    const isPasswordValid = passwordCheck(form.password, form.password2)
    if (isPasswordValid.valid) {
        mutation.mutate(form)
    } else {
        setMessage(isPasswordValid.errorAlerts)
        return setNotification(true)
    }

    mutation.mutate()
  }

  return { onSubmitSignup }

}

export default useSignup;