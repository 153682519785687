import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { MoreHorizontal } from "lucide-react"
import Loading from '../Site/Loading.js'
import PageIsPrivate from './PageIsPrivate.js'
import PageNotFound from '../Site/PageNotFound.js'
import { ButtonLink } from '../blocks/components/buttons/ButtonLink'
import { Button } from "../components/ui/button.jsx"
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../components/ui/card.jsx"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuCheckboxItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
  DropdownMenuSeparator
} from "../components/ui/dropdown-menu.jsx"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../components/ui/table.jsx"
import {
    Pagination,
    PaginationContent,
    PaginationItem,
    PaginationLink,
    PaginationNext,
    PaginationPrevious,
  } from "../components/ui/pagination.jsx"
  import {
    Popover,
    PopoverContent,
    PopoverTrigger,
  } from "../components/ui/popover.jsx"
import { ScrollArea, ScrollBar } from "../components/ui/scroll-area.jsx"
import useQueryAllGyms from '../api/Gym/useQueryAllGyms.js'
import {AddWallSet} from '../blocks/components/data/AddWallSet.js'

const AdminGyms = () => {
    const [ pageNum, setPageNum ] = useState(0)
    const [ itemsPerPage, setItemsPerPage ] = useState(10)
    const [ wallFilterList, setWallFilterList ] = useState(null)
    const [ gradeRanges, setGradeRanges ] = useState(null)
    const [ omitBroken, setOmitBroken ] = useState(true)
    const [ sortObj, setSortObj ] = useState({needsSorting: false, fieldName: ""})
    const [ wallSetModal, setWallSetModal ] = useState(false)
    const [ gymData, setGymData ] = useState(null)
    const [ gymId, setGymId ] = useState('')
    const [ gymIdx, setGymIdx ] = useState(null)
    const [ wallId, setWallId ] = useState('')
    const [ newWallSet, setNewWallSet ] = useState(null)

    const { data, isLoading, error, refetch } = useQueryAllGyms()

    const addPage = () => {
        if (pageNum < Math.ceil(data.feedbackLength / itemsPerPage) - 1) {
            setPageNum(pageNum + 1)
        }
    }

    const removePage = () => {
        if (pageNum > 0) {
            setPageNum(pageNum - 1)
        }
    }

    const handlePageChange = (value) => {
        setPageNum(value)
    }

    const chooseItemsPerPage = (value) => {
        setPageNum(0)
        setItemsPerPage(value)
    }

    const handleSorting = (field) => {
        const sortingOrder = sortObj.needsSorting == false ? "ASC" : sortObj.needsSorting == "ASC" ? "DES" : sortObj.needsSorting == "DES" ? "ASC" : ""
        setSortObj({needsSorting: sortingOrder, fieldName: field})
    }

    useEffect(() => {
        refetch()
    }, [pageNum, itemsPerPage, sortObj, omitBroken])

    useEffect(() => {
        if (data && wallFilterList == null && gradeRanges == null) {
            refetch()
        }
    }, [data, wallFilterList, gradeRanges])

    useEffect(() => {
        if (data !== undefined && gymIdx !== null) {
            setGymData(data[gymIdx])
        }
    }, [data, gymIdx])

    if (isLoading) return <Loading />

    if (error) {
        if (error.response.data.error == "private") {
            return <PageIsPrivate errMessage={error.response.data.message} />
        } else {
            return <PageNotFound />
        }
    } 
    
    return (
        <div className="min-h-screen w-full p-4 flex-col bg-muted/40">
            <AddWallSet 
                modalVis={wallSetModal}
                setModalVis={setWallSetModal}
                gymData={gymData}
                gymId={gymId}
                wallId={wallId}
                setWallId={setWallId}
                newWallSet={newWallSet}
                setNewWallSet={setNewWallSet}
            />
            <Card x-chunk="dashboard-06-chunk-0">
                <CardHeader>
                <CardTitle className="flex flex-row items-center justify-between">
                    Admin Gyms Page
                    <ButtonLink
                        link={"/admin/create-gym"}
                        buttonName={"+ Gym"} />
                </CardTitle>
                <CardDescription>
                { data && data.feedbackLength ?
                    <>
                    Showing {" "}
                    <strong>
                        {pageNum * itemsPerPage + 1} - {" "}
                        {(pageNum + 1) * itemsPerPage < (data.feedbackLength) ? (pageNum + 1) * itemsPerPage : data.feedbackLength}
                    </strong> 
                    {" "} of {" "}
                    <strong>
                        {data && data.feedbackLength}
                    </strong>
                    {" "} feedback
                    </>  
                :
                    <span>Manage gyms</span>                          
                }
                </CardDescription>
                </CardHeader>
                <CardContent>
                <Table>
                    <TableHeader>
                    <TableRow>
                        <TableHead className="hidden w-[100px] sm:table-cell">
                            <span className="sr-only">#</span>
                        </TableHead>
                        <TableHead>
                            <Button onClick={() => handleSorting("gymName")} variant="secondary">Gym</Button>
                        </TableHead>
                        <TableHead>
                            <Button onClick={() => handleSorting("notes")} variant="secondary">Author</Button>
                        </TableHead>
                        <TableHead className="hidden md:table-cell">
                            <Button onClick={() => handleSorting("wallName")} variant="secondary">Privacy</Button>
                        </TableHead>
                        <TableHead>
                            <span>Actions</span>

                            <span className="sr-only">Actions</span>
                        </TableHead>
                    </TableRow>
                    </TableHeader>
                    <TableBody>
                        { data && data.map((item, idx) => {
                            return <TableRow>
                                <TableCell className="hidden sm:table-cell">
                                    {idx + 1}
                                </TableCell>
                                <TableCell>
                                    <p className={`w-fit font-medium py-2 px-4 rounded text-nowrap`}>{item.gymName}</p>
                                </TableCell>
                                <TableCell className="text-nowrap">
                                <Popover>
                                    <PopoverTrigger>{item.authorName}</PopoverTrigger>
                                    <PopoverContent className="w-64">
                                        <div className="flex flex-col">
                                            <div className="flex flex-row justify-start mt-2">
                                                <h4 className="text-sm font-semibold w-12">
                                                    Rating
                                                </h4>
                                                <p style={{ backgroundColor: `${item.color}`}} className={`w-fit font-medium text-indigo-50 py-1 px-2 ml-2 rounded text-nowrap`}>{item.level}</p>
                                            </div>
                                            <div className="flex flex-row justify-start mt-2">
                                                <h4 className="text-sm font-semibold w-12">
                                                    Notes
                                                </h4>
                                                <p className="text-sm text-wrap ml-2 border p-1">
                                                    {item.notes}
                                                </p>
                                            </div>
                                            <div className="flex flex-row justify-start mt-2">
                                                <h4 className="text-sm font-semibold w-12">
                                                        Wall
                                                </h4>
                                                <p className="text-sm text-wrap ml-2 py-1">
                                                    {item.wallName}
                                                </p>
                                            </div>                                                        
                                        </div>
                                    </PopoverContent>
                                </Popover>                                                
                                </TableCell>
                                <TableCell className="hidden text-nowrap md:table-cell">
                                    {item.privacy}
                                </TableCell>                                            
                                <TableCell>
                                    <DropdownMenu>
                                        <DropdownMenuTrigger asChild>
                                        <Button
                                            aria-haspopup="true"
                                            size="icon"
                                            variant="ghost"
                                        >
                                            <MoreHorizontal className="h-4 w-4" />
                                            <span className="sr-only">Toggle menu</span>
                                        </Button>
                                        </DropdownMenuTrigger>
                                        <DropdownMenuContent align="end">
                                        <DropdownMenuItem onClick={() => {setGymIdx(idx); setGymId(item._id); setWallSetModal(true)}}>                                                
                                            Add Wallset
                                        </DropdownMenuItem>
                                        <DropdownMenuItem>
                                            <Link to={{ pathname: "/admin/edit-gym/" + item._id}}>
                                                Edit General Information
                                            </Link>
                                        </DropdownMenuItem>
                                        <DropdownMenuItem>
                                            <Link to={{pathname: "/admin/list-wallsets/" + item._id}}>
                                                Edit Walls & Wallset
                                            </Link>
                                        </DropdownMenuItem>
                                        </DropdownMenuContent>
                                    </DropdownMenu>
                                </TableCell>
                            </TableRow>
                        })}                        
                    </TableBody>
                </Table>
                </CardContent>
                <CardFooter>
                    { data && data.feedbackLength ?
                            <>                                            
                                <Pagination>
                                    <PaginationContent>
                                        <PaginationItem>
                                            <PaginationPrevious onClick={() => removePage()} href="#" />
                                        </PaginationItem>
                                        <ScrollArea className="w-24 whitespace-nowrap rounded-md border">
                                            <div className="flex w-max space-x-4 p-2">
                                                {
                                                    [...Array(Math.ceil(data.feedbackLength / itemsPerPage))].map((e, idx) => {
                                                        if (idx < Math.ceil(data.feedbackLength / itemsPerPage)) {
                                                            return <PaginationItem>
                                                                    <PaginationLink onClick={() => handlePageChange(idx)} isActive={pageNum == idx ? true : false} href="#">{idx + 1}</PaginationLink>
                                                                </PaginationItem>
                                                        }
                                                        
                                                    })
                                                }
                                            </div>
                                            <ScrollBar orientation="horizontal" />
                                        </ScrollArea>                                                    
                                        <PaginationItem>
                                            <PaginationNext onClick={() => addPage()} href="#" />
                                        </PaginationItem>
                                    </PaginationContent>
                                </Pagination> 
                            </>                                                                            
                        :
                            null
                    } 
                    <div className="text-xs text-muted-foreground"> 
                        <DropdownMenu>
                            <DropdownMenuTrigger asChild>
                            <Button
                                aria-haspopup="true"
                                size="icon"
                                variant="ghost"
                            >
                                <MoreHorizontal className="h-4 w-4" />
                            </Button>
                            </DropdownMenuTrigger>
                            <DropdownMenuContent align="end">
                                <DropdownMenuLabel>Items per page:</DropdownMenuLabel>
                                <DropdownMenuSeparator />
                                <DropdownMenuCheckboxItem 
                                    onClick={(e) => {e.preventDefault(); chooseItemsPerPage(10)}} 
                                    checked={itemsPerPage == 10}>
                                    10
                                </DropdownMenuCheckboxItem>
                                <DropdownMenuCheckboxItem 
                                    onClick={(e) => {e.preventDefault(); chooseItemsPerPage(20)}} 
                                    checked={itemsPerPage == 20}>
                                    20
                                </DropdownMenuCheckboxItem>
                                <DropdownMenuCheckboxItem 
                                    onClick={(e) => {e.preventDefault(); chooseItemsPerPage(50)}} 
                                    checked={itemsPerPage == 50}>
                                    50
                                </DropdownMenuCheckboxItem>
                                <DropdownMenuCheckboxItem 
                                    onClick={(e) => {e.preventDefault(); chooseItemsPerPage(100)}} 
                                    checked={itemsPerPage == 100}>
                                    100
                                </DropdownMenuCheckboxItem>
                            </DropdownMenuContent>
                        </DropdownMenu>                                                                  
                    </div>                                                                                                                     
                </CardFooter>
            </Card>
        </div>
    )
}

export default AdminGyms