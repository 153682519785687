export function H3({text, color}) {
    const styling = color == undefined ? 
                    "scroll-m-20 text-2xl font-semibold tracking-tight" : 
                    `${color} scroll-m-20 text-2xl font-semibold tracking-tight`

    return (
      <h3 className={styling}>
        {text}
      </h3>
    )
  }