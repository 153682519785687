import { useState } from 'react'
import { Link } from 'react-router-dom';
import backCover from "../assets/img/back-cover.png";

const PageIsPrivate = ({errMessage}) => {
    const [ userId, setUserId ] = useState(sessionStorage.getItem('userId') ? sessionStorage.getItem('userId') : null)

    return (
        <div className="page404-panel header-spacer">
            <div className="page404-banner">
                <img src={backCover} className="page404-img" />
            </div>
            <div className="page404-heading page404-expand">
                <h2 className="page404-heading">Sorry! This page is private!</h2>
                <p className="page404-text">{errMessage}</p>
            </div>
            <div className="page404-buttoncontainer">
                { userId !== null ?
                    <Link to={{pathname: "/climbs"}} className="site-banner-link">
                        <h1 className="button-option button-list">Go Home</h1>
                    </Link>
                :
                    <Link to={{pathname: "/"}} className="site-banner-link">
                        <h1 className="button-option button-list">Go Home</h1>
                    </Link>
                }   
            </div>
        </div>
    )
}

export default PageIsPrivate