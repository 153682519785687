import { useMutation } from '@tanstack/react-query'
import { useNavigate} from 'react-router-dom'
import { passwordCheck } from '../../helpers/passwordCheck.js'
import { axiosAPI } from '../axiosAPI.js'

const useResetPassword = (email, form, setNotification, setMessage) => {
    const navigate = useNavigate()

    const mutation = useMutation({
        mutationFn:() => {
            let formData = {...form}
            formData["email"] = email

            return axiosAPI.post('reset-password', formData)
        },
        onSuccess: () => {
            return navigate('/')
        },
        onError: (error) => {
            setMessage([])
            for (const [key, value] of Object.entries(error.response.data)) {
                setMessage(errorValues => ([...errorValues, value]))
            }
    
            return setNotification(true)
        }
    })

    const onSubmitResetPassword = (e) => {
        e.preventDefault()

        setMessage([])

        const isPasswordValid = passwordCheck(form.password, form.password2)
        if (isPasswordValid.valid) {
            mutation.mutate(form)
        } else {
            setMessage(isPasswordValid.errorAlerts)
            return setNotification(true)
        }

        mutation.mutate()
    }

    return { onSubmitResetPassword}
}

export default useResetPassword;