import { useQuery } from '@tanstack/react-query'
import { axiosAPI } from '../axiosAPI.js'

const useQueryUserClimbs = () => {
    const { isLoading, error, data } = useQuery({
        queryKey: ['climbs'],
        queryFn: async () => {
            const response = await axiosAPI.get('users-climbs-data')

            return response.data
        },
        enabled: true,
        retry: 1,
    })

   return { isLoading, error, data}
}

export default useQueryUserClimbs;