import { useMutation, useQueryClient } from '@tanstack/react-query'
import { axiosAPI } from '../axiosAPI.js'

const useMutateAdminDeleteWallset = (gymId, wallId, wallSetId, setAlert) => {
    const queryClient = useQueryClient()

    // send to backend API
    const mutation = useMutation({
        mutationFn: async () => {
            const response = await axiosAPI.put('delete-gym-wallset/' + gymId, {wallID: wallId, wallSetID: wallSetId})
            return response.data
        }, 
        onSuccess: (data) => {
            //may need to invalidate instead
            queryClient.invalidateQueries(['all-gyms', 'list-walls'])        


            return setAlert(false)
        }
    })

    const onSubmitDeleteWallset = (e) => {
        e.preventDefault()

        mutation.mutate()
    }

    return { onSubmitDeleteWallset }
}

export default useMutateAdminDeleteWallset;