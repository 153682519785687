import { Trash2 } from 'lucide-react'
import { Button } from '../../../components/ui/button'
import {
    Alert,
    AlertDescription,
    AlertTitle,
  } from "../../../components/ui/alert"

export function AlertModal({modalVis, setModalVis, heading, subHeading, submitFunction}) {
  if (modalVis) {
    return (
      <div className="fixed top-0 h-full w-full z-20 flex items-center">
      <Alert className="w-80 shadow-xl">
        <Trash2 className="h-4 w-4" />
        <AlertTitle>{heading}</AlertTitle>
        <AlertDescription>          
          <p className="text-xs">{subHeading}</p>          
        </AlertDescription>
        <div className="mt-4 flex flex-row">
        <Button
          size="sm"
          className="mx-auto"
          variant="destructive"
          onClick={(e) => submitFunction(e)}>
            Delete
        </Button>
        <Button
          size="sm"
          className="mx-auto"
          onClick={() => setModalVis(false)}>
            Cancel
        </Button>
        </div>
      </Alert>
      </div>
    )
  } else return
  
}
  