

export function SubHeading({text, color}) {
    const styling = color == undefined ? 
                  "text-sm text-slate-500 dark:text-slate-400" : 
                  `${color} text-sm text-slate-500 dark:text-slate-400`

    return (
      <p className={styling}>
        {text}
      </p>
    )
  }
  