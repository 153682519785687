import { useMutation, useQueryClient } from '@tanstack/react-query'
import { axiosAPI } from '../axiosAPI.js'
import { Button } from '../../components/ui/button.jsx'

const UseMutateAdminUpdateWallset = ({gymId, wallId, updatedWallSet, setModalVis}) => {
    const queryClient = useQueryClient()

    // send to backend API
    const mutation = useMutation({
        mutationFn: async () => {
            const response = await axiosAPI.put('update-gym-wallset/' + gymId, {wallId: wallId, wallSetId: updatedWallSet._id, updatedWallSet: updatedWallSet})

            return response.data
        }, 
        onSuccess: (data) => {
            //may need to invalidate instead
            queryClient.invalidateQueries(['all-gyms', 'list-walls'])        

            return setModalVis(false)
        }
    })

    const onSubmitCreateWallset = (e) => {
        e.preventDefault()

        mutation.mutate()
    }

    return (
        <div>
            {mutation.isLoading ? (
            'Adding wall set...'
            ) : (
            <>
                <Button onClick={(e) => onSubmitCreateWallset(e)} size="sm">Update</Button>

                {mutation.isError ? (
                    <>
                        <p className="forgot-password-error">{mutation.error.response.data.authorization}</p>
                    </>
                ) : null}
                </>
            )}
        </div>
    )
}

export default UseMutateAdminUpdateWallset;