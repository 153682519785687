import { useState } from 'react'
import { blogData } from './data/blogData'
import { Button } from "../components/ui/button"
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../components/ui/card"
import { SubHeading } from '../blocks/components/typography/SubHeading'
import { ButtonLink } from '../blocks/components/buttons/ButtonLink'

const Blog = () => {
    const [ posts, setPosts ] = useState(blogData)
    const [ viewPost, setViewPost ] = useState('false')

    return (
        <div className="w-full">
            { viewPost !== "false" ?
                <Card className="p-4">
                    <Button 
                            size="sm" 
                            variant="outline"
                            className="mb-2"
                            onClick={() => setViewPost('false')} >
                                {"<"}
                        </Button>
                    <CardHeader className="p-4">                        
                        <CardTitle>{posts[viewPost].title}</CardTitle>
                        <CardDescription>By {posts[viewPost].author}</CardDescription>
                        <CardDescription>Created {posts[viewPost].date}</CardDescription>
                    </CardHeader>
                    <CardContent>
                        {posts[viewPost].content}
                    </CardContent>
                    <Button onClick={() => setViewPost('false')} size="sm" variant="outline">{"<"}</Button>
                </Card>
            :
                <div className="w-[18rem] py-12 mx-auto">
                    <Card>
                    <CardHeader>
                        <CardTitle className="text-2xl">Blog</CardTitle>
                        <CardDescription>
                            For progress updates or guides on using beta-break!
                        </CardDescription>
                    </CardHeader>
                    <CardContent className="grid gap-4">
                        { posts.map((post, idx)  => {
                            return <Button 
                                        onClick={() => setViewPost(idx)}
                                        key={post.title + '-' + idx}
                                        variant="ghost">
                                        <blockquote className=" flex flex-col justify-start items-start w-52 overflow-scroll mt-6 border-l-2 pl-6 italic">
                                            <h5 className="scroll-m-20 text-l font-extrabold tracking-tight">{post.title}</h5>
                                            <SubHeading text={post.date} />
                                        </blockquote>                                                                                                         
                                    </Button>
                            })
                        }
                    </CardContent>
                    <CardFooter className="justify-center mx-auto mt-4">
                        <ButtonLink link={"/"} buttonName={"Back to Homepage"} />
                    </CardFooter>
                    </Card>
                </div>
            }
        </div>        
    )
}



export default Blog