import { useState } from 'react'
import { Link } from 'react-router-dom'
import AppLogo from '../assets/img/beta-break.png'
import useMutateLogout from '../api/Site/useMutateLogout'
import {
  Menubar,
  MenubarContent,
  MenubarItem,
  MenubarMenu,
  MenubarTrigger,
} from "../components/ui/menubar"

const Header = () => {
    const [ userId, setUserId ] = useState(sessionStorage.getItem('userId') ? sessionStorage.getItem('userId') : null)

    const { onSubmitLogout } = useMutateLogout()

    return (
    <Menubar className="h-[4rem] bg-slate-50 m-0 p-0 rounded-none border-0 justify-between">
      <MenubarMenu>
        <Link to={{pathname: userId !== null ? "/dashboard" : "/"}}>
          <MenubarTrigger>
            <img src={AppLogo} className="w-4 h-auto mr-2"></img>

            <p>beta-break</p>
          </MenubarTrigger>
        </Link>
      </MenubarMenu>
      <MenubarMenu>
        <MenubarTrigger className="font-bold">MENU</MenubarTrigger>
        <MenubarContent>
            <Link to={{ pathname: "/" }}>
              <MenubarItem>
                Home
              </MenubarItem>
            </Link>
            <Link to={{ pathname: "/about" }}>
              <MenubarItem>
                About
              </MenubarItem>
            </Link>
            <Link to={{ pathname: "/howitworks" }}>
              <MenubarItem>
                How it works
              </MenubarItem>
            </Link>
            <Link to={{ pathname: "/blog" }}>
              <MenubarItem>
                Blog
              </MenubarItem>
            </Link>
            { userId !== null ? 
              <>                  
                  <Link to={{ pathname: "/dashboard" }}>
                    <MenubarItem>
                      User Dashboard
                    </MenubarItem>
                  </Link>
                  <Link to={{ pathname: "/profile" }}>
                    <MenubarItem>
                      Profile
                    </MenubarItem>
                  </Link>
                  <button onClick={(e) => onSubmitLogout(e)} >
                    <MenubarItem>
                      Logout
                    </MenubarItem>
                  </button> 
              </>
          :
          <>
              <Link to={{ pathname: "/login" }}>
                <MenubarItem>
                  Login
                </MenubarItem>
              </Link>
              <Link to={{ pathname: "/membership" }}>
                <MenubarItem>
                  Get Started
                </MenubarItem>
              </Link>
          </>
          }          
        </MenubarContent>
      </MenubarMenu>
    </Menubar>
    )
}

export default Header

