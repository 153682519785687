import { useState, useEffect } from 'react'
import Cookies from 'js-cookie'
import { Points, Point, PointMaterial} from '@react-three/drei'
import { Canvas } from '@react-three/fiber'
import { Link, useParams } from "react-router-dom"
import {
    TableCellsSplit,
    TableRowsSplit,
    Star,
    Menu,
    Pencil,
    Trash2,
    Eye,
    Waypoints,
    MessageSquareText,
    MoveUp,
    MoveDown,
    Undo2,
    StepBack,
    RouteOff,
    Save
  } from "lucide-react"

import GymScene from './GymScene.js'
import Loading from '../Site/Loading.js'
import PageIsPrivate from './PageIsPrivate.js'
import PageNotFound from '../Site/PageNotFound.js'
import useQueryGym from '../api/Gym/useQueryGym.js'
import { handleUpdatedWall } from './data/useWallStore.js'
import ModalGymIntro from '../blocks/Gym/ModalGymIntro.js'
import { checkUserGroup } from '../helpers/checkUserGroup.js'
import { handleUpdateCurrentGymWall } from './data/useWallStore'
import { gymDataFormatter } from '../helpers/dataProjectFormatters.js'
import ModalGymClimbDelete from '../blocks/Gym/ModalGymClimbDelete.js'
import UseModalClimbFeedback from '../blocks/Gym/UseModalClimbFeedback.js'
import UseMutateMakeMainGymWallSet from '../api/Gym/UseMutateMakeMainGymWallSet'
import useMutateUpdateWallsetClimbWithBorder from '../api/Gym/useMutateUpdateWallsetClimbWithBorder.js'
import ModalCreateGymClimbBlock from '../blocks/Gym/ModalCreateGymClimbBlock.js'
import { useGymStore, toggleBorderVisibility, 
         sortClimbs, prepareClimbToEdit,
         exitEditingBorder, hoverClimb 
    } from './data/useGymStore.js'
import { useBorderPointsStore, resetBorder, 
         clearBorder, removeLastVertex 
   } from './data/useBorderPointsStore.js'

import { WallIDSelect } from '../blocks/components/data/WallIDSelect'
import { AlertNormal } from '../blocks/components/alerts/AlertNormal'
import { WallSetSelect } from '../blocks/components/data/WallSetSelect'
import { CollapsibleBox } from '../blocks/components/views/CollapsibleBox'
import { DropdownFilter } from '../blocks/components/views/DropdownFilter.js'
import { SubHeading } from '../blocks/components/typography/SubHeading.js'
import { Sheet, SheetContent, SheetTrigger } from "../components/ui/sheet"
import { DropdownBox } from '../blocks/components/views/DropdownBox.js'
import { createDropdownItems } from '../blocks/components/views/createDropdownItems.js'

import { Input } from '../components/ui/input'
import { Label } from '../components/ui/label'
import { Button } from "../components/ui/button"
import { ScrollArea } from "../components/ui/scroll-area"
import {
    Card,
    CardContent,
    CardDescription,
    CardHeader,
  } from "../components/ui/card"
import {
    Tabs,
    TabsContent,
    TabsList,
    TabsTrigger,
  } from "../components/ui/tabs"
  import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
  } from "../components/ui/tooltip"

const GymCanvas = ({}) => {
    const { id } = useParams()
    const [ userId, setUserId ] = useState(sessionStorage.getItem('userId') ? sessionStorage.getItem('userId') : '')
    const [ initialGymInfoCookie, setInitialGymInfoCookie ] = useState(Cookies.get().showGymInfo == "false" ? false : true)
    const [ gymIntroVis, setGymIntroVis ] = useState(Cookies.get().showGymInfo == "false" ? false : true)
    const [ wallName, setWallName ] = useState(null)
    const [ changeWall, setChangeWall ] = useState(false)
    const [ changeWallSet, setChangeWallSet ] = useState(false)
    const [ pinWallSet, setPinWallSet ] = useState(false)
    const [ pinModalSet, setPinModalSet ] = useState(false)
    const [ alertsArray, setAlertsArray ] = useState([])
    const [ gradeFilter, setGradeFilter ] = useState([])
    const [ gradeInputs, setGradeInputs ] = useState([])
    const [ notifyFeedback, setNotifyFeedback ] = useState(false)
    const [ feedbackMessage, setFeedbackMessage ] = useState([])
    const [ feedbackModal, setFeedbackModal ] = useState(false)
    const [ climbName, setClimbName ] = useState("")
    const [ climbGrade, setClimbGrade ] = useState("")
    const [ climbID, setClimbID ] = useState("")
    const [ climbModalVis, setClimbModalVis ] = useState(false)
    const [ updateMode, setUpdateMode ] = useState(null)
    const [ deleteModalVis, setDeleteModalVis ] = useState(false)
    const [ deleteId, setDeleteId ] = useState(null)
    const [ sortOrder, setSortOrder ] = useState("ASC")
    const [ highlightMode, setHighlightMode ] = useState(false)

    const climbs = useGymStore((state) => state.climbs)
    const setClimbs = useGymStore((state) => state.setClimbs)
    const climbToEdit = useGymStore((state) => state.climbToEdit)
    const currentWallID = useGymStore((state) => state.currentWallID)
    const currentWallSetID = useGymStore((state) => state.currentWallSetID)
    const setCurrentWallID = useGymStore((state) => state.setCurrentWallID)
    const setCurrentWallSetID = useGymStore((state) => state.setCurrentWallSetID)
    const currentPoints = useBorderPointsStore((state) => state.currentPoints)
    const setCurrentPoints = useBorderPointsStore((state) => state.setCurrentPoints)

    const { data, isLoading, error } = useQueryGym(id, setUserId)
    const { onSubmitUpdateWallsetClimbWithBorder } = useMutateUpdateWallsetClimbWithBorder(currentWallID, currentWallSetID, setHighlightMode, setWallName)

    const handleSetGymInfoCookie = () => {
        const updatedGymInfoCookie = initialGymInfoCookie == true ? false : true

        Cookies.set('showGymInfo', updatedGymInfoCookie)
        setInitialGymInfoCookie(updatedGymInfoCookie)
    }

    const handleUpdateWallURL = (value) => {
        handleUpdateCurrentGymWall(value, data)
    }

    const handleUpdateGradeFilter = (grade) => {
        const updatedGradeFilter = gradeFilter.map((item) => {
            if (item.grade == grade) {
                let updatedItem = {...item}
                updatedItem.filter = updatedItem.filter == true ? false : true
                
                return updatedItem
            }

            return item
        })

        setGradeFilter(updatedGradeFilter)
    }

    const handleToggleFeedbackModal = (id, name, grade) => {
        setFeedbackModal(!feedbackModal)
        setClimbGrade(grade)
        setClimbName(name)
        setClimbID(id)
    }

    const getWallOptions = () => {
        let wallOptions = data.walls.map((wall) => {
            return {
                name: wall.wallName,
                wallID: wall._id
            }
        })

        return wallOptions;
    }

    const resetFilteredClimbs = () => {
        gymDataFormatter(data, currentWallID, currentWallSetID, handleUpdatedWall, setClimbs, setWallName)
    }   

    const updateDeleteGymClimbAlert = (id, name, grade) => {
        setDeleteId({id: id, name: name, grade: grade})
    }

    useEffect(() => {
        if (currentWallID && currentWallSetID && data && data.walls && id == data._id) {
            gymDataFormatter(data, currentWallID, currentWallSetID, handleUpdatedWall, setClimbs, setWallName)
        }
    }, [currentWallID, currentWallSetID, data])

    useEffect(() => {
        let noDuplicateGrades= []
        let gradeList = []
        
        climbs.map((climb) => {
            if (noDuplicateGrades.includes(climb.grade)) {
                return
            } 

            noDuplicateGrades.push(climb.grade)

            return gradeList.push({
                grade: climb.grade,
                filter: true
            })
        })

        setGradeFilter(gradeList)
    }, [climbs])

    useEffect(() => {
        if (gradeFilter.length > 0) {
            const inputs = gradeFilter.map((iteration) => {
                return <div className="flex flex-row" style={{justifyContent: "start"}} >
                    <Input 
                        type="checkbox" 
                        onClick={() => {handleUpdateGradeFilter(iteration.grade)}} 
                        name="grades" 
                        checked={iteration.filter}
                        className="w-4 h-4 mr-2 mb-1"/>
                    <Label className="mr-2 text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70" >{iteration.grade}</Label>
                </div>
                        
            })

            setGradeInputs(inputs)
        }
    }, [gradeFilter])

    useEffect(() => {
        setCurrentPoints([])
        setWallName(null)
        setCurrentWallID(null)
        setCurrentWallSetID(null)
    }, [])

    if (isLoading) return <Loading />

    if (error) {
        if (error.response.data.error == "private") {
            return <PageIsPrivate errMessage={error.response.data.message} />
        } else {
            return <PageNotFound />
        }
    } 

    return (
        <div className="flex min-h-screen w-full">    
            <ModalGymIntro 
                modalVis={gymIntroVis} 
                setModalVis={setGymIntroVis} 
                getWallOptions={getWallOptions}
                gymInfoCookie={initialGymInfoCookie} 
                handleSetGymInfoCookie={handleSetGymInfoCookie} />            
            <UseModalClimbFeedback 
                modalVis={feedbackModal} 
                setModalVis={setFeedbackModal} 
                climbName={climbName} 
                climbGrade={climbGrade} 
                climbID={climbID}
                setNotification={setNotifyFeedback} 
                setMessage={setFeedbackMessage}
                ratingSystem={data && data.ratingSystem} 
                setWallName={setWallName} />
            <AlertNormal 
                modalVis={notifyFeedback} 
                setModalVis={setNotifyFeedback}
                alertsArray={feedbackMessage} />
            <ModalCreateGymClimbBlock 
                climbID={updateMode} 
                gradeRangeList={data && data.gradingSystem}
                climbModalVis={climbModalVis}
                setClimbModalVis={setClimbModalVis} />
            <ModalGymClimbDelete
                modalVis={deleteModalVis}
                setModalVis={setDeleteModalVis}
                currentWallID={currentWallID} 
                currentWallSetID={currentWallSetID}
                deleteId={deleteId} />
            <div className="w-72 hidden border-r bg-muted/40 md:block">
                <div className="flex max-h-screen flex-col gap-2">
                    <div className="flex h-14 items-center border-b px-4 lg:h-[60px] lg:px-6">
                        <Link href="/" className="flex items-center gap-2 font-semibold">
                        <span className="">Menu</span>
                        </Link>
                    </div>

                    <div className="flex-1">
                        <nav className="grid items-start px-2 text-sm font-medium lg:px-4">
                            <Tabs defaultValue="climb" className="w-52">
                                <TabsList className="flex flex-row justify-around">
                                    <TabsTrigger value="climb">Climb</TabsTrigger>
                                    <TabsTrigger value="wall">Wall</TabsTrigger>
                                </TabsList>
                                <TabsContent value="wall">
                                    <Card>
                                    <CardHeader>
                                            <CardDescription>
                                                Navigate through gym walls and wall sets.
                                            </CardDescription>
                                        </CardHeader>
                                        <CardContent className="space-y-2 px-1">
                                            <CollapsibleBox
                                                isOpen={changeWall}
                                                setIsOpen={setChangeWall}
                                                title={"Change Wall"}
                                                titleIcon={<TableCellsSplit className="h-4 w-4" />}
                                                content={<WallIDSelect wallValue={currentWallID} updateWallValue={handleUpdateWallURL} getWallOptions={getWallOptions} />}
                                            />
                                            <CollapsibleBox
                                                isOpen={changeWallSet}
                                                setIsOpen={setChangeWallSet}
                                                title={"Change Wall Set"}
                                                titleIcon={<TableRowsSplit className="h-4 w-4" />}
                                                content={<WallSetSelect />}
                                            />                        
                                            { userId == data.authorID || checkUserGroup(data.collaborators, userId) == true ?
                                                <CollapsibleBox
                                                    isOpen={pinWallSet}
                                                    setIsOpen={setPinWallSet}
                                                    title={"Pin Wall Set"}
                                                    titleIcon={<Star className="h-4 w-4" />}
                                                    content={<>
                                                        <Label>Set as main wall set?</Label>
                                                        <SubHeading text={"When navigating to this wall, this wall set (wall and holds) will be the first to load."} />
                                                        <AlertNormal 
                                                            modalVis={pinModalSet} 
                                                            setModalVis={setPinModalSet}
                                                            alertsArray={alertsArray} />
                                                        <UseMutateMakeMainGymWallSet 
                                                            wallID={currentWallID} 
                                                            wallSetID={currentWallSetID}
                                                            setNotification={setPinModalSet}
                                                            setMessage={setAlertsArray} />
                                                    </>}
                                                />                                  
                                            :
                                                null
                                            }  
                                        </CardContent>                                       
                                    </Card>
                                </TabsContent>
                                <TabsContent value="climb">
                                <Card className="min-w-64 sm:min-w-52">
                                    <CardHeader>
                                        <CardDescription>
                                            Navigate, view, and filter climbs.
                                        </CardDescription>
                                        { userId == data.authorID || checkUserGroup(data.collaborators, userId) == true ?
                                            <Button onClick={() => {setUpdateMode(null); setClimbModalVis(!climbModalVis)}} size="sm">+ Climb</Button>
                                        :
                                            null 
                                        }
                                    </CardHeader>
                                    <CardContent className="space-y-2">
                                        <Label>Sort and Filter</Label>
                                        <div className="flex flex-col justify-start items-start">  
                                        { gradeFilter.length > 0 ?

                                            <DropdownFilter
                                                resetClimbs={resetFilteredClimbs}
                                                options={gradeInputs}
                                            />                                        
                                        :
                                            null
                                        }                                                                                  
                                        </div>                                        
                                    </CardContent>
                                    <CardContent className="pb-1 flex flex-row justify-between items-center">
                                        <Label>Climbs</Label>
                                        <Button 
                                            size="sm"
                                            variant="ghost"
                                            onClick={() => sortClimbs(sortOrder, setSortOrder)}>
                                                Sort {sortOrder == "ASC" ? <MoveDown className="h-4 w-4" /> : <MoveUp className="h-4 w-4" />}</Button>                                                
                                    </CardContent>
                                    <CardContent className="h-52 w-72">
                                        <ScrollArea className="h-56 w-56 rounded-md border pr-4">
                                            {climbs.map((climb, idx) => {
                                                let showClimb = false

                                                for (const element of gradeFilter) {
                                                    if (element.grade == climb.grade && element.filter == true) {
                                                        showClimb = true
                                                    }
                                                }
        
                                                if (showClimb == true) {
                                                const gradeCol = data.gradingSystem.findIndex((grade) => grade.grade == climb.grade)
                                                const climbTitle = data && data.gradingSystem && gradeCol !== -1 && data.gradingType == "color" ? 
                                                                        <p style={{ backgroundColor: data.gradingSystem[gradeCol].color}} className="h-6 w-16 overflow-auto rounded px-2 py-0.5 text-indigo-50">{climb.grade}</p>
                                                                    :
                                                                        <p className="h-6 w-16 overflow-auto rounded px-2 py-0.5 text-indigo-50 bg-indigo-950">{climb.grade}</p>
                                                const ratingItem = createDropdownItems("Rate", <MessageSquareText className="mr-2 h-4 w-4" />, () => handleToggleFeedbackModal(climb.id, climb.name, climb.grade))
                                                const showItem = createDropdownItems("Highlight Climb", <Eye className="mr-2 h-4 w-4"/>, () => {toggleBorderVisibility(Points, Point, PointMaterial, climb.id)})

                                                const editItem = createDropdownItems("Edit", <Pencil className="mr-2 h-4 w-4" />, () => {setUpdateMode(climb.id); setClimbModalVis(!climbModalVis)})
                                                const deleteItem = createDropdownItems("Delete", <Trash2 className="mr-2 h-4 w-4" />, () => {setDeleteModalVis(true); updateDeleteGymClimbAlert(climb.id, climb.name, climb.grade)})
                                                const highlightItem = createDropdownItems("Set Highlighted Holds", <Waypoints className="mr-2 h-4 w-4"/>, () => {prepareClimbToEdit(idx); setHighlightMode(true)})
                                                return <DropdownBox 
                                                        title={<>{climbTitle} &nbsp; {climb.name}</>}
                                                        onMouseEnter={() => hoverClimb(true, climb.id, Points, Point, PointMaterial)}
                                                        onMouseLeave={() => hoverClimb(false)}
                                                        options={[ ratingItem, showItem, editItem, deleteItem, highlightItem ]} />                                                    
                                                } else return                                       
                                            })}                                            
                                        </ScrollArea>                                        
                                    </CardContent>
                                    </Card>
                                </TabsContent>
                            </Tabs>                            
                        </nav>
                    </div>                
                </div>
            </div>                
            <div className="flex flex-col w-full custom-canvas-break">
                <main className="flex flex-1 flex-col gap-4 p-4 lg:gap-6 lg:p-6">
                <div className="flex items-center">
                    <Sheet>
                        <SheetTrigger asChild>
                        <Button
                            variant="outline"
                            size="icon"
                            className="h-14 shrink-0 md:hidden"
                        >
                            <Menu className="h-5 w-5" />
                            <span className="sr-only">Toggle navigation menu</span>
                        </Button>
                        </SheetTrigger>
                        <SheetContent side="left" className="flex flex-col p-2">
                        <nav className="grid gap-2 text-lg font-medium">
                            <Tabs defaultValue="climb" className="w-52">
                                <TabsList className="flex flex-row justify-around">
                                    <TabsTrigger value="climb">Climb</TabsTrigger>
                                    <TabsTrigger value="wall">Wall</TabsTrigger>
                                </TabsList>
                                <TabsContent value="wall">
                                    <Card>
                                        <CardHeader>
                                            <CardDescription>
                                                Navigate through gym walls and wall sets.
                                            </CardDescription>
                                        </CardHeader>
                                        <CardContent className="space-y-2 px-1">
                                            <CollapsibleBox
                                                isOpen={changeWall}
                                                setIsOpen={setChangeWall}
                                                title={"Change Wall"}
                                                titleIcon={<TableCellsSplit className="h-4 w-4" />}
                                                content={<WallIDSelect wallValue={currentWallID} updateWallValue={handleUpdateWallURL} getWallOptions={getWallOptions} />}
                                            />
                                            <CollapsibleBox
                                                isOpen={changeWallSet}
                                                setIsOpen={setChangeWallSet}
                                                title={"Change Wall Set"}
                                                titleIcon={<TableRowsSplit className="h-4 w-4" />}
                                                content={<WallSetSelect />}
                                            />                        
                                            { userId == data.authorID || checkUserGroup(data.collaborators, userId) == true ?
                                                <CollapsibleBox
                                                    isOpen={pinWallSet}
                                                    setIsOpen={setPinWallSet}
                                                    title={"Pin Wall Set"}
                                                    titleIcon={<Star className="h-4 w-4" />}
                                                    content={<>
                                                        <Label>Set as main wall set?</Label>
                                                        <SubHeading text={"When navigating to this wall, this wall set (wall and holds) will be the first to load."} />
                                                        <AlertNormal 
                                                            modalVis={pinModalSet} 
                                                            setModalVis={setPinModalSet}
                                                            alertsArray={alertsArray} />
                                                        <UseMutateMakeMainGymWallSet 
                                                            wallID={currentWallID} 
                                                            wallSetID={currentWallSetID}
                                                            setNotification={setPinModalSet}
                                                            setMessage={setAlertsArray} />
                                                    </>}
                                                />                                  
                                            :
                                                null
                                            }  
                                        </CardContent>                                    
                                    </Card>
                                </TabsContent>
                                <TabsContent value="climb">
                                    <Card className="min-w-64 sm:min-w-52">
                                    <CardHeader>
                                        <CardDescription>
                                            Navigate, view, and filter climbs.
                                        </CardDescription>
                                        { userId == data.authorID || checkUserGroup(data.collaborators, userId) == true ?
                                            <Button onClick={() => {setUpdateMode(null); setClimbModalVis(!climbModalVis)}} size="sm">+ Climb</Button>
                                        :
                                            null 
                                        }
                                    </CardHeader>
                                    <CardContent className="space-y-2">
                                        <Label>Sort and Filter</Label>
                                        <div className="flex flex-col justify-start items-start">  
                                        { gradeFilter.length > 0 ?

                                            <DropdownFilter
                                                resetClimbs={resetFilteredClimbs}
                                                options={gradeInputs}
                                            />                                        
                                        :
                                            null
                                        }                                                                                  
                                        </div>                                        
                                    </CardContent>
                                    <CardContent className="pb-1 flex flex-row justify-between items-center">
                                        <Label>Climbs</Label>
                                        <Button 
                                            size="sm"
                                            variant="ghost"
                                            onClick={() => sortClimbs(sortOrder, setSortOrder)}>
                                                Sort {sortOrder == "ASC" ? <MoveDown className="h-4 w-4" /> : <MoveUp className="h-4 w-4" />}</Button>                                                
                                    </CardContent>
                                    <CardContent className="h-52 w-72">
                                        <ScrollArea className="h-56 w-56 rounded-md border pr-4">
                                            {climbs.map((climb, idx) => {
                                                let showClimb = false

                                                for (const element of gradeFilter) {
                                                    if (element.grade == climb.grade && element.filter == true) {
                                                        showClimb = true
                                                    }
                                                }
        
                                                if (showClimb == true) {
                                                const gradeCol = data.gradingSystem.findIndex((grade) => grade.grade == climb.grade)
                                                const climbTitle = data && data.gradingSystem && gradeCol !== -1 && data.gradingType == "color" ? 
                                                                        <p style={{ backgroundColor: data.gradingSystem[gradeCol].color}} className="h-6 w-16 overflow-auto rounded px-2 py-0.5 text-indigo-50">{climb.grade}</p>
                                                                    :
                                                                        <p className="h-6 w-16 overflow-auto rounded px-2 py-0.5 text-indigo-50 bg-indigo-950">{climb.grade}</p>
                                                const ratingItem = createDropdownItems("Rate", <MessageSquareText className="mr-2 h-4 w-4" />, () => handleToggleFeedbackModal(climb.id, climb.name, climb.grade))
                                                const showItem = createDropdownItems("Highlight Climb", <Eye className="mr-2 h-4 w-4"/>, () => {toggleBorderVisibility(Points, Point, PointMaterial, climb.id)})

                                                const editItem = createDropdownItems("Edit", <Pencil className="mr-2 h-4 w-4" />, () => {setUpdateMode(climb.id); setClimbModalVis(!climbModalVis)})
                                                const deleteItem = createDropdownItems("Delete", <Trash2 className="mr-2 h-4 w-4" />, () => {setDeleteModalVis(true); updateDeleteGymClimbAlert(climb.id, climb.name, climb.grade)})
                                                const highlightItem = createDropdownItems("Set Highlighted Holds", <Waypoints className="mr-2 h-4 w-4"/>, () => {prepareClimbToEdit(idx); setHighlightMode(true)})
                                                return <DropdownBox 
                                                        title={<>{climbTitle} &nbsp; {climb.name}</>}
                                                        onMouseEnter={() => hoverClimb(true, climb.id, Points, Point, PointMaterial)}
                                                        onMouseLeave={() => hoverClimb(false)}
                                                        options={[ ratingItem, showItem, editItem, deleteItem, highlightItem ]} />                                                    
                                                } else return                                       
                                            })}
                                        </ScrollArea>                                        
                                    </CardContent>
                                    </Card>
                                </TabsContent>
                            </Tabs>   
                        </nav>                                            
                        </SheetContent>
                    </Sheet>
                    <div className="flex flex-col pl-2">
                        <h1 className="text-lg font-semibold md:text-2xl">{data && data.gymName}</h1>
                        <SubHeading text={wallName ? "Wall Displayed: " + wallName: null} />
                    </div>  
                    <div className="ml-auto">
                        <Button onClick={() => {setGymIntroVis(true)}}>INFO</Button>
                    </div>              
                </div>
                { highlightMode == true ?
                    <div className="relative float-start w-full z-20 flex flex-col" style={{top: "2rem", left: "0.5rem", height: "0"}}>
                        <TooltipProvider>
                            <Tooltip>
                                <TooltipTrigger asChild>
                                    <Button 
                                        size="sm"
                                        variant="outline"
                                        className="w-8 mb-2 p-1"
                                        onClick={() => removeLastVertex()}>
                                            <Undo2 className="w-4 h-4" />
                                        </Button>
                                </TooltipTrigger>
                                <TooltipContent>
                                <p>Remove the last point added.</p>
                                </TooltipContent>
                            </Tooltip>
                        </TooltipProvider>
                        <TooltipProvider>
                            <Tooltip>
                                <TooltipTrigger asChild>
                                    <Button 
                                        size="sm"
                                        variant="outline"
                                        className="w-8 mb-2 p-1"
                                        onClick={() => resetBorder(climbToEdit.borderPoints)}>
                                            <StepBack className="w-4 h-4" />
                                        </Button>
                                </TooltipTrigger>
                                <TooltipContent>
                                <p>Revert to original points before editing.</p>
                                </TooltipContent>
                            </Tooltip>
                        </TooltipProvider>
                        <TooltipProvider>
                            <Tooltip>
                                <TooltipTrigger asChild>
                                    <Button 
                                        size="sm"
                                        variant="outline"
                                        className="w-8 mb-2 p-1"
                                        onClick={() => clearBorder()}>
                                            <RouteOff className="w-4 h-4" />                                        
                                        </Button>
                                </TooltipTrigger>
                                <TooltipContent>
                                <p>Clear all points.</p>
                                </TooltipContent>
                            </Tooltip>
                        </TooltipProvider>
                        <TooltipProvider>
                            <Tooltip>
                                <TooltipTrigger asChild>
                                    <Button 
                                        size="sm"
                                        className="w-8 mb-2 p-1"
                                        onClick={(e) => onSubmitUpdateWallsetClimbWithBorder(e, currentPoints)}>
                                            <Save className="w-4 h-4" />                                        
                                        </Button>
                                </TooltipTrigger>
                                <TooltipContent>
                                <p>Save climb points.</p>
                                </TooltipContent>
                            </Tooltip>
                        </TooltipProvider>
                        <TooltipProvider>
                            <Tooltip>
                                <TooltipTrigger asChild>
                                    <Button 
                                        size="sm"
                                        variant="destructive"
                                        className="w-8 mb-2 p-1"
                                        onClick={() => {setHighlightMode(false); exitEditingBorder()}}>
                                            <Trash2 className="w-4 h-4" />                                        
                                        </Button>
                                </TooltipTrigger>
                                <TooltipContent>
                                <p>Exit without saving changes.</p>
                                </TooltipContent>
                            </Tooltip>
                        </TooltipProvider>
                    </div>
                :
                    null
                }                
                <div
                    className="flex flex-1 items-center justify-center rounded-lg border border-dashed shadow-sm" 
                    x-chunk="dashboard-02-chunk-1">                
                    <Canvas shadows camera={{ position: [0, 5, 25], fov: 60 }}>
                        <GymScene highlightMode={highlightMode} />
                    </Canvas>  
                </div>
                </main>
            </div>
        </div>
    )
}

export default GymCanvas