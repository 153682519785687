import { useEffect } from 'react'
import { AlertCircle } from "lucide-react"
 
import {
  Alert,
  AlertDescription,
  AlertTitle,
} from "../../../components/ui/alert"
 
export function AlertWarning({modalVis, setModalVis, alertsArray}) {
  useEffect(() => {
    let timer;
    
    const destroyAlert = () => {
        clearTimeout(timer)
    }

    const showAlert = () => {
        if (modalVis == true) {    
            timer = setTimeout(() => {
                setModalVis(false)
            }, 2000)    
        } else {
            return destroyAlert()
        }
    }

    showAlert()
  },[modalVis])

  if (modalVis && alertsArray.length > 0) {
    return (
      <div className="fixed top-0 h-full w-full z-20 bg-black/10">
      <Alert variant="destructive" className="modal-alert">
        <AlertCircle className="h-4 w-4" />
        <AlertTitle>Error</AlertTitle>
        <AlertDescription>
          { alertsArray && alertsArray.map((alert) => {
            return <p>{alert}</p>
            })            
          }
        </AlertDescription>
      </Alert>
      </div>
      
    )
  }
}