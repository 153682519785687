import { useState } from 'react'
import { Link } from 'react-router-dom'
import useContactUs from '../api/Site/useContactUs'
import {AlertWarning} from '../blocks/components/alerts/AlertWarning'
import {ButtonLoading} from '../blocks/components/buttons/ButtonLoading'

import { Button } from "../components/ui/button"
import { Input } from "../components/ui/input"
import { Label } from "../components/ui/label"
import { Textarea } from '../components/ui/textarea'

const Contact = () => {
    const [ form, setForm ] = useState({
        firstName: sessionStorage.getItem("firstName") ? sessionStorage.getItem("firstName") : "",
        lastName: sessionStorage.getItem("lastName") ? sessionStorage.getItem("lastName") : "",
        email: sessionStorage.getItem("email") ? sessionStorage.getItem("email") : "",
    })
    const [ statusVis, setStatusVis ] = useState(false)
    const [ statusAlerts, setStatusAlerts ] = useState([])
    const [ isLoading, setIsLoading ] = useState(false)

    const handleFormChange = (name, value) => {
        setForm({...form, [name]: value})
    }

    const { onSubmitContactUs } = useContactUs(form, statusVis, setStatusVis, setStatusAlerts, setIsLoading)
    
    return (
        <div className="bg-zinc-100 w-full lg:grid lg:min-h-[600px] lg:grid-cols-2 xl:min-h-[800px]">
            <AlertWarning 
                modalVis={statusVis} 
                setModalVis={setStatusVis}
                alertsArray={statusAlerts} 
            />
          <div className="flex items-center justify-center py-12">
            <div className="mx-auto grid w-[350px] gap-6">
              <div className="grid gap-2 text-center">
                <h1 className="text-3xl font-bold">Contact Us</h1>
                <p className="text-balance text-muted-foreground">
                  Suggestions, Questions, Feedback? We would love to hear from you!
                </p>
              </div>
              <form className="grid gap-4" onSubmit={(e) => onSubmitContactUs(e)}>
                <div className="grid gap-2">
                  <Label htmlFor="firstName">First Name</Label>
                  <Input
                    id="firstName"
                    type="text"
                    name="firstName"
                    placeholder="Enter your first name"
                    required
                    value={form.firstName} 
                    onChange={(e) => handleFormChange(e.target.name, e.target.value)}
                  />
                </div>
                <div className="grid gap-2">
                  <Label htmlFor="lastName">lastName</Label>
                  <Input
                    id="lastName"
                    type="text"
                    name="lastName"
                    placeholder="Enter your last name"
                    required
                    value={form.lastName} 
                    onChange={(e) => handleFormChange(e.target.name, e.target.value)}
                  />
                </div>            
                <div className="grid gap-2">
                  <Label htmlFor="email">Email</Label>
                  <Input
                    id="email"
                    type="email"
                    name="email"
                    placeholder="Enter your email"
                    required
                    value={form.email} 
                    onChange={(e) => handleFormChange(e.target.name, e.target.value)}
                  />
                </div>
                <div className="grid gap-2">
                  <Label htmlFor="email">Message</Label>
                  <Textarea 
                    placeholder="Type your message here." 
                    rows={10} 
                    name="message" 
                    value={form.message} 
                    onChange={(e) => handleFormChange(e.target.name, e.target.value)}
                    />
                </div>
                
                { isLoading == false ?
                    <Button type="submit" className="w-full">
                        Send Feedback
                    </Button>
                :
                    <ButtonLoading />
                }
              </form>
              <div className="mt-4 text-center text-sm">
                Already have an account?{" "}
                <Link 
                    to={{pathname: "/login"}}
                    className="underline">
                  Login
                </Link>
              </div>
            </div>
          </div>
          <div className="hidden bg-muted lg:block bg-indigo-950">                            
          </div>
        </div>
    )
}

export default Contact

