export function Paragraph({text, color}) {
    const styling = color == undefined ? 
                  "leading-7 [&:not(:first-child)]:mt-6" : 
                  `${color} leading-7 [&:not(:first-child)]:mt-6`

    return (
      <p className={styling}>
        {text}
      </p>
    )
  }
  