import { useState, useEffect } from 'react'
import { HexColorPicker } from 'react-colorful'
import { useGymStore } from '../../Beta/data/useGymStore.js'
import UseMutateCreateGymClimb from '../../api/Gym/UseMutateCreateGymClimb.js'
import useMutateUpdateWallsetClimb from '../../api/Gym/useMutateUpdateWallsetClimb.js'

import { GradeSelect } from '../components/data/GradeSelect.js'

import { Label } from '../../components/ui/label.jsx'
import { Input } from '../../components/ui/input'
import { Button } from '../../components/ui/button'
import {
    Card,
    CardContent,
    CardHeader,
    CardTitle,
  } from "../../components/ui/card.jsx"

const ModalCreateGymClimbBlock = ({climbModalVis, setClimbModalVis, climbID, gradeRangeList}) => {
    const climbs = useGymStore((state) => state.climbs)
    const currentWallSetID = useGymStore((state) => state.currentWallSetID)
    const currentWallID = useGymStore((state) => state.currentWallID)

    const [ form, setForm ] = useState({name: '', grade: '', tags: []})
    const [ newTag, setNewTag ] = useState('')
    const [ holdColor, setHoldColor ] = useState("#FFFFFF")

    const { onSubmitUpdateWallsetClimb } = useMutateUpdateWallsetClimb(currentWallID, currentWallSetID, form, holdColor, setClimbModalVis)

    const handleFormChange = (name, value) => {
        setForm({...form, [name]: value})
    }

    const handleGradeChange = (value) => {
        handleFormChange("grade", value)
    }

    useEffect(() => {
        if (climbID !== null) {
            const climbIdx = climbs.findIndex((climb) => climb.id == climbID)

            setForm({id: climbs[climbIdx].id, name: climbs[climbIdx].name, grade: climbs[climbIdx].grade, tags: climbs[climbIdx].tags})
            setHoldColor(climbs[climbIdx].color)
        } else {
            setForm({name: '', grade: '', tags: []})
            setHoldColor("#FFFFFF")

        }
    }, [climbID])

    if (climbModalVis) {
        return (
            <div className="fixed top-0 h-full w-full z-20 bg-black/10">
                <Card className="mt-12 mx-auto w-64 box-border rounded-lg p-1 overflow-auto">
                    <CardHeader>
                        <CardTitle>Climb</CardTitle>
                    </CardHeader>
                    <CardContent>
                        <Label>Name</Label>
                        <Input
                            id="name"
                            type="text"
                            name="name"
                            placeholder="Enter the climb name"
                            required
                            value={form.name} 
                            onChange={(e) => handleFormChange(e.target.name, e.target.value)}
                            />
                        <Label>Grade</Label>
                        <GradeSelect
                            gradeValue={form.grade}
                            updateGradeValue={handleGradeChange}
                            getGradeOptions={gradeRangeList} />
                        <Label>Colour</Label>                
                        <div className="climb-color mt-4">
                            <HexColorPicker color={holdColor} onChange={setHoldColor} />
                        </div>
                    </CardContent>
                    <div className="flex flex-row justify-around mb-4">                
                        { climbID !== null ?   
                            <Button 
                                onClick={(e) => onSubmitUpdateWallsetClimb(e)}
                                size="sm">
                                    Save Climb
                            </Button>
                        :
                            <UseMutateCreateGymClimb wallID={currentWallID} wallSetID={currentWallSetID} climb={form} holdColor={holdColor} setClimbModalVis={setClimbModalVis} />
                        }
                        
                        <Button 
                            onClick={() => setClimbModalVis(false)} 
                            variant="destructive"
                            size="sm">
                                Cancel
                        </Button>
    
                    </div>
                </Card>
            </div>
        )
    } else return null
    
}

export default ModalCreateGymClimbBlock