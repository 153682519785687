import { useState } from 'react'
import { Link } from 'react-router-dom'
import Loading from '../Site/Loading.js'
import PageIsPrivate from './PageIsPrivate.js'
import PageNotFound from '../Site/PageNotFound.js'
import { daysOfWeek } from '../helpers/daysOfOurLives.js'
import useQueryExploreGyms from '../api/Gym/useQueryExploreGyms.js'
import { ExploreTable } from '../blocks/components/data/ExploreTable.js'
import { Button } from '../components/ui/button'
import {
    Card,
    CardContent,
    CardDescription,
    CardFooter,
    CardHeader,
    CardTitle,
  } from "../components/ui/card.jsx"
import '../tailwind.css'
import './beta.css'


const ExploreGyms = () => {
    const [ today, setToday ] = useState({})
    const { isLoading, error, data } = useQueryExploreGyms(daysOfWeek, setToday)

    if (isLoading) return <Loading />

    if (error) {
        if (error.response.data.error == "private") {
            return <PageIsPrivate errMessage={error.response.data.message} />
        } else {
            return <PageNotFound />
        }
    } 

    const exploreHeadings = ["#", "Name", "Hours", "Address", "City", "Province"]
    return (
        <>
        <div className="flex min-h-screen w-full flex-col bg-muted/40">
            <div className="flex flex-col sm:gap-4 sm:py-4 sm:pl-4">
                <main className="grid flex-1 items-start gap-4 p-4 sm:px-6 sm:py-0 md:gap-8">
                <div>
                    <div className="flex justify-end items-center mb-4">
                            <Button 
                                onClick={() => console.log('clicked')}>Option</Button>                    
                            <Button 
                                variant="destructive"
                                style={{ marginLeft: "0.5rem"}}
                                onClick={() => console.log('destructed')}>Reset</Button>
                    </div>                    
                    <Card x-chunk="dashboard-06-chunk-0">
                        <CardHeader >
                        <CardTitle>Explore Gyms</CardTitle>
                        <CardDescription>
                            Search through climbing gyms!
                        </CardDescription>
                        </CardHeader>
                        <CardContent>
                            <ExploreTable 
                                headings={exploreHeadings} 
                                exploreGyms={data}
                                today={today} />  
                        </CardContent>
                        <CardFooter>
                            {/* { data && data.feedbackLength ?
                                    <>                                            
                                        <Pagination>
                                            <PaginationContent>
                                                <PaginationItem>
                                                    <PaginationPrevious onClick={() => removePage()} href="#" />
                                                </PaginationItem>
                                                <ScrollArea className="w-24 whitespace-nowrap rounded-md border">
                                                    <div className="flex w-max space-x-4 p-2">
                                                        {
                                                            [...Array(Math.ceil(data.feedbackLength / itemsPerPage))].map((e, idx) => {
                                                                if (idx < Math.ceil(data.feedbackLength / itemsPerPage)) {
                                                                    return <PaginationItem>
                                                                            <PaginationLink onClick={() => handlePageChange(idx)} isActive={pageNum == idx ? true : false} href="#">{idx + 1}</PaginationLink>
                                                                        </PaginationItem>
                                                                }
                                                                
                                                            })
                                                        }
                                                    </div>
                                                    <ScrollBar orientation="horizontal" />
                                                </ScrollArea>                                                    
                                                <PaginationItem>
                                                    <PaginationNext onClick={() => addPage()} href="#" />
                                                </PaginationItem>
                                            </PaginationContent>
                                        </Pagination> 
                                    </>                                                                            
                                :
                                    null
                            } 
                            <div className="text-xs text-muted-foreground"> 
                                <DropdownMenu>
                                    <DropdownMenuTrigger asChild>
                                    <Button
                                        aria-haspopup="true"
                                        size="icon"
                                        variant="ghost"
                                    >
                                        <MoreHorizontal className="h-4 w-4" />
                                    </Button>
                                    </DropdownMenuTrigger>
                                    <DropdownMenuContent align="end">
                                        <DropdownMenuLabel>Items per page:</DropdownMenuLabel>
                                        <DropdownMenuSeparator />
                                        <DropdownMenuCheckboxItem 
                                            onClick={(e) => {e.preventDefault(); chooseItemsPerPage(10)}} 
                                            checked={itemsPerPage == 10}>
                                            10
                                        </DropdownMenuCheckboxItem>
                                        <DropdownMenuCheckboxItem 
                                            onClick={(e) => {e.preventDefault(); chooseItemsPerPage(20)}} 
                                            checked={itemsPerPage == 20}>
                                            20
                                        </DropdownMenuCheckboxItem>
                                        <DropdownMenuCheckboxItem 
                                            onClick={(e) => {e.preventDefault(); chooseItemsPerPage(50)}} 
                                            checked={itemsPerPage == 50}>
                                            50
                                        </DropdownMenuCheckboxItem>
                                        <DropdownMenuCheckboxItem 
                                            onClick={(e) => {e.preventDefault(); chooseItemsPerPage(100)}} 
                                            checked={itemsPerPage == 100}>
                                            100
                                        </DropdownMenuCheckboxItem>
                                    </DropdownMenuContent>
                                </DropdownMenu>                                                                  
                            </div>                                                                                                                      */}
                        </CardFooter>
                    </Card>
                </div>
                </main>
            </div>
        </div>        
        </>
        
    )
}

export default ExploreGyms;