import { useQuery } from '@tanstack/react-query'
import { axiosAPI } from '../axiosAPI.js'
import { useWallStore } from '../../Beta/data/useWallStore.js'
import { useGymStore } from '../../Beta/data/useGymStore.js'

const useQueryGym = (id, setUserId) => {
    const setCurrentWallModel = useWallStore((state) => state.setCurrentWallModel)    
    const setCurrentWallSetID = useGymStore((state) => state.setCurrentWallSetID)    
    const setCurrentWallID = useGymStore((state) => state.setCurrentWallID)    

    const { isLoading, error, data } = useQuery({
        queryKey: ['gym'],
        queryFn: async () => {
          const response = await axiosAPI.get('gym/' + id)

          if (response.data.userAuthorCheck.userIsAuthor) {
            setUserId(response.data.userAuthorCheck.userId)
            sessionStorage.setItem("userId", response.data.userAuthorCheck.userId)
          } else {
            setUserId('')
            sessionStorage.clear()
          }

          const loadedWallID = response.data.gym.newWallSet.id
          const wallIdx = response.data.gym.walls.findIndex((item) => item.id == loadedWallID)

          const loadedWallSetID = response.data.gym.walls[wallIdx].currentWallSetID
          setCurrentWallSetID(loadedWallSetID)
          const wallSetIdx = response.data.gym.walls[wallIdx].wallSets.findIndex((item) => item.id == loadedWallSetID)

          setCurrentWallModel({ modelURL: response.data.gym.walls[wallIdx].wallSets[wallSetIdx].wallSetURL,
                                geometry: response.data.gym.walls[wallIdx].wallSets[wallSetIdx].wallGeometry,
                                material: response.data.gym.walls[wallIdx].wallSets[wallSetIdx].wallMaterial })
          setCurrentWallID(response.data.gym.walls[wallIdx]._id)

          return response.data.gym
        },
        retry: 1
    })

    return { data, isLoading, error }
}

export default useQueryGym;