
export const createPointsOffBackendData = (Points, Point, PointMaterial, climbID, climbPoints, climbColor) => {
  return (
    <Points
      limit={1000} // Optional: max amount of items (for calculating buffer size)
      range={1000} // Optional: draw-range
      climbID={climbID}
    >
      <PointMaterial transparent vertexColors size={10} sizeAttenuation={false} depthWrite={false} />

        { climbPoints.map((point) => {
            return <Point 
                      position={point} 
                      color={climbColor} 
                      onClick={() => console.log('clicked me!')} 
                      onPointerOver={() => console.log('hovering me, king')}
                        />                    
        })}
      
      </Points>
  )
}