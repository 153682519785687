import { Link } from 'react-router-dom';

export const policyData = [
    {
        title: 'Terms of Service',
        lastUpdated: 'July 10, 2023',
        content: <>
                    <span className="text-black leading-7 [&:not(:first-child)]:mt-6">
                    When you use any beta-break products or services, you are agreeing to these latest Terms of 
                    Service (“Terms”). Violation of these terms may, at our discretion, result is us terminating 
                    your account. We may update these Terms of Service at any time. If we make significant changes, 
                    we will refresh the date at the top of this page and notify users who have signed up to our 
                    policy updates mailing list.
                    </span>
                    <h4 className="scroll-m-20 text-sm font-extrabold tracking-tight">
                        Definitions
                    </h4>
                    <div className="p-4">
                        <span className="text-black leading-7 [&:not(:first-child)]:mt-6">
                            “Company”, “we”, “our”, or “us” in any of our policies or terms, refers to beta-break.
                        </span>
                        <span className="text-black leading-7 [&:not(:first-child)]:mt-6">
                            “Services” refers to our website, beta-break.com, and any product created and maintained by beta-break 
                            whether delivered within a web browser, desktop application, mobile application, or another format.
                        </span>
                        <span className="text-black leading-7 [&:not(:first-child)]:mt-6">
                            Finally, “you” or “your” refers to the people or organizations that own an account with one or more of 
                            our services. We have a specific Account Ownership Policy for our products.
                        </span>
                    </div>
                    <span className="scroll-m-20 text-sm font-extrabold tracking-tight">
                        Account Terms
                    </span>
                    <div className="p-4">
                        <span className="text-black leading-7 [&:not(:first-child)]:mt-6">
                            <ol className="terms-list">
                                <li>
                                    You are responsible for maintaining the security of your account and password. The Company 
                                    cannot and will not be liable for any loss or damage from your failure to comply with this 
                                    security obligation. 
                                </li>
                                <li>
                                    You may not use the Services for any purpose outlined in our Use Restrictions Policy, and 
                                    you may not permit any of your users to do so, either.
                                </li>
                                <li>
                                    You are responsible for all content posted to and activity that occurs under your account, 
                                    including content posted by and activity of any users in your account.
                                </li>
                                <li>
                                    You must be a human. Accounts registered by “bots” or other automated methods are not permitted.
                                </li>
                            </ol>
                        </span>                                 
                    </div>
                    <span className="scroll-m-20 text-sm font-extrabold tracking-tight">
                        Payments, Refunds, and Plan Changes
                    </span>
                    <div className="p-4">
                        <span className="text-black leading-7 [&:not(:first-child)]:mt-6">
                            <ol className="terms-list">
                                <li>
                                    If you are using a free version of one of our Services, it is really free: we do not ask you 
                                    for your credit card or sell your data.
                                </li>
                                <li>
                                    If you are upgrading from a free plan to a paid plan, we will charge your card immediately and 
                                    your billing cycle starts on the day of upgrade. For other upgrades or downgrades in plan level, 
                                    the new rate starts from the next billing cycle.
                                </li>
                                <li>
                                    All fees are exclusive of all taxes, levies, or duties imposed by taxing authorities. Where 
                                    required, we will collect those taxes on behalf of the taxing authority and remit those taxes 
                                    to taxing authorities. See our Taxes Policy for more details. Otherwise, you are responsible 
                                    for payment of all taxes, levies, or duties.
                                </li>
                                <li>
                                    If you were just charged for the next month of service but you meant to cancel, we’re happy to refund that extra charge.
                                </li>    
                            </ol>
                        </span>                                 
                    </div>
                    <span className="scroll-m-20 text-sm font-extrabold tracking-tight">
                        Cancellation and Termination
                    </span>
                    <div className="p-4">
                        <span className="text-black leading-7 [&:not(:first-child)]:mt-6">
                            <ol className="terms-list">
                                <li>
                                    You are solely responsible for properly canceling your account. You can find instructions for 
                                    how to cancel your account in our Cancellation Policy. An email or phone request to cancel your 
                                    account is not automatically considered cancellation. If you need help canceling your account, 
                                    you can always contact our Support team at support@beta-break.com.                                
                                </li>
                                <li>
                                    All of your content will be inaccessible from the Services immediately upon account cancellation. 
                                    Within 30 days, all content will be permanently deleted from active systems and logs. Within 60 
                                    days, all content will be permanently deleted from our backups. We cannot recover this information 
                                    once it has been permanently deleted.
                                </li>
                                <li>
                                    If you cancel the Service before the end of your current paid up month, your cancellation will take 
                                    effect immediately, and you will not be charged again. We do not automatically prorate unused time 
                                    in the last billing cycle.
                                </li>
                                <li>
                                    We reserve the right to suspend or terminate your account and refuse any and all current or future 
                                    use of our Services for any reason at any time. Suspension means you and any other users on your 
                                    account will not be able to access the account or any content in the account. Termination will 
                                    furthermore result in the deletion of your account or your access to your account, and the deletion 
                                    of all content in your account. We also reserve the right to refuse the use of the Services to 
                                    anyone for any reason at any time, including, but not limited to violations of our Use Restrictions 
                                    Policy.
                                </li>
                                <li>
                                    Verbal, physical, written or other abuse (including threats of abuse or retribution) of a Company 
                                    employee or officer will result in immediate account termination.
                                </li>    
                            </ol>
                        </span>                                 
                    </div>
                    <span className="scroll-m-20 text-sm font-extrabold tracking-tight">
                        Modifications to the Service and Prices
                    </span>
                    <div className="p-4">
                        <span className="text-black leading-7 [&:not(:first-child)]:mt-6">
                            <ol className="terms-list">
                                <li>
                                    We intend to support our Services as far as it is possible and reasonable to do so. That means when 
                                    it comes to security, privacy, and customer support, we will continue to maintain any legacy Services. 
                                    Sometimes it becomes technically impossible to continue a feature or we redesign a part of our 
                                    Services because we think it could be better or we decide to close new signups of a product. We 
                                    reserve the right at any time to modify or discontinue, temporarily or permanently, any part of our 
                                    Services with or without notice.                            
                                </li>
                                <li>
                                    Sometimes we change the pricing structure for our products. When we do that, we tend to exempt existing 
                                    customers from those changes. However, we may choose to change the prices for existing customers. If 
                                    we do so, we will give at least 30 days notice and will notify you via the email address on record. We 
                                    may also post a notice about changes on our websites or the affected Services themselves.
                                </li>  
                            </ol>
                        </span>                                 
                    </div>
                    <span className="scroll-m-20 text-sm font-extrabold tracking-tight">
                        Uptime, Security, and Privacy
                    </span>
                    <div className="p-4">
                        <span className="text-black leading-7 [&:not(:first-child)]:mt-6">
                            <ol className="terms-list">
                                <li>
                                    Your use of the Services is at your sole risk. We provide these Services on an “as is” and “as available” 
                                    basis. We do not offer service-level agreements, but do take uptime of our applications seriously.                       
                                </li>
                                <li>
                                    We reserve the right to temporarily disable your account if your usage significantly exceeds the average 
                                    usage of other customers of the Services. We’ll reach out to the account owner before taking any action 
                                    except in rare cases where the level of use may negatively impact the performance of the Service for other 
                                    customers.
                                </li>  
                                <li>
                                    We take many measures to protect and secure your data through backups, redundancies, and encryption. We 
                                    enforce encryption for data transmission from the public Internet. There are some edge cases where we may 
                                    send your data through our network unencrypted. Please refer to our Security Overview for full details and 
                                    our Security Response page for how to report a security incident or threat.
                                </li>
                                <li>
                                    You agree that beta-break may process your data as described in our Privacy Policy and for no other purpose. 
                                    On rare occasion, our staff may access your data for the following reasons:
                                </li>
                                <ul className="terms-sub-list">
                                    <li>
                                        To help you with support requests you make. We’ll ask for express consent before accessing your account.
                                    </li>
                                    <li>
                                        On the rare occasions when an error occurs that stops an automated process partway through. We get automated 
                                        alerts when such errors occur. When we can fix the issue and restart automated processing without looking at 
                                        any personal data, we do. In rare cases, we have to look at a minimum amount of personal data to fix the issue.
                                    </li>
                                    <li>
                                        To safeguard beta-break. We’ll look at logs and metadata as part of our work to ensure the security of your 
                                        data and the Services as a whole. If necessary, we may also access accounts as part of an abuse report 
                                        investigation.
                                    </li>
                                    <li>
                                        To the extent required by applicable law. As a Canadian company with its main data infrastructure located in 
                                        Canada, we only preserve or share customer data if compelled by a Canadian government authority with a legally 
                                        binding order, or in limited circumstances in the event of an emergency request. If a non-Canadian authority 
                                        approaches beta-break for assistance, our default stance is to refuse unless the order has been approved by the
                                        Canadian government, which compels us to comply through procedures outlined in an established mutual legal 
                                        assistance treaty or agreement mechanism. If beta-break is audited by a tax authority, we only share the bare 
                                        minimum billing information needed to complete the audit.
                                    </li>
                                </ul>
                                <li>
                                    We use third party vendors and hosting partners to provide the necessary hardware, software, networking, storage, 
                                    and related technology required to run the Services.                      
                                </li>
                                <li>
                                    beta-break is a “service provider”, not a “business” or “third party”, with respect to your use of the Services. 
                                    That means we process any data you share with us only for the purpose you signed up for and as described in these 
                                    Terms, the Privacy Policy, and other policies. We do not retain, use, disclose, or sell any of that information for 
                                    any other commercial purposes unless we have your explicit permission. Similarly, you agree to comply with and not 
                                    use Basecamp’s Services in a way that violates the terms of service.                
                                </li>
                                <li>
                                    Your use of the Services is at your sole risk. We provide these Services on an “as is” and “as available” 
                                    basis. We do not offer service-level agreements, but do take uptime of our applications seriously.                       
                                </li>
                            </ol>
                        </span>                                 
                    </div>
                    <span className="scroll-m-20 text-sm font-extrabold tracking-tight">
                        Copyright and Content Ownership
                    </span>
                    <div className="p-4">
                        <span className="text-black leading-7 [&:not(:first-child)]:mt-6">
                            <ol className="terms-list">
                                <li>
                                    All content posted on the Services must comply with Canadian copyright law. We provide details on how to 
                                    file a copyright infringement claim.               
                                </li>
                                <li>
                                    You give us a limited license to use the content posted by you and your users in order to provide the 
                                    Services to you, but we claim no ownership rights over those materials. All materials you submit to the 
                                    Services remain yours.
                                </li>  
                                <li>
                                    We do not pre-screen content, but we reserve the right (but not the obligation) in our sole discretion to 
                                    refuse or remove any content that is available via the Service.
                                </li>
                                <li>
                                    The Company or its licensors own all right, title, and interest in and to the Services, including all 
                                    intellectual property rights therein, and you obtain no ownership rights in the Services as a result of 
                                    your use. You may not duplicate, copy, or reuse any portion of the HTML, CSS, JavaScript, or visual design 
                                    elements without express written permission from the Company. You must request permission to use the 
                                    Company’s logos or any Service logos for promotional purposes. Please email us requests to use logos. We 
                                    reserve the right to rescind any permissions if you violate these Terms.
                                </li>                                
                                <li>
                                    You agree not to reproduce, duplicate, copy, sell, resell or exploit any portion of the Services, use of 
                                    the Services, or access to the Services without the express written permission of the Company.                    
                                </li>                                
                            </ol>
                        </span>                                 
                    </div>
                    <span className="scroll-m-20 text-sm font-extrabold tracking-tight">
                        Liability
                    </span>
                    <div className="p-4">
                        <span className="text-black leading-7 [&:not(:first-child)]:mt-6">
                            You agree that the Company is not liable to you or to any third party for damages of any kind that result from 
                            the use of the Services, in ability to access data, or unauthorized access of your data or account. The Company 
                            is also not liable for damages of any kind related to actions of any third party that uses the Services, or 
                            any other consequences related to the Terms or Services.                                                                                                 
                        </span>     
                        <span className="text-black leading-7 [&:not(:first-child)]:mt-6">
                            If you have a question about any of these Terms, please contact our Support team at support@beta-break.com.                            
                        </span>
                        <span className="text-black leading-7 [&:not(:first-child)]:mt-6">
                            Adapted from the <a className="link-basic" href="https://github.com/basecamp/policies">
                                Basecamp open-source policies
                            </a> / <a className="link-basic" href="https://creativecommons.org/licenses/by/4.0/">
                                CC BY 4.0
                            </a>
                        </span>  
                    </div>
                </>
    },
    {
        title: 'Privacy Policy', 
        lastUpdated: 'July 10, 2023',      
        content: <>
                    <span className="text-black leading-7 [&:not(:first-child)]:mt-6">
                        In this policy, we lay out: what data we collect and why; how your data is handled; 
                        and your rights with respect to your data. We never sell your data.
                    </span>
                    <span className="text-black leading-7 [&:not(:first-child)]:mt-6">
                        This policy applies to all products built and maintained by beta-break.
                    </span>
                    <span className="text-black leading-7 [&:not(:first-child)]:mt-6">
                        This policy applies to our handling of information about site visitors, prospective 
                        customers, and customers and authorized users (in relation to their procurement of 
                        the services and management of their relationship with beta-break). We refer 
                        collectively to these categories of individuals as “you” throughout this policy.
                    </span>
                    <h4 className="scroll-m-20 text-sm font-extrabold tracking-tight">
                        What we collect and why
                    </h4>
                    <div className="p-4">
                        <span className="text-black leading-7 [&:not(:first-child)]:mt-6">
                            Our guiding principle is to collect only what we need. Here's what that means in practice:
                        </span>
                        <div className="p-4">
                        <h4 className="scroll-m-20 text-sm font-extrabold tracking-tight">
                            Identity and Access
                        </h4>
                        <span className="text-black leading-7 [&:not(:first-child)]:mt-6">
                            When you sign up for a beta-break product, we ask for identifying information such as your 
                            name and email address. That’s so you can personalize your new account, and we can send you 
                            other essential information. We may also send you optional surveys from time to time to help 
                            us understand how you use our products and to make improvements. With your consent, we will
                            send you our newsletter and other updates. We sometimes also give you the option to add a 
                            profile picture that displays in our products.
                        </span>
                        <span className="text-black leading-7 [&:not(:first-child)]:mt-6">
                            We’ll never sell your personal information to third parties, and we won’t use your name or
                            email in marketing statements without your permission either.
                        </span>
                        <h4 className="scroll-m-20 text-sm font-extrabold tracking-tight">
                            Billing Information
                        </h4>
                        <span className="text-black leading-7 [&:not(:first-child)]:mt-6">
                            If you sign up for a paid beta-break product, you will be asked to provide your payment 
                            information and billing address. Credit card information is submitted directly to our payment 
                            processor and doesn’t hit beta-break servers. We store a record of the payment transaction, 
                            including the last 4 digits of the credit card number, for purposes of account history, invoicing, 
                            and billing support. We store your billing address so we can charge you for service, calculate any 
                            sales tax due, send you invoices, and detect fraudulent credit card transactions. We occasionally 
                            use aggregate billing information to guide our marketing efforts.
                        </span>
                        <h4 className="scroll-m-20 text-sm font-extrabold tracking-tight">
                            Product Interactions
                        </h4>
                        <span className="text-black leading-7 [&:not(:first-child)]:mt-6">
                            We store on our servers the content that you receive or maintain in your beta-break product accounts. 
                            This is so you can use our products as intended, for example, to create climbs, spraywalls, or a gym 
                            in beta-break. We keep this content as long as your account is active. If you delete your account, 
                            we’ll delete the content immediately.
                        </span>
                        <h4 className="scroll-m-20 text-sm font-extrabold tracking-tight">
                            General Geolocation data
                        </h4>
                        <span className="text-black leading-7 [&:not(:first-child)]:mt-6">
                            For our products, we log the full IP address used to sign up a product account and retain that for use 
                            in mitigating future spammy signups. We also log all account access by full IP address for security and 
                            fraud prevention purposes, and we keep this login data for as long as your product account is active.
                        </span>
                        <h4 className="scroll-m-20 text-sm font-extrabold tracking-tight">
                            Anti-bot assessments
                        </h4>
                        <span className="text-black leading-7 [&:not(:first-child)]:mt-6">
                            We may use CAPTCHA across beta-break to mitigate brute force logins and as a means of spam protection. 
                            We have a legitimate interest in protecting our app and the broader Internet community from credential 
                            stuffing attacks and spam. When you log into your beta-break accounts and when you fill in certain forms 
                            the CAPTCHA service evaluates various information (e.g., IP address, how long the visitor has been on the 
                            app, mouse movements) to try to detect if the activity is from an automated program instead of a human. 
                            The CAPTCHA service then provides beta-break with the spam score results; we do not have access to the 
                            evaluated information.
                        </span>
                        <h4 className="scroll-m-20 text-sm font-extrabold tracking-tight">
                            Voluntary Correspondence
                        </h4>
                        <span className="text-black leading-7 [&:not(:first-child)]:mt-6">
                            When you email beta-break with a question or to ask for help, we keep that correspondence, including your 
                            email address, so that we have a history of past correspondence to reference if you reach out in the future.
                        </span>
                        <span className="text-black leading-7 [&:not(:first-child)]:mt-6">
                            We also store information you may volunteer, for example, written responses to surveys. If you agree to a 
                            customer interview, we may ask for your permission to record the conversation for future reference or use. 
                            We will only do so with your express consent.
                        </span>
                    </div>
                    </div>
                    <span className="scroll-m-20 text-sm font-extrabold tracking-tight">
                        When we access or disclose your information
                    </span>
                    <div className="p-4">
                        <span className="text-black leading-7 [&:not(:first-child)]:mt-6">
                            To provide products or services you’ve requested. We use some third-party subprocessors to help run our 
                            applications and provide the Services to you. The following is a list of personal data subprocessors we use,
                            <ul className="terms-list">
                                <li className="list-item-spacer"><a href="https://www.digitalocean.com/legal/gdpr-faq" className="nav-menu-button">Digital Ocean</a> - Cloud Service Provider</li>                        
                                <li className="list-item-spacer"><a href="https://www.mongodb.com/legal/privacy" className="nav-menu-button">MongoDB</a> - Cloud Database Provider</li>
                                <li className="list-item-spacer"><a href="https://stripe.com/en-ca/legal/privacy-center" className="nav-menu-button">Stripe</a> - Payment Processor</li>
                            </ul>
                        </span>
                        <span className="text-black leading-7 [&:not(:first-child)]:mt-6">
                            No beta-break human looks at your content except for limited purposes with your express permission, for example, 
                            if an error occurs that stops an automated process from working and requires manual intervention to fix. These are 
                            rare cases, and when they happen, we look for root cause solutions as much as possible to avoid them recurring. We 
                            may also access your data if required in order to respond to legal process (see “When required under applicable law” 
                            below).
                        </span>
                        <span className="text-black leading-7 [&:not(:first-child)]:mt-6">
                            To help you troubleshoot or squash a software bug, with your permission. If at any point we need to access your content 
                            to help you with a support case, we will ask for your consent before proceeding.
                        </span>
                        <span className="text-black leading-7 [&:not(:first-child)]:mt-6">
                            To investigate, prevent, or take action regarding restricted uses. Accessing a customer’s account when investigating 
                            potential abuse is a measure of last resort. We want to protect the privacy and safety of both our customers and the 
                            people reporting issues to us, and we do our best to balance those responsibilities throughout the process. If we 
                            discover you are using our products for a restricted purpose, we will take action as necessary, including notifying 
                            appropriate authorities where warranted.
                        </span>
                        <span className="text-black leading-7 [&:not(:first-child)]:mt-6">
                            Aggregated and de-identified data. We may aggregate and/or de-identify information collected through the services. 
                            We may use de-identified or aggregated data for any purpose, including marketing or analytics.
                        </span>
                        <span className="text-black leading-7 [&:not(:first-child)]:mt-6">
                            When required under applicable law. beta-break is a Canadian company with its main data infrastructure located in Canada
                            and the U.S.
                        </span>
                        <div className="p-4">
                            <span className="text-black leading-7 [&:not(:first-child)]:mt-6">
                                Requests for user data. Our policy is to not respond to government requests for user data unless we are compelled by 
                                legal process or in limited circumstances in the event of an emergency request. However, if Canadian law enforcement 
                                authorities have the necessary warrant, criminal subpoena, or court order requiring us to disclose data, we must comply. 
                                Likewise, we will only respond to requests from government authorities outside Canada if compelled by the Canadian 
                                government through procedures outlined in a mutual legal assistance treaty or agreement. It is beta-break's policy to 
                                notify affected users before we disclose data unless we are legally prohibited from doing so, and except in some 
                                emergency cases.
                            </span>
                            <span className="text-black leading-7 [&:not(:first-child)]:mt-6">
                                Preservation requests. Similarly, beta-break's policy is to comply with requests to preserve data only if compelled by 
                                Canadian data retention acts, or by a properly served Canada subpoena for civil matters. We do not disclose preserved 
                                data unless required by law or compelled by a court order that we choose not to appeal. Furthermore, unless we receive 
                                a proper warrant, court order, or subpoena before the required preservation period expires, we will destroy any 
                                preserved copies of customer data at the end of the preservation period.
                            </span>
                            <span className="text-black leading-7 [&:not(:first-child)]:mt-6">
                                If we are audited by a tax authority, we may be required to disclose billing-related information. If that happens, 
                                we will disclose only the minimum needed, such as billing addresses and tax exemption information.
                            </span>
                        </div>
                        <span className="text-black leading-7 [&:not(:first-child)]:mt-6">
                            Finally, if beta-break is acquired by or merges with another company — we don’t plan on that, but if it happens — we’ll 
                            notify you well before any of your personal information is transferred or becomes subject to a different privacy policy.
                        </span>
                    </div>
                    <span className="scroll-m-20 text-sm font-extrabold tracking-tight">
                        Your rights with respect to your information
                    </span>
                    <div className="p-4">
                        <span className="text-black leading-7 [&:not(:first-child)]:mt-6">
                            At beta-break, we strive to apply the same data rights to all customers, regardless of their location. Some of these rights include:
                        </span>                    
                        <div className="p-4">
                            <span className="text-black leading-7 [&:not(:first-child)]:mt-6">
                                Right to Know. You have the right to know what personal information is collected, used, shared or sold. We outline both 
                                the categories and specific bits of data we collect, as well as how they are used, in this privacy policy.
                            </span>
                            <span className="text-black leading-7 [&:not(:first-child)]:mt-6">
                                Right of Access. This includes your right to access the personal information we gather about you, and your right to 
                                obtain information about the sharing, storage, security and processing of that information.
                            </span>
                            <span className="text-black leading-7 [&:not(:first-child)]:mt-6">
                                Right to Correction. You have the right to request correction of your personal information.
                            </span>
                            <span className="text-black leading-7 [&:not(:first-child)]:mt-6">
                                Right to Erasure / “To Be Forgotten”. This is your right to request, subject to certain limitations under applicable law, 
                                that your personal information be erased from our possession and, by extension, from all of our service providers. Fulfillment 
                                of some data deletion requests may prevent you from using beta-break services because our applications may then no longer 
                                work. In such cases, a data deletion request may result in closing your account.
                            </span>
                            <span className="text-black leading-7 [&:not(:first-child)]:mt-6">
                                Right to Complain. You have the right to make a complaint regarding our handling of your personal information with the 
                                appropriate supervisory authority.
                            </span>
                            <span className="text-black leading-7 [&:not(:first-child)]:mt-6">
                                Right to Restrict Processing. This is your right to request restriction of how and why your personal information is used 
                                or processed, including opting out of sale of your personal information. (Again: we never have and never will sell your 
                                personal data.)
                            </span>
                            <span className="text-black leading-7 [&:not(:first-child)]:mt-6">
                                Right to Object. You have the right, in certain situations, to object to how or why your personal information is processed.
                            </span>
                            <span className="text-black leading-7 [&:not(:first-child)]:mt-6">
                                Right to not Be Subject to Automated Decision-Making. You have the right to object to and prevent any decision that could 
                                have a legal or similarly significant effect on you from being made solely based on automated processes. This right is 
                                limited if the decision is necessary for performance of any contract between you and us, is allowed by applicable law, or 
                                is based on your explicit consent.
                            </span>
                            <span className="text-black leading-7 [&:not(:first-child)]:mt-6">
                                Right to Non-Discrimination. We do not and will not charge you a different amount to use our products, offer you different 
                                discounts, or give you a lower level of customer service because you have exercised your data privacy rights. However, the 
                                exercise of certain rights may, by virtue of your exercising those rights, prevent you from using our Services.
                            </span>
                        </div>
                        <span className="text-black leading-7 [&:not(:first-child)]:mt-6">
                            Many of these rights can be exercised by signing in and updating your account information. Please note that certain information 
                            may be exempt from such requests under applicable law. For example, we need to retain certain information in order to provide our 
                            services to you.
                        </span>
                        <span className="text-black leading-7 [&:not(:first-child)]:mt-6">
                            In some cases, we also need to take reasonable steps to verify your identity before responding to a request, which may include, 
                            at a minimum, depending on the sensitivity of the information you are requesting and the type of request you are making, verifying 
                            your name and email address. If we are unable to verify you, we may be unable to respond to your requests.
                        </span>
                        <span className="text-black leading-7 [&:not(:first-child)]:mt-6">
                            Many of these rights can be exercised by signing in and updating your account information. Please note that certain information 
                            may be exempt from such requests under applicable law. For example, we need to retain certain information in order to provide our 
                            services to you.
                        </span>
                    </div>
                    <span className="scroll-m-20 text-sm font-extrabold tracking-tight">
                        How we secure your data
                    </span>
                    <div className="p-4">
                        <span className="text-black leading-7 [&:not(:first-child)]:mt-6">
                            All data is encrypted via SSL/TLS when transmitted from our servers to your browser. The database backups are also encrypted. In 
                            addition, we go to great lengths to secure your data at rest. For more information about how we keep your information secure, please 
                            review our Security Overview.
                        </span>                                   
                    </div>
                    <span className="scroll-m-20 text-sm font-extrabold tracking-tight">
                        What happens when you delete content in your user account
                    </span>
                    <div className="p-4">
                        <span className="text-black leading-7 [&:not(:first-child)]:mt-6">
                            If you choose to cancel your account, your content will become immediately inaccessible and should be purged from our systems immediately. 
                            This applies both for cases when an account owner directly cancels and for auto-canceled accounts. Please refer to our Cancellation Policy 
                            for more details.
                        </span>                                   
                    </div>
                    <span className="scroll-m-20 text-sm font-extrabold tracking-tight">
                        Data retention
                    </span>
                    <div className="p-4">
                        <span className="text-black leading-7 [&:not(:first-child)]:mt-6">
                            We keep your information for the time necessary for the purposes for which it is processed. The length of time for which we retain 
                            information depends on the purposes for which we collected and use it and your choices, after which time we will delete it.
                        </span>                                   
                    </div>
                    <span className="scroll-m-20 text-sm font-extrabold tracking-tight">
                        Location of site and data
                    </span>
                    <div className="p-4">
                        <span className="text-black leading-7 [&:not(:first-child)]:mt-6">
                            Our products and other web properties are primarily housed in the Canada and the U.S. If you are located in the European Union, UK, 
                            or elsewhere outside of Canada or the U.S., please be aware that any information you provide to us will be transferred to and stored 
                            in Canada or the U.S.. By using our websites or Services and/or providing us with your personal information, you consent to this transfer.
                        </span>                                   
                    </div>    
                    <span className="scroll-m-20 text-sm font-extrabold tracking-tight">
                        Changes and questions
                    </span>
                    <div className="p-4">
                        <span className="text-black leading-7 [&:not(:first-child)]:mt-6">
                            We may update this policy as needed to comply with relevant regulations and reflect any new practices. If we make significant changes, 
                            we will refresh the date at the top of this page and notify users who have signed up to our policy updates mailing list.
                        </span> 
                        <span className="text-black leading-7 [&:not(:first-child)]:mt-6">
                            Have any questions, comments, or concerns about this privacy policy, your data, or your rights with respect to your information? Please 
                            get in touch by emailing us at support@beta-break.com. 
                        </span>                                   
                    </div>    
                    <span className="text-black leading-7 [&:not(:first-child)]:mt-6">
                        Adapted from the <a className="link-basic" href="https://github.com/basecamp/policies">
                            Basecamp open-source policies
                        </a> / <a className="link-basic" href="https://creativecommons.org/licenses/by/4.0/">
                            CC BY 4.0
                        </a>
                    </span>             
                </>
    },
    {
        title: 'Cancellation Policy',   
        lastUpdated: 'July 10, 2023',     
        content: <>
                    <span className="text-black leading-7 [&:not(:first-child)]:mt-6">
                    We want satisfied customers, not hostages. That’s why we make it easy for you to cancel your account directly in all of our products.
                    </span>                            
                    <span className="scroll-m-20 text-sm font-extrabold tracking-tight">
                        Delete your account
                    </span>
                    <div className="p-4">
                        <span className="text-black leading-7 [&:not(:first-child)]:mt-6">
                            <Link to={{ pathname: "/profile"}} className="blog-signup-btn">Profile</Link>
                        </span>
                        <span className="text-black leading-7 [&:not(:first-child)]:mt-6">
                            Click the "Delete your account" button, fill in the text prompt with your username, and submit your account deletion action.
                        </span>
                        <span className="text-black leading-7 [&:not(:first-child)]:mt-6">
                            Our legal responsibility is to account owners, which means we cannot cancel an account at the request of anyone else. If you 
                            no longer know who the account owner is, contact us, and we’ll reach out to any current account owners at the email addresses 
                            we have on file.
                        </span>                
                    </div>
                    <span className="scroll-m-20 text-sm font-extrabold tracking-tight">
                        What happens when you delete your account?
                    </span>
                    <div className="p-4">                    
                        <span className="text-black leading-7 [&:not(:first-child)]:mt-6">
                            You won’t be able to access your account once you delete. If you have a paid beta-break account, you can cancel your subscription 
                            using the Stripe portal under,                            
                        </span>
                        <span className="text-black leading-7 [&:not(:first-child)]:mt-6">
                            <Link to={{ pathname: "/profile"}} className="blog-signup-btn">Profile</Link>
                        </span>
                        <span className="leading-7 [&:not(:first-child)]:mt-6">                            
                            and keep using your account until your paid period expires. At that point you may also delete your account and it will become 
                            inaccessible. You can also choose to fully delete your account and your subscription plan through Stripe will automatically be 
                            cancelled. Upon deletion of your account, we will also send you a follow-up email with a link to manage your Stripe customer details
                            with beta-break to ensure your subscription is cancelled.
                        </span>                        
                        <span className="text-black leading-7 [&:not(:first-child)]:mt-6">
                            We won’t bill you again once you cancel. We don’t automatically prorate any unused time you may have left but if you haven’t 
                            used your account in months or just started a new billing cycle, contact us for a fair refund. We’ll treat you right.
                        </span>                
                    </div>
                    <span className="scroll-m-20 text-sm font-extrabold tracking-tight">
                        beta-break initiated cancellations
                    </span>
                    <div className="p-4">                    
                        <span className="text-black leading-7 [&:not(:first-child)]:mt-6">
                            We may cancel accounts if they have been inactive for an extended period:                           
                        </span> 
                        <div className="p-4">  
                            <span className="leading-7 [&:not(:first-child)]:mt-6">                  
                                <ul className="terms-list">
                                    <li>For free accounts: after 365 days of inactivity</li>
                                </ul>
                            </span>
                        </div>              
                    </div>
                    <span className="text-black leading-7 [&:not(:first-child)]:mt-6">
                        We also retain the right to suspend or terminate accounts for any reason at any time, as outlined in our Terms of Service. In practice, 
                        this generally means we will cancel your account without notice if we have evidence that you are violating the restricted use agreement.
                    </span>
                    <span className="text-black leading-7 [&:not(:first-child)]:mt-6">
                        Adapted from the <a className="link-basic" href="https://github.com/basecamp/policies">
                            Basecamp open-source policies
                        </a> / <a className="link-basic" href="https://creativecommons.org/licenses/by/4.0/">
                            CC BY 4.0
                        </a>
                    </span>  
                </>
    },
    {
    title: 'Use Restrictions Policy',
    lastUpdated: 'July 10, 2023',
    content: <>            
                <span className="scroll-m-20 text-sm font-extrabold tracking-tight">
                    Restricted Purposes
                </span>
                <div className="p-4">
                    <span className="text-black leading-7 [&:not(:first-child)]:mt-6">
                        When you use any of beta-break's services, you agree not to,
                    </span>
                    <span className="text-black leading-7 [&:not(:first-child)]:mt-6">
                        <ul className="terms-list">
                            <li>
                                Use the products to break any laws or regulations.
                            </li>
                            <li>
                                Gather or use information from accounts that aren’t yours.
                            </li>
                            <li>
                                Attempt to deceive us or other users by making false reports or pretending to be someone else.
                            </li>
                            <li>
                                Bypass or interfere with the security features of the products.
                            </li>
                            <li>
                                Overload or disrupt any configuration or operations of the products.
                            </li>
                            <li>
                                Send or try to send viruses or any malware, or add tracking tools like web bugs and cookies to the products.
                            </li>
                            <li>
                                Harass or threaten other users, or any of our employees.
                            </li>
                            <li>
                                Speak ill of or damage, in our view, us or the products.
                            </li>
                        </ul>
                    </span>
                    <span className="text-black leading-7 [&:not(:first-child)]:mt-6">
                        In addition to the above, we reserve the right to suspend or cancel any account, at any time, without notice.
                    </span>                
                </div>
                <span className="scroll-m-20 text-sm font-extrabold tracking-tight">
                    How to report abuse
                </span>
                <div className="p-4">
                    <span className="text-black leading-7 [&:not(:first-child)]:mt-6">
                        Report violations by emailing support@beta-break.com with details about the account and the issue, including 
                        URLs or screenshots. If you need to send files securely, ask us for a link. We keep your identity confidential. For 
                        copyright issues, see our instructions on how to notify us about infringement claims.
                    </span>                                   
                </div>
                <span className="leading-7 [&:not(:first-child)]:mt-6">
                    Adapted from the <a className="link-basic" href="https://github.com/basecamp/policies">
                        Basecamp open-source policies
                    </a> / <a className="link-basic" href="https://creativecommons.org/licenses/by/4.0/">
                        CC BY 4.0
                    </a>
                </span>  
            </>
    },
    {
        title: 'Security Overview', 
        lastUpdated: 'July 10, 2023',
        content: <>
                    <span className="scroll-m-20 text-sm font-extrabold tracking-tight">
                        We protect your data
                    </span>
                    <div className="p-4">
                        <span className="text-black leading-7 [&:not(:first-child)]:mt-6">
                            All data are written to multiple disks instantly, backed up weekly, and stored in multiple locations.
                        </span>                                   
                    </div>
                    <span className="scroll-m-20 text-sm font-extrabold tracking-tight">
                        Your data is sent using HTTPS
                    </span>
                    <div className="p-4">
                        <span className="text-black leading-7 [&:not(:first-child)]:mt-6">
                            Whenever your data are in transit between you and us, everything is encrypted, and sent using HTTPS. 
                            Within our firewalled private networks, data may be transferred unencrypted.
                        </span>                                   
                    </div>
                    <span className="scroll-m-20 text-sm font-extrabold tracking-tight">
                        We protect your billing information
                    </span>
                    <div className="p-4">
                        <span className="text-black leading-7 [&:not(:first-child)]:mt-6">
                            All credit card transactions are processed using secure encryption—the same level of encryption used 
                            by leading banks. Card information is transmitted, stored, and processed securely on a PCI-Compliant 
                            network.
                        </span>                                   
                    </div>     
                    <span className="scroll-m-20 text-sm font-extrabold tracking-tight">
                        Have a concern? Need to report an incident?
                    </span>
                    <div className="p-4">
                        <span className="text-black leading-7 [&:not(:first-child)]:mt-6">
                            Have you noticed abuse, misuse, an exploit, or experienced an incident with your account or beta-break services?
                            Please email us at support@beta-break.com to securely submit a report.
                        </span>                                   
                    </div> 
                    <span className="text-black leading-7 [&:not(:first-child)]:mt-6">
                        Adapted from the <a className="link-basic" href="https://github.com/basecamp/policies">
                            Basecamp open-source policies
                        </a> / <a className="link-basic" href="https://creativecommons.org/licenses/by/4.0/">
                            CC BY 4.0
                        </a>
                    </span>                
                </>
    },
    {
        title: 'Taxes on Services', 
        lastUpdated: 'July 10, 2023',
        content: <>
                    <span className="text-black leading-7 [&:not(:first-child)]:mt-6">
                        Where our products are taxable, we collect and remit consumption tax to governments at the local, state, and/or federal 
                        levels. If your billing address is in one of the following jurisdictions, you’ll see an additional line item on your 
                        invoice for sales tax or VAT. The subscription prices on our product websites are all exclusive of sales tax and VAT.
                    </span>  
                    <span className="text-black leading-7 [&:not(:first-child)]:mt-6">
                        The tax rate and rules are determined by each jurisdiction and sometimes vary by whether you are using our products for 
                        business/commercial or personal purposes. As regulations change, we will update this list.
                    </span>  
                    <span className="text-black leading-7 [&:not(:first-child)]:mt-6">
                        Jurisdictions we collect and remit taxes for:
                    </span>    
                    <span className="scroll-m-20 text-sm font-extrabold tracking-tight">
                        Within the Canada & the U.S.
                    </span>
                    <div className="p-4">
                        <span className="text-black leading-7 [&:not(:first-child)]:mt-6">
                            We collect relevant sales tax from beta-break subscription plans. Stripe, our payment processor, will
                            calculate relevant sales tax values on checkout based off your province/state.
                        </span>                                   
                    </div>
                    <span className="scroll-m-20 text-sm font-extrabold tracking-tight">
                        Within the EU and U.K.
                    </span>
                    <div className="p-4">
                        <span className="text-black leading-7 [&:not(:first-child)]:mt-6">
                            We collect VAT on any direct-to-consumer sales to EU-based customers. If you are a customer with a VAT 
                            identification number, let us know and we will apply a reverse charge for VAT to your invoice.
                        </span>                                   
                    </div>
                    <span className="scroll-m-20 text-sm font-extrabold tracking-tight">
                        Updating your billing address
                    </span>
                    <div className="p-4">
                        <span className="text-black leading-7 [&:not(:first-child)]:mt-6">
                            If you need to change your billing information because you don’t actually reside in a taxable jurisdiction, 
                            you can easily handle that within your linked Stripe portal account. You may need to re-enter your credit 
                            card information as part of the billing address update. To update your billing information navigate to your
                            profile and select the "Stripe Portal" button to access your Stripe payment details,
                        </span>     
                        <span className="text-black leading-7 [&:not(:first-child)]:mt-6">
                            <Link to={{ pathname: "/profile"}} className="blog-signup-btn">Profile</Link>
                        </span>                              
                    </div>
                    <span className="scroll-m-20 text-sm font-extrabold tracking-tight">
                        We're here to help
                    </span>
                    <div className="p-4">
                        <span className="text-black leading-7 [&:not(:first-child)]:mt-6">
                            If you run into any issues please contact our support team at support@beta-break.com!
                        </span>                                                     
                    </div>
                    <span className="text-black leading-7 [&:not(:first-child)]:mt-6">
                        Adapted from the <a className="link-basic" href="https://github.com/basecamp/policies">
                            Basecamp open-source policies
                        </a> / <a className="link-basic" href="https://creativecommons.org/licenses/by/4.0/">
                            CC BY 4.0
                        </a>
                    </span>  
                </>
    },
    {
        title: 'Account Ownership Policy', 
        lastUpdated: 'July 10, 2023',
        content: <>                
                    <span className="text-black leading-7 [&:not(:first-child)]:mt-6">
                        Accounts are owned by the organization detailed during signup, if this is a legal entity. If the organization 
                        is unincorporated, the account is owned by the individual who signed up for the account.
                    </span>   
                    <span className="text-black leading-7 [&:not(:first-child)]:mt-6">
                        When you sign up and create a beta-break account, the person who originally signed up for the account is default 
                        designated the primary Account Owner or Manager, but the role can be reassigned (contact support@beta-break.com 
                        for help). Unless the organization is not a legal entity, the account and data held it in, is ultimately owned by the 
                        organization.
                    </span> 
                    <span className="text-black leading-7 [&:not(:first-child)]:mt-6">
                        Adapted from the <a className="link-basic" href="https://github.com/basecamp/policies">
                            Basecamp open-source policies
                        </a> / <a className="link-basic" href="https://creativecommons.org/licenses/by/4.0/">
                            CC BY 4.0
                        </a>
                    </span>                                
                </>
    }
]