import { useQuery } from '@tanstack/react-query'
import { axiosAPI } from '../axiosAPI.js'

const useQueryUserList = () => {  
    const { isLoading, error, data } = useQuery({
        queryKey: ['user-list'],
        queryFn: async () => {
          const response = await axiosAPI.get('user-list')

          return response.data
        },
        retry: 1
    })

    return { data, isLoading, error }
}

export default useQueryUserList;