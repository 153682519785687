import { create } from 'zustand'
import _ from 'lodash'
import { useCoordinatesStore } from './useCoordinatesStore.js'
import { v4 as uuidv4 } from 'uuid'

export const useBorderPointsStore = create((set) => ({
    currentPoints: [],
    setCurrentPoints: (data) => set((state) => ({currentPoints: data})),
    selectedUHID: null,
    setSelectedUHID: (data) => set((state) => ({setSelectedUHID: data})),
    renderBorderPoint: (position, color, uhid, highlightMode) => {
        return [position[0], position[1], position[2]]
    }
}))

export const setupStartClimbBorder = () => {
    const mouseCoords = useCoordinatesStore.getState().mouseCoords
    const setCurrentPoints = useBorderPointsStore.getState().setCurrentPoints

    setCurrentPoints([[mouseCoords.x, mouseCoords.y, mouseCoords.z]])

}

const getCurrentBorderPointsCopy = () => {
    return _.cloneDeep(useBorderPointsStore.getState().currentHolds)
}

// delete a hold from currentHolds
export const handleDeleteBorderPoints = () => {
    const currentBorderPoints = getCurrentBorderPointsCopy()
    const selectedUHID = useBorderPointsStore.getState().selectedUHID

    const newHoldSet = currentBorderPoints.filter((hold) => {
        if (hold.props.uhid !== selectedUHID) {
            return hold
        }
    })

    return useBorderPointsStore.getState().setCurrentHolds(newHoldSet)
}

// should update currentPoints
export const updateBorderPoints = (mouseCoords, color, highlightMode) => {
    const currentPoints = useBorderPointsStore.getState().currentPoints
    const setCurrentPoints = useBorderPointsStore.getState().setCurrentPoints
    const renderBorderPoint = useBorderPointsStore.getState().renderBorderPoint
    
    const newBorderPoint = renderBorderPoint([mouseCoords.x, mouseCoords.y, 
                                              mouseCoords.z], color, 
                                              uuidv4(), highlightMode)

    return setCurrentPoints([...currentPoints, newBorderPoint]) 
}

export const handleDeleteChangedBorderPoint = () => {
    handleDeleteBorderPoints()

    return 
}

export const handleClearBorderPoints = () => {
    const setCurrentPoints = useBorderPointsStore.getState().setCurrentPoints
    
    setCurrentPoints([])

    return 
}

export const handleBorderPointToChange = (holdInfo) => {
    const BorderPointsStore = useBorderPointsStore.getState()
    const setMouseCoords = useCoordinatesStore.getState().setMouseCoords

    setMouseCoords({x: holdInfo.position[0], y: holdInfo.position[1], z: holdInfo.position[2]})

    BorderPointsStore.setSelectedUHID(holdInfo.uhid)

    return 
}

export const resetBorder = (data) => {
    const setCurrentPoints = useBorderPointsStore.getState().setCurrentPoints

    setCurrentPoints(data)
}

export const clearBorder = () => {
    const setCurrentPoints = useBorderPointsStore.getState().setCurrentPoints

    setCurrentPoints([])
}

export const removeLastVertex = () => {
    const currentPoints = useBorderPointsStore.getState().currentPoints
    const setCurrentPoints = useBorderPointsStore.getState().setCurrentPoints
    
    const updatedPoints = currentPoints.filter((point, idx) => {
        if (idx < currentPoints.length - 1) {
            return point
        }
    })

    setCurrentPoints(updatedPoints)  
} 