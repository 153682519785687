export function H1({text, color}) {
  const styling = color == undefined ? 
                 "scroll-m-20 text-4xl font-extrabold tracking-tight lg:text-5xl" : 
                 `${color} scroll-m-20 text-4xl font-extrabold tracking-tight lg:text-5xl`

    return (
      <h1 className={styling}>
        {text}
      </h1>
    )
  }
