import { useState, useEffect } from 'react'
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectTrigger,
    SelectValue,
  } from "../../../components/ui/select"  

export function WallIDSelect({ wallValue, updateWallValue, getWallOptions}) {
    const [ wallOptions, setWallOptions ] = useState([])

    useEffect(() => {
        setWallOptions(getWallOptions()) 
    }, [])

    return (
        <Select
            onValueChange={(value) => {updateWallValue(value)}}
            name="wallSet"
            value={wallValue} >
            <SelectTrigger className="w-[180px]">
                <SelectValue placeholder="Select the most recent updated wall" />
            </SelectTrigger>
            <SelectContent>
                <SelectGroup>
                    {wallOptions.length > 0 && wallOptions.map((item, i) => {
                        return <SelectItem key={item.name + '-wallSetSel-'+i} value={item.wallID}>{item.name}</SelectItem>
                    })}
                </SelectGroup>
            </SelectContent>
        </Select> 
    )
}

