import { useMutation } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'
import { axiosAPI } from '../axiosAPI.js'
import { Button } from '../../components/ui/button'

const UseMutateCreateGym = ({form, setNotification, setMessage}) => {
    const navigate = useNavigate()

    // send to backend API
    const mutation = useMutation({
        mutationFn: async () => {
            const response = await axiosAPI.post('gym', form)

            return response.data
        }, 
        onSuccess: (data) => {
            //notifications on success?
            navigate("/admin")
            return data
        },
        onError: (error) => {
            setMessage([])
            for (const [key, value] of Object.entries(error.response.data.errors)) {
                setMessage(errorValues => ([...errorValues, value]))
            }
    
            return setNotification(true)
        }
    })

    const onSubmitCreateWallset = (e) => {
        e.preventDefault()

        mutation.mutate()
    }

    return (
        <div>
            {mutation.isLoading ? (
            'Adding Gym...'
            ) : (
            <>
                <div className="flex flex-row justify-around">
                <Button onClick={(e) => onSubmitCreateWallset(e)} size="sm">Add Gym</Button>
                </div>

                {mutation.isError ? (
                    <>
                        <p className="forgot-password-error">{mutation.error.response.data.authorization}</p>
                    </>
                ) : null}
    
            </>
            )}
        </div>
    )
}

export default UseMutateCreateGym;