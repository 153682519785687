import { useMutation } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'
import { Button } from '../../components/ui/button'
import { axiosAPI } from '../axiosAPI.js'

const UseMutateUpdateGym = ({gymInfo, setNotification, setMessage}) => {
    const navigate = useNavigate()
    
    // send to backend API
    const mutation = useMutation({
        mutationFn: async () => {

            const response = await axiosAPI.put('update-gym/' + gymInfo._id, gymInfo)
            return response.data
        }, 
        onSuccess: (data) => {
            //notifications on success?            
            navigate('/admin')
            return data
        },
        onError: (error) => {
            setMessage([])
            for (const [key, value] of Object.entries(error.response.data.errors)) {
                setMessage(errorValues => ([...errorValues, value]))
            }
    
            return setNotification(true)
        }
    })

    const onSubmitUpdateGym = (e) => {
        e.preventDefault()

        mutation.mutate()
    }

    return (
        <div>
            {mutation.isLoading ? (
            'Updating gym info...'
            ) : (
            <>
                <div className="flex flex-row justify-around">
                    <Button size="sm" onClick={(e) => onSubmitUpdateGym(e)}>Update</Button>
                </div>

                {mutation.isError ? (
                    <>
                        <p className="forgot-password-error">{mutation.error.response.data.authorization}</p>
                    </>
                ) : null}
            </>
            )}
        </div>
    )
}

export default UseMutateUpdateGym;