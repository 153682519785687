import { Link } from 'react-router-dom'
import { ButtonLink } from '../buttons/ButtonLink'
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
  } from "../../../components/ui/table"
  import { LineChart } from "lucide-react"
  export function GymTable({headings, userGyms}) {
    return (
      <Table>
        <TableHeader>
          <TableRow>
            { headings.map((heading, idx) => {
              let customClassName = ""
              if (idx == headings.length - 1) { customClassName = "text-right" }

              return <TableHead className={customClassName}>{heading}</TableHead>
            })}
          </TableRow>
        </TableHeader>
        <TableBody>
          {userGyms.map((gym, idx) => (
            <TableRow key={gym.id}>
                <TableCell className="font-medium">
                  <Link to={{ pathname: "/gym/" + gym.id }}>
                    {idx + 1}
                  </Link>
                </TableCell>
                <TableCell className="text-nowrap">
                  <Link to={{ pathname: "/gym/" + gym.id }}>
                    {gym.gymName}
                  </Link>
                </TableCell>              
              <TableCell className="text-right">
                <ButtonLink link={"/feedback/" + gym.id} buttonName={<LineChart />} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    )
  }
  