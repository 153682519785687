import { useMutation, useQueryClient } from '@tanstack/react-query'
import { axiosAPI } from '../axiosAPI.js'

const useMutateUpdateWallsetClimb = (wallID, wallSetID, climb, holdColor, setClimbModalVis) => {
    const queryClient = useQueryClient()

    // send to backend API
    const mutation = useMutation({
        mutationFn: async () => {
            const gymID = queryClient.getQueryData(['gym']).id
            const response = await axiosAPI.put('update-gym-climb/' + gymID, 
                                                {wallID: wallID, wallSetID: wallSetID, climbID: climb.id, 
                                                climb: {...climb, ['color']: holdColor}})

            return response.data
        }, 
        onSuccess: (data) => {
            queryClient.setQueryData(['gym'], data)
            
            return setClimbModalVis(false)
        }
    })

    const onSubmitUpdateWallsetClimb = (e) => {
        e.preventDefault()

        mutation.mutate()
    }

    return { onSubmitUpdateWallsetClimb }
}

export default useMutateUpdateWallsetClimb;