export function BlockQuote({text, color}) {
    const styling = color == undefined ? 
                    "mt-6 border-l-2 pl-6 italic" : 
                    `${color} mt-6 border-l-2 pl-6 italic`

    return (
      <blockquote className={styling}>
        {text}
      </blockquote>
    )
  }
  