import {
    DropdownMenu,
    DropdownMenuCheckboxItem,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
  } from "../../../components/ui/dropdown-menu"
import { ListFilter } from "lucide-react"
import { Button } from "../../../components/ui/button"

export function WallFilter({wallFilterList, handleWallFilterChange, handleWallSetFilterChange, refetchOnConfirm}) {
    return (
        <DropdownMenu>
            <DropdownMenuTrigger asChild>
                <Button size="sm" className="h-8 gap-1">
                <ListFilter className="h-3.5 w-3.5" />
                <span className="sr-only sm:not-sr-only sm:whitespace-nowrap">
                    Wall Filter
                </span>
                </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
                <DropdownMenuLabel>Filter by</DropdownMenuLabel>
                <DropdownMenuSeparator />
                { wallFilterList && wallFilterList.length > 0 ?
                    wallFilterList.map((wall) => {
                        return <>
                                <DropdownMenuLabel>Select a Wall</DropdownMenuLabel>
                                <DropdownMenuCheckboxItem onClick={(e) => {e.preventDefault(); handleWallFilterChange(wall.wallID, !wall.filter)}} checked={wall.filter}>
                                    {wall.name}
                                </DropdownMenuCheckboxItem>
                                                                
                                {wall.filter == true ?
                                    <div className="pl-2">                                
                                        <DropdownMenuLabel>Select a Wall Set</DropdownMenuLabel>

                                        {wall.wallSets.map((wallSet) => {
                                            return <DropdownMenuCheckboxItem 
                                                        onClick={(e) => {e.preventDefault(); handleWallSetFilterChange(wall.wallID, wallSet.wallSetID, !wallSet.filter)}} 
                                                        checked={wallSet.filter}>
                                                        {wallSet.name}
                                                    </DropdownMenuCheckboxItem>
                                                
                                        })}
                                    </div>                                              
                                :
                                    null
                                }                                  
                                
                    </>
                    })
                :
                    null
                }   
                <DropdownMenuItem>
                    <Button 
                        size="lg" 
                        className="h-8 gap-1 w-full"
                        onClick={() => { refetchOnConfirm()}}>
                        <span>
                            Confirm
                        </span>
                    </Button>
                </DropdownMenuItem>            
            </DropdownMenuContent>
        </DropdownMenu>
    )
}