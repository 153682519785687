import { useMutation } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'
import { axiosAPI } from '../axiosAPI.js'

const useContactUs = (form, notificationVis, setNotification, setMessage, setIsLoading) => {
    const navigate = useNavigate()
    
    // send to backend API
    const mutation = useMutation({
    mutationFn: async (form) => {
        setIsLoading(true)

        return await axiosAPI.post('contact-us', form)
    },
    onSuccess: () => {
        setIsLoading(false)
        
        return navigate('/')
    }, 
    onError: (error) => {
        setIsLoading(false)

        setMessage([])
        for (const [key, value] of Object.entries(error.response.data)) {
            setMessage(errorValues => ([...errorValues, value]))
        }

        return setNotification(true)
      }
    })

    const onSubmitContactUs = (e) => {
        e.preventDefault()

        mutation.mutate(form)
    }

    return { onSubmitContactUs }
}

export default useContactUs;