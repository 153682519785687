import { Button } from "../../../components/ui/button"
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuGroup,
    DropdownMenuLabel,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
  } from "../../../components/ui/dropdown-menu"
  
export function DropdownBox({title, onMouseEnter, onMouseLeave, options}) {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button 
          variant="outline"
          size="sm"
          className="w-52 mb-2 justify-start overflow-auto"
          onMouseEnter={() => onMouseEnter()}
          onMouseLeave={() => onMouseLeave()}>
            <span className="flex flex-row">
              {title}
            </span>
          </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56">
        <DropdownMenuLabel>Options</DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuGroup>
          { options.map((item) => {return item})}            
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
  